import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { BasicEditingGrid } from 'components/grid/DataGrid';

import { GetAxiosGetDescriptior } from 'hooks/useAxios';
import useModelComponent from 'hooks/useModelComponent';

const TabPanel = (props) =>
{
    const { children, value, index, ...other } = props;

    return (
        <div
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            {children}
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



const ModelComponentsManager = () => {
    const [is_loaded_camera_data, loadCompleteCameraData] = useState(false);
    const [is_loaded_lens_data, loadCompleteLensData] = useState(false);
    const [is_loaded_display_data, loadCompleteDisplayData] = useState(false);

    const [index, setIndex] = useState(1);

    const datagrid_columns_display = [];
    const datagrid_columns_camera = [
        { field: 'name', headerName: 'Camera Name', width: 180, editable: true },
        { field: 'param_k', headerName: 'Parameter K', width: 200, editable: true },
    ];
    const datagrid_columns_lens = [];

    const [datagrid_rows_display, setDatagridRowsDisplay] = useState([]);
    const [datagrid_rows_camera, setDatagridRowsCamera] = useState([]);
    const [datagrid_rows_lens, setDatagridRowsLens] = useState([]);

    const onTabButtonChange = (event, tabbutton_index) => {
        setIndex(tabbutton_index);
    };

    const getCameraData = useCallback(() => {
        if (is_loaded_camera_data === true)
            return;

        let axiosDescriptorCameraData = GetAxiosGetDescriptior(onSuccessGetCameraInfo, onErrorGetData);
        useModelComponent.axiosGetUsableCameraInfo(axiosDescriptorCameraData);
    }, [is_loaded_camera_data])

    const onSuccessGetCameraInfo = (data) => {
        // console.log(data);
        
        let temp_rows = [];
        for (let i in data)
        {
            let row = data[i];
            if (row.is_deleted === false)
            {
                temp_rows.push({
                    id : row.camera_id,
                    name : row.camera_name,
                    param_k : row.camera_param_k,
                    removed : row.is_deleted
                })
            }
        }
        setDatagridRowsCamera(temp_rows);

        loadCompleteCameraData(true);
    }
    const onSuccessGetLensInfo = (data) => {
        // console.log("onSuccessGetLensInfo")
        // console.log(data);
        loadCompleteLensData(true);
    }
    const onSuccessGetDisplayInfo = (data) => {
        // console.log("onSuccessGetDisplayInfo")
        // console.log(data);
        loadCompleteDisplayData(true);
    }
    const onErrorGetData = (data) => {
        console.log("onErrorGetData")
    }
    const onAddCameraRowClick = (e) => {
        // console.log("onAddRowClick");
        setDatagridRowsCamera([...datagrid_rows_camera, {
            id : datagrid_rows_camera.length,
            name : '',
            param_k : '',
            removed : false
        }])
    }
    const onSaveCameraData = (e) => {
        console.log("onSaveCameraData");
    }

    // data loader
    useEffect(() => {
        getCameraData();
    }, [getCameraData]);

    useEffect(() => {
        let axiosDescriptorLensData = GetAxiosGetDescriptior(onSuccessGetLensInfo, onErrorGetData);
        if (is_loaded_lens_data === false)
        {
            useModelComponent.axiosGetUsableLensInfo(axiosDescriptorLensData);
        }
    }, [is_loaded_lens_data]);
    useEffect(() => {
        let axiosDescriptorDisplayData = GetAxiosGetDescriptior(onSuccessGetDisplayInfo, onErrorGetData);
        if (is_loaded_display_data === false)
        {
            useModelComponent.axiosGetDisplayInfo(axiosDescriptorDisplayData);
        }
    }, [is_loaded_display_data]);

    return (
    <>
        <Box sx={{ width: '100%' }}>
            {/* TabButton */}
            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                <Tabs value={index} onChange={onTabButtonChange}>
                    <Tab label="Display" />
                    <Tab label="Camera" />
                    <Tab label="Lens" />
                </Tabs>
            </Box>
            {/* TabPanel1 ( Display Data Table ) */}
            <TabPanel value={index} index={0}>

            </TabPanel>
            {/* TabPanel2 ( Camera Data Table ) */}
            <TabPanel value={index} index={1}>
                <BasicEditingGrid width='100%' height='600px' columns={datagrid_columns_camera} rows={datagrid_rows_camera} callback_addrow={onAddCameraRowClick} callback_save={onSaveCameraData} />
            </TabPanel>
            {/* TabPanel3 ( Lens Data Table ) */}
            <TabPanel value={index} index={2}>
                
            </TabPanel>
        </Box>
    </>);
}

export default ModelComponentsManager;