// base
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import useModelComponent from 'hooks/useModelComponent';

// mui
import { Typography, Grid, Button, Box, Container, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
// hooks
import { MopicContext } from 'hooks/useContextManager';
// MP Components
import { MPTablePaginationAction, StyledTableCell, StyledTableCellEllipsis } from 'components/MPTable';
// etc
import { GetAxiosGetDescriptior } from 'hooks/useAxios';

export default function List() {
  // context
  const account_info = useContext(MopicContext).account_info;
  const listpage_info = useContext(MopicContext).listpage_info;

  // role
  const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");

  // page
  const [page, setPage] = useState(0);
  const [rowcount, setRowCount] = useState(10);

  
  const [is_loaded_data, loadCompleteData] = useState(false);
  const [parts_list, setPartsList] = useState([]);
  useEffect(() => {
    getPartsData();
    setPage(listpage_info.getModelPageIndex());
    setRowCount(listpage_info.getModelPageRowCount());
  }, [listpage_info, page, rowcount]);


  const getPartsData = useCallback(() => {
    if (is_loaded_data === true)
        return;

    let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetInfo, onErrorGetData);
    useModelComponent.axiosGetUsableCameraInfo(axiosDescriptor); 
  }, [is_loaded_data]);

  const onSuccessGetInfo = (data) => {
    const filteredData = data.filter(item => item.camera_name !== "미사용"); 
    setPartsList(filteredData);
    loadCompleteData(true);
  };
  const onErrorGetData = (data) => {
      console.log("onErrorGetData");
  };

  // Event Handler
  const onPageIndexChange = (e, new_page_idx) => {
    setPage(new_page_idx);
    listpage_info.setModelPageIndex(new_page_idx);
  };

  const onPageRowCountChange = e => {
    const rowcount = parseInt(e.target.value, 10);

    setPage(0);
    setRowCount(rowcount);

    listpage_info.setModelPageIndex(0);
    listpage_info.setModelPageRowCount(rowcount);
  };

  const onModelDetailClick = e => {
    listpage_info.setModelPageIndex(page);
    listpage_info.setModelPageRowCount(rowcount);
  }
  
  return (
    <>
      {/* Title Container */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItem: 'right',
          flexWrap: 'wrap',
          m: 0
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography sx={{ m: 0 }} variant="h5">Camera 관리</Typography> 
          </Grid>
          {(has_role_write) ?
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button variant="contained" component={Link} to="/spec/camera/write">Camera 추가</Button>
            </Grid>
            : null}
        </Grid>
      </Container>
      {/* DataTable Container */}
      <Container sx={{ mx: 0, mt: 3, display: 'flex', flexWrap: 'wrap' }}>
        <TableContainer sx={{}} component={Box}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell component="th" scope="row" align="center" sx={{ width: '255px' }}>모델명</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ width: '135px' }}> k</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">Note</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parts_list.slice(page * rowcount, page * rowcount + rowcount).map((row, idx) => {
                return (
                  
                    <TableRow key={idx} hover={true}>
                      <StyledTableCell align="center"><Link to="/spec/camera/view" sx={{ cursor: "pointer" }} state={{ data: row }} onClick={onModelDetailClick}>{row.camera_name}</Link></StyledTableCell>
                      <StyledTableCell align="center">{row.camera_param_k}</StyledTableCell>
                      <StyledTableCellEllipsis align="left" sx={{ maxWidth: '400px' }}>{row.changed_message}</StyledTableCellEllipsis>
                    </TableRow>
                  
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={4}
                  count={parts_list.length}
                  rowsPerPage={rowcount}
                  page={parts_list.length <= 0 ? 0 : page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                  onPageChange={onPageIndexChange}
                  onRowsPerPageChange={onPageRowCountChange}
                  ActionsComponent={MPTablePaginationAction}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};