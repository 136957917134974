import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { MsalProviderToPCA } from "./components/auth/MSALModule";
import { QueryClientProvider, QueryClient } from 'react-query';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <MsalProviderToPCA>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </MsalProviderToPCA>
//   </React.StrictMode>
);