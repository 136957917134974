
// Base
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
// mui
import { Card, CardContent, Typography } from '@mui/material';
import { Box, Container, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// hook
import { DateFormat } from 'utils/Dateformat';
import { MopicContext } from 'hooks/useContextManager';
import useModelComponent from 'hooks/useModelComponent';
import { GetAxiosCommonDescriptior } from 'hooks/useAxios';

function createData(title, value) {
    return { title, value };
};

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    '&:first-of-type': {
        fontWeight: '700'
    }
}));

const View = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
    const has_role_update = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_UPDATE");
    // cng
    const [obj_parts, setParts] = useState({
        lens_name:'',
        lens_param_lp:'',
	    changed_message : '',
        changed_date: DateFormat(new Date()),
        changed_user: ''
    });

    const navigate = useNavigate();
    const onSuccessDelete = (data, variables, context) => {
        alert("삭제되었습니다");
        navigate('/spec/lens');// cng
    };

    const onErrorDelete = (e) => {
        console.log('Error Delete');
    };

    const [checkstate, setCheckState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                setParts({
                    ...data
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_parts, location]);

    const { lens_name, lens_param_lp, changed_message} = obj_parts;

    const basicInfo = [
        createData('제품명', lens_name),
        createData('lp', lens_param_lp),
        createData('노트', changed_message)
    ];

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold' }}
                    variant="h6"
                >
                    Lens 정보
                </Typography>

                <Card {...props}>
                    <CardContent>
                        <Container sx={{ m: 0, p: 0, display: 'flex', flexWrap: 'wrap' }}>
                            <TableContainer component={Box} sx={{}}>
                                <Table sx={{}} aria-label="Spec">
                                    <TableBody>
                                        {basicInfo.map((row, idx) => (
                                            <TableRow
                                                key={idx}
                                                hover={true}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="col" align="left" width="200px">{row.title}</StyledTableCell>
                                                <StyledTableCell align="left">{row.value}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </CardContent>
                </Card>
            </Container>

            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="/spec/lens">리스트</Button>
                    {(has_role_write || has_role_update) ?
                        <Button component={Link} variant="contained" to="/spec/lens/update" state={{ data: obj_parts }}>수정</Button>
                        : null
                    }
                    {(has_role_write || has_role_update) ?
                        <Button component={Link} variant="contained" to="?" onClick={()=> {
                            obj_parts.changed_user = account_info.getAccount();
                            let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessDelete, onErrorDelete);
                            axiosDescriptor.data = obj_parts;
                            useModelComponent.axiosRemoveLensInfo(axiosDescriptor);
                        }}>삭제</Button>
                        : null
                    }
                    {/* <Button variant="contained" onClick={() => { console.log('delete'); }}>삭제</Button> */}
                </Stack>
            </Container>
        </>
    );
};
export default View;
