// base
import * as React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, Link as RouterLink } from "react-router-dom";
// mui
import {
  Typography,
  Grid,
  Container,
  Button,
  Link,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// hook
import { MopicContext } from "hooks/useContextManager";
import { GetAxiosCommonDescriptior } from "hooks/useAxios";
import { getCustomerInfo } from "hooks/useCustomer";
import { issueProductEyetracker, issueProductEyetrackerWindowOnly, issueProductWarpage } from "hooks/useProduct";
import useEyetracker from "hooks/useEyetracker";
import { URI_QUERY_LIST } from "hooks/useMopicAPIConfig";
import { URL_API_LIST } from "hooks/useMopicAPIConfig";

import { DateFormat } from "utils/Dateformat";

import MPLabel from "components/MPLabel";
import MPPrint from "components/MPPrint";
import {isWindowsOnlyLicense} from "utils/WindowsOnlyProductList";

const ProductView = (props) => {
  

  const account_info = useContext(MopicContext).account_info;
  const has_role_write = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_WRITE");
  const has_role_update = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_UPDATE");
  const has_role_eyetracker_all = account_info.hasRole("EYETRACKER_ALL");

  const [obj_product, setProduct] = useState();
  const [obj_product_windowsOnly, setProduct_windowsOnly] = useState();
  const [temp, setTemp] = useState(false);
  const location = useLocation();
  const componentRef = useRef(null);

  const [customerrows, setCustomerRows] = useState([]);
  const use_key_customer_company = ["customer_company"];
  const use_key_customer_name = ["customer_name"];
  const use_key_customer_id = ["customer_id"];

  const [is_warpage_mode, setIsWarpageMode] = useState(false);

  let is_show_eyetracker_blink_serial = false;

  const onSuccessQueryCustomer = (data) => {
    let obj_customers = [];
    let obj_customer = {};
    let customer_company, customer_name;

    data.forEach((elem, idx, arr) => {
      obj_customer = {};
      for (const key in elem)
      {
        if (use_key_customer_company.indexOf(key) > -1)
        {
          customer_company = elem[key];
        }
        if (use_key_customer_name.indexOf(key) > -1)
        {
          customer_name = elem[key];
        }
        if (use_key_customer_id.indexOf(key) > -1)
        {
          obj_customer[key] = elem[key];
        }

        if (customer_company && customer_name)
        {
          obj_customer["customer"] =
            customer_company + "(" + customer_name + ")";
          customer_company = undefined;
          customer_name = undefined;
        }
      }
      obj_customers.push(obj_customer);
    });
    const set = new Set(obj_customers);
    const uniqueCustomers = [...set];
    setCustomerRows(uniqueCustomers);

    // console.log("Success Query Customer in List Product");
  };

  const onErrorQueryCustomer = (error) => {
    // console.log("Error Query Customer in List Product");
  };

  const query_options_customer = {
    keys: [],
    callback_success: onSuccessQueryCustomer,
    callback_error: onErrorQueryCustomer,
  };

  getCustomerInfo(query_options_customer);
  
  const [eyetrackerWindowsOnlyLicense, setEyetrackerWindowsOnlyLicense] = useState(false);
  
  const [productid, setProductID] = useState("");
  const getProductViewInfo = async (product_id) => {
    const fetch_option = {
      method: "GET",
      headers: {
        "api-key": "mopicapikey",
      },
    };
    const windowOnly = isWindowsOnlyLicense(product_id);
    setEyetrackerWindowsOnlyLicense(windowOnly);
    if (windowOnly) {
      const res_windowonly = await fetch(URL_API_LIST["API_PRODUCT_EYETRACKER_WINDOWONLY"] + "/2/license?pid="+product_id, fetch_option);
      const res_data_windowonly = await res_windowonly.json();
      setProduct_windowsOnly(res_data_windowonly);
    }
    else {
      setProduct_windowsOnly(null);
    }

    let req_url = URL_API_LIST["API_PRODUCT_INFORMATION"];
    let str_query = "";

    if (product_id)
    {
      str_query += "?";
      str_query += URI_QUERY_LIST["SEARCH_QUERY_PRODUCTID"] + "=" + product_id;
    }
    else
    {
      return;
    }

    if (str_query)
    {
      req_url += str_query;
    }

    const res = await fetch(req_url, fetch_option);
    const res_data = await res.json();
    setTemp1({
      ...res_data,
    });
    if (res_data?.[0].base.eyetracker_id) 
    {
      setBlinkID(res_data?.[0].base.eyetracker_id);
    }
    setProduct(res_data?.[0]);
    callback_getwarpage(product_id);
    
    return;
  };

  useEffect(() => {
    const data = location.state?.data;

    if (!temp)
    {
      if (data)
      {
        getProductViewInfo(data);
        setProductID(data);
        setTemp(!temp);
      }
    }
  }, [temp, productid]);

  const strCustomerInfo = (obj) => {
    let str = "";
    if (obj && customerrows)
    {
      customerrows.forEach((elem, idx, arr) => {
        const company_info = customerrows.find(
          (elem) => elem.customer_id === obj.base["customer_id"]
        );
        if (company_info) {
          str = company_info.customer;
          if (company_info.customer == "삭제(삭제)")  {
            str ="";
          }
        }
      });
    }
    return str;
  };

  const strhistory = (obj) => {
    let str = "";
    if (obj && obj.status_history)
    {
      obj.status_history.forEach((elem) => {
        str += DateFormat(new Date(elem.changed_date)) + " ";
        str += elem.status_name + "\n";
      });
    }
    return str;
  };

  const year_const = 1000 * 60 * 60 * 24 * 365;
  const day_const = 1000 * 60 * 60 * 24;
  const strActiveDate = (obj, type) => {
    let str = "";

    let history;
    const histories = obj?.activate_history;
    console.log('histories' , histories);
    if (!histories) return;

    let first_activatedate, last_activatedate, expiredate, validtime;
    let current_date = new Date();
    let is_complete_remain_date = false;
    
    //for (let i = histories.length - 1; i >= 0; i--)
    for (let i = 0; i<histories.length ;i++)
    {
      history = histories[i];
      console.log('history', i);
      let start_date = new Date(history.activate_date);
      let end_date = new Date(history.expire_date);

      if (history.activate_date)
      {
        if (!first_activatedate)
        {
          first_activatedate = start_date;
        }
        else
        {
          first_activatedate =
            first_activatedate > start_date ? start_date : first_activatedate;
        }

        if (+history.valid_time === -1)
        {
          is_complete_remain_date = true;
          if (!last_activatedate)
          {
            last_activatedate = history.activate_date;
            expiredate = history.expire_date;
          }
        }

        if (is_complete_remain_date === false)
        {
          console.log('history---', current_date, start_date, end_date, new Date(end_date.getTime()-start_date.getTime()).getTime());
          //if (current_date > start_date && current_date < end_date)
          if ( current_date < end_date)
          {
            last_activatedate = history.activate_date;
            expiredate = history.expire_date;
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", expiredate, i);
          }
        }
      }
    }
    console.log(type, first_activatedate, last_activatedate, expiredate);

    if (type === 1)
    {
      // first_activate_time
      if (first_activatedate)
      {
        return DateFormat(first_activatedate);
      }
    }
    else if (type === 2)
    {
      // valid_time
      if (expiredate)
      {
        let end_date = new Date(expiredate);
        let remain_date = end_date - current_date;

        if (remain_date / day_const < 365)
        {
          str = Math.floor(remain_date / day_const) + " Days";
        }
        else
        {
          if (remain_date / year_const > 100)
          {
            str = "Permanent License";
          }
          else
          {
            str = Math.floor(remain_date / year_const) + " Years Over";
          }
        }
        console.log('remain_date', remain_date, str);
        return str + " (" + DateFormat(new Date(expiredate)) + ")";
      }
      else
      {
        return str;
      }
    } else if (type === 3)
    {
      // last_activate_time
      if (last_activatedate) return DateFormat(new Date(last_activatedate));
    }
    return str;
  };

  const label_opt = {
    label_type: "release",
    //encode_text: "바코드 읽을때 동작할 내용",
    serialnumber: "넘겨받은 시리얼",
    model_name: "넘겨받은 모델",
    customer_company: "",
  };

  const [obj_label, setLabel] = useState(label_opt);
  const [bc_open, setBc_Open] = useState(false);
  const [temp1, setTemp1] = useState({});

  const handleBCClickOpen = (row) => {
    if (row)
    {
      obj_label.serialnumber = row.base.serialnumber;
      obj_label.model_name = row.base.model_name;
      obj_label.customer_company = row.base.customer_company;
    }

    setBc_Open(true);
  };

  const handleBCClose = () => {
    setBc_Open(false);
  };
  const [blink_id, setBlinkID] = useState("");
  // issue eyetracker sn
  const [obj_eyetrackerkey, setEyeTracerkey] = useState({
    eyetracker_id:"",
    eyetracker_user: "",
    eyetracker_key: "",
    eyetracker_expiredate: "",
    eyetracker_name: "",
  });

  
  const [obj_product_warpage, setProductWarpage] = useState({
    product_id:"",
    warpage_type:"1",
    warpage_data: ""
  });
  

  const callback_getwarpage = async (product_id) => {
    const fetch_option = {
      method: "GET",
      headers: {
        "api-key": "mopicapikey",
      },
    };

    const options = obj_product?.base;
    let req_url = URL_API_LIST["API_PRODUCT_WARPAGE"];

    req_url +=
      "?pid=" +
      (product_id ? product_id : options.product_id);

    const res = await fetch(req_url, fetch_option);
    const res_data = await res.json();
    setProductWarpage( res_data );
    
    return;
  };
  const callback_geteyetrackerkey = async () => {
    const fetch_option = {
      method: "GET",
      headers: {
        "api-key": "mopicapikey",
      },
    };

    const options = obj_product?.base;
    let req_url = URL_API_LIST["API_PRODUCT_EYETRACKER"];

    req_url +=
      "?cat=info&et=" +
      options.eyetracker_idx +
      "&&pid=" +
      options.product_id +
      "&&lt=" +
      options.eyetracker_license_type_idx;

    const res = await fetch(req_url, fetch_option);
    const res_data = await res.json();
    setEyeTracerkey({ ...res_data });
    return;
  };

  const onSuccessIssueEyeTracker = (data, variables, context) => {
    setTimeout(() => {
      callback_geteyetrackerkey();
    }, 400);
  };
  const onSuccessIssueWarpage = (data, variables, context) => {
    // console.log('onSuccessIssueWarpage', data, variables, context);
    setTimeout(() => {
      callback_getwarpage();
    }, 400);
  };

  const onErrorIssueEyeTracker = (error) => {
    // console.log("error");
  };
  const onErrorIssueWarpage = (error) => {
    // console.log("error");
  };

  const [obj_options_eyetracker, setOptionsEyeTracker] = useState({
    product_id: "",
    changed_user: "",
  });

  const mutate_options_eyetracker = {
    body: obj_options_eyetracker,
    callback_success: onSuccessIssueEyeTracker,
    callback_error: onErrorIssueEyeTracker,
  };

  const result_mutate_eyetracker = issueProductEyetracker(
    mutate_options_eyetracker
  );
  const result_mutate_eyetracker_windowOnly = issueProductEyetrackerWindowOnly(
    mutate_options_eyetracker
  );

  // issue eyetracker key
  const onIssueEyetrackerKeyClick = (row) => {
    if(window.confirm("Blink Key를 발급하시겠습니까?")) {
      if (obj_product && row)
      {
        if (row.title === "Eyetracker Type")
        {
          obj_options_eyetracker.product_id = obj_product.base.product_id;
          obj_options_eyetracker.license_type_idx =
            obj_product.base.eyetracker_license_type_idx;
          obj_options_eyetracker.changed_user = account_info.getAccount();
          obj_options_eyetracker.unsync = eyetrackerWindowsOnlyLicense;
          result_mutate_eyetracker.mutate();
          if (!obj_product_windowsOnly) {
            //result_mutate_eyetracker_windowOnly.mutate();
          }
          //console.log('S/N');
        }
        else if (row.title === "Eyetracker S/N")
        {
          // TODO : sn 재발급
        }
      }
    }
    return;
  };

  
  const [obj_options_warpage, setOptionsWarpage] = useState({
    product_id: "",
    warpage_type: "0",
    changed_user: "",
  });
  const mutate_options_warpage = {
    body: obj_options_warpage,
    callback_success: onSuccessIssueWarpage,
    callback_error: onErrorIssueWarpage,
  };

  const result_mutate_warpage = issueProductWarpage(
    mutate_options_warpage
  );

  const onUnregistEyetrackerKeyClick = (row) => {
    if(window.confirm("Blink Key를 제거하시겠습니까?")) {
      let axiosDescriptor = GetAxiosCommonDescriptior(
        onSuccessUnregistEyetrackerKey,
        onErrorUnregistEyetrackerKey
      );
      axiosDescriptor.data = {
        product_id: obj_product?.base.product_id,
        license_type_idx: obj_product.base.eyetracker_license_type_idx,
        changed_user: account_info.getAccount(),
        unsync : eyetrackerWindowsOnlyLicense
      };
      useEyetracker.axiosPostUnregistEyeTrackerKey(axiosDescriptor);
      if (!obj_product_windowsOnly) {
        //useEyetracker.axiosPostUnregistEyeTrackerKeyWindowOnly(axiosDescriptor);
      }
    }
  };
  const onSuccessUnregistEyetrackerKey = (data) => {
    setTimeout(() => {
      callback_geteyetrackerkey();
    }, 400);
  };
  const onErrorUnregistEyetrackerKey = (data) => { };


  
  // exchange eyetracker key
  const onExchangeEyetrackerKeyClick = (row) => {
    if(window.confirm("Blink Key를 교체하시겠습니까?")) {
        let axiosDescriptor = GetAxiosCommonDescriptior(
          onSuccessExchangeEyetrackerKey,
          onErrorExchangeEyetrackerKey
        );
        axiosDescriptor.data = {
          product_id: obj_product?.base.product_id,
          license_type_idx: obj_product.base.eyetracker_license_type_idx,
          changed_user: account_info.getAccount(),
          unsync : eyetrackerWindowsOnlyLicense
        };
        useEyetracker.axiosPostExchangeEyeTrackerKey(axiosDescriptor);
        if (!obj_product_windowsOnly) {
          //useEyetracker.axiosPostExchangeEyeTrackerKeyWindowOnly(axiosDescriptor);
        }
    };
    
  };
  const onSuccessExchangeEyetrackerKey = (data) => {
    setTimeout(() => {
      callback_geteyetrackerkey();
    }, 400);
  };
  const onErrorExchangeEyetrackerKey = (data) => { };

  const createData = (title, value) => {
    return { title, value };
  };
  const createCopyableData = (title, value) => {
    return { title, value, clipboard: true };
  };
  const createEyetrackerTypeData = (title, value) => {
    let issue = false;
    let exchange = false;
    let unregist = false;

    let key = obj_product?.base.eyetracker_key
      ? obj_product?.base.eyetracker_key
      : obj_eyetrackerkey?.eyetracker_key;
    let policy = obj_product?.base.eyetracker_license_type_idx;

    if (value === "Blink")
    {
      if (key)
      {
        unregist = true;
        exchange = true;
      }
      else
      {
        issue = true;
      }

      if (policy === 4) exchange = false;

      is_show_eyetracker_blink_serial = true;
    }

    if (has_role_eyetracker_all)
    {
      return {
        title,
        value,
        issue: issue,
        exchange: exchange,
        unregist: unregist,
      };
    }
    else
    {
      return { title, value, issue: false, exchange: false, unregist: false };
    }
  };
  const createEyetrackerSerialData = (title, value) => {
    if (is_show_eyetracker_blink_serial)
    {
      if (value == null || value === "")
      {
        return { title, value: "Issued Key Is Empty", clipboard: false };
      }
      else
      {
        return { title, value, clipboard: true };
      }
    }
    else
    {
      return { title, value: "Not Use Key", clipboard: false };
    }
  };
  const createEyetrackerWindowsOnlySerialData = (title, value) => {
    if (is_show_eyetracker_blink_serial)
    {
      if (value == null || value === "")
      {
        return { title, value: "Issued Key Is Empty", clipboard: false, windowsOnly: true };
      }
      else
      {
        return { title, value, clipboard: true, windowsOnly: true };
      }
    }
    else
    {
      return { title, value: "Not Use Key", clipboard: false, windowsOnly: true };
    }
  };
  const hiddenFileInput = React.useRef(null);
    
  const handleFileSelectChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = function(e) {
            const base64String = e.target.result.split(',')[1];
            // console.log(base64String);

            obj_options_warpage.product_id = obj_product?.base.product_id;
            obj_options_warpage.serialnumber = obj_product?.base.serialnumber;
            obj_options_warpage.warpage_type = "1";
            obj_options_warpage.warpage_data = base64String;
            obj_options_warpage.changed_user = account_info.getAccount();
            result_mutate_warpage.mutate();
        };
        reader.readAsDataURL(file);
    }
    else {
      alert('No file selected!');
    }

  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const onChangeWarpageMode = (e) => {
    obj_options_warpage.product_id = obj_product?.base.product_id;
    obj_options_warpage.serialnumber = obj_product?.base.serialnumber;
    obj_options_warpage.warpage_type = (e.target.checked) ? "1" : "0";
    obj_options_warpage.changed_user = account_info.getAccount();
    delete obj_options_warpage.warpage_data;
    result_mutate_warpage.mutate();
    //setIsWarpageMode(e.target.checked)
  };
  const downloadCSV= () => {
    const base64Encoded = obj_product_warpage.warpage_data;

    if (obj_product_warpage?.warpage_data) {
      const csvContent = atob(base64Encoded);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const blobURL = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobURL;
      link.download = (obj_product?.base.serialnumber ? obj_product?.base.serialnumber : "download") + '.csv'; // 다운로드할 파일 이름 설정
      link.click();

      URL.revokeObjectURL(blobURL);
    }
    else {
      console.log('data 가 없습니다.')
    }
  }
  const GetWarpageValue= () => {
    let type = obj_product_warpage?.warpage_type ? obj_product_warpage?.warpage_type : "0";
    let data = obj_product_warpage?.warpage_data ? obj_product_warpage?.warpage_data : "";
    
    return (<>
      
      <IOSSwitch  color="secondary" checked={type==1} onChange={onChangeWarpageMode} inputProps={{ 'aria-label': 'warpage_mode' }}/>
      {/* <ToggleButton color="secondary" value="check" size="small" selected={is_warpage_mode} onChange={() => { setIsWarpageMode(!is_warpage_mode);}}><CheckIcon /></ToggleButton> */}

      { (type>0 && data.length===0) ?
        <>
          <Button variant="contained" sx={{ ml: 1, marginTop: "-8px" }}  className="issue_key" onClick={()=> {hiddenFileInput.current.click()}}>
              Upload File
          </Button>
          <input type="file" ref={hiddenFileInput} accept=".csv" onChange={handleFileSelectChange} style={{display: 'none'}} />
          {/* .csv */}
        </>
        :
        null
      }
      {type>0 && data.length>0 ? (
        <>
          <Button variant="contained" sx={{ ml: 1, marginTop: "-8px" }} className="issue_key" onClick={downloadCSV}
          >
            Download File
          </Button>
          <Button variant="contained" sx={{ ml: 1, marginTop: "-8px" }}  className="issue_key" onClick={()=> {hiddenFileInput.current.click()}}>
              Change File
          </Button>
          <input type="file" ref={hiddenFileInput} accept=".csv" onChange={handleFileSelectChange} style={{display: 'none'}} /> 
          {/* .csv */}
        </>
      ) : null}
    </>);
  }
  
  const basicInfo = [
    createCopyableData("Mopic License", obj_product?.base.serialnumber),
    createCopyableData("UUID", obj_product?.base.product_id, true),
    createData("Model Name", obj_product?.base.model_name),
    createData("Model S/N", obj_product?.base.model_serialnumber),
    createData("고객사", obj_product ? strCustomerInfo(obj_product) : null),
    createData(
      "제작일자",
      DateFormat(new Date(obj_product?.base.manufacture_date))
    ),
    createData("최초 가동일", strActiveDate(obj_product, 1)),
    createData("License 시작일", strActiveDate(obj_product, 3)),
    createData("License 사용기간", strActiveDate(obj_product, 2)),
    createData(
      "Slanted Angle",
      obj_product?.base.product_slantedangle
        ? obj_product?.base.product_slantedangle
        : ""
    ),
    createData(
      "Offset",
      obj_product?.base.product_offset ? obj_product?.base.product_offset : ""
    ),
    createData(
      "Spsize",
      obj_product?.base.product_spsize ? obj_product?.base.product_spsize : ""
    ),
    createEyetrackerTypeData(
      "Eyetracker Type",
      obj_product?.base.eyetracker_name
    ),
    createEyetrackerTypeData(
      "Eyetracker Policy",
      obj_product?.base.license_type_name
    ),
    createEyetrackerSerialData(
      "Eyetracking S/N",
      obj_product?.base.eyetracker_key
        ? obj_product?.base.eyetracker_key
        : obj_eyetrackerkey?.eyetracker_key
    ),

    
    // createEyetrackerSerialData(
    //   "Eyetracking Policy (Windows Only)",
    //   obj_product_windowsOnly
    //   ? obj_product_windowsOnly.license_type_idx 
    //   : ""
    // ),
    createEyetrackerWindowsOnlySerialData(
      "Eyetracking S/N (Windows Only)",
      obj_product_windowsOnly
      ? obj_product_windowsOnly.eyetracker_key 
      : ""
    ),
    createData(
      "휨보정 CSV",
      <GetWarpageValue />
      // {
      //   type : obj_product_warpage?.warpage_type ? obj_product_warpage?.warpage_type : "",
      //   data : obj_product_warpage?.warpage_data ? obj_product_warpage?.warpage_data : ""
      // }
    ),



    
    createData("영업담당자", obj_product?.base.product_manager),
    createData("등록자", obj_product?.status_history[0].changed_user),
    createData(
      "최종수정일자",
      DateFormat(new Date(obj_product?.status_history[0].changed_date))
    ),
    createData("상태", obj_product?.status_history[0].status_name),
    createData("Note", obj_product?.base.product_note),
    createData("이력", obj_product ? strhistory(obj_product) : null),
  ];
  const GetGridContent = (row, idx) => {
    if (!eyetrackerWindowsOnlyLicense && row.windowsOnly) {
      return null;
    }

    return (
      <Grid container key={idx} className={row.windowsOnly ? "windowsOnly" : ""}>
        <Grid
          item
          xs={5}
          sm={3}
          xl={3}
          key={idx}
          sx={{
            p: 2,
            borderBottom: { borderBottom: "1px solid #ddd" },
            fontWeight: "bold",
          }}
          className="view_title"
        >
          {row.title}
        </Grid>
        <Grid
          item
          xs={7}
          sm={9}
          xl={9}
          sx={{
            p: 2,
            whiteSpace: "pre-wrap",
            borderBottom: { borderBottom: "1px solid #ddd" },
          }}
          className="view_content"
        >
          <span>{row.title === "Offset Map" ? (
            <Link variant="text" href={row.value[1]}>
              {row.value[0]}
            </Link>
          ) : (
            row.value
          )}</span>
          {row.clipboard ? (
            <div className="btn_copy_wrap">
            <CopyToClipboard text={row.value} className="btn_copy">
              <IconButton aria-label="copy" size="small" sx={{ ml: 1, top:"0px", position:"relative",  display: "inline-block" }}>
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </CopyToClipboard>
            </div>
          ) : null}
          {row.unregist ? (
            <Button
              variant="contained" sx={{ ml: 1, marginTop: "-8px" }} className="issue_key" onClick={() => {
                onUnregistEyetrackerKeyClick(row);
              }}
            >
              Key 제거
            </Button>
          ) : null}
          {row.issue ? (
            <Button variant="contained" sx={{ ml: 1, marginTop: "-8px" }} className="issue_key" onClick={() => {
                onIssueEyetrackerKeyClick(row);
              }}
            >
              Key 발급
            </Button>
          ) : null}
          {row.exchange ? (
            <Button variant="contained" sx={{ ml: 1, marginTop: "-8px" }} className="issue_key" onClick={() => {
                onExchangeEyetrackerKeyClick(row);
              }}
            >
              Key 교체
            </Button>
          ) : null}
          
        </Grid>
      </Grid>
    )
  };

  return (
    <>
      <Container sx={{ m: 0 }} className="product_view">
        <Typography sx={{ m: 0, mb: 2, fontWeight: "bold" }} variant="h6">
          제품 정보 
          {/* <GetGridContent></GetGridContent> */}
        </Typography>
        <Container sx={{ m: 0, mt: 2, p: 0 }} className="product_view_container">
          <Grid container sx={{ fontSize: 14 }}>
            {basicInfo.map((row, idx) => 
              GetGridContent(row, idx)
            )}
          </Grid>
        </Container>
      </Container>

      <Container sx={{ m: 0, mt: 5 }}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }} className="view_btnbox">
          <Button component={RouterLink} variant="contained" to={"/logviewer/mopiclib?log_serial="+obj_product?.base.serialnumber+"&log_date=1&log_page=0"}>
            로그보기
          </Button>
          <Button className="btn_label_print" variant="contained" onClick={() => {
              handleBCClickOpen(obj_product);
            }}
          >
            출고라벨
          </Button>
          <Button component={RouterLink} variant="contained" to="/product/list">
            리스트
          </Button>
          {has_role_write || has_role_update ? (
            <Button component={RouterLink} variant="contained" to="/product/update" state={{
                data: obj_product?.base,
                activatedata: obj_product?.activate_history,
              }}
            >
              수정
            </Button>
          ) : null}
          {/* <Button variant="contained" onClick={() => { console.log('delete'); }}>삭제</Button> */}
        </Stack>
      </Container>

      <Dialog
        open={bc_open}
        onClose={handleBCClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div ref={componentRef}>
          <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: "#24292e", color: "#fff" }}>
            {"출고용 라벨"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ pt: 2 }}>
              <MPLabel options={obj_label} />
            </DialogContentText>
          </DialogContent>
        </div>
        <DialogActions sx={{ mx: 2 }}>
          <MPPrint options={obj_label}></MPPrint>
          <Button onClick={handleBCClose} autoFocus>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProductView;
