/* eslint-disable */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import "./scss/common.scss"
import { ThemeProvider } from '@mui/material/styles';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { useMediaQuery, CssBaseline, Box } from '@mui/material';

import MPTheme from 'view/common/Theme';
import Header from 'view/common/Header';
import Navigator from 'view/common/Navigator';
import MPRouter from 'view/common/Router';
import Login from 'view/common/Login';

import { ScrollTop } from 'utils/ScrollTop';
import MPCopyright from 'components/MPCopyright';
import { MopicContext, AccountInfomation, ListPageInfomation } from 'hooks/useContextManager';

import { loginRequest, graphConfig } from "./components/auth/config";

const drawerWidth = 200;

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(MPTheme.breakpoints.up('md'));
  const { instance, accounts } = useMsal();

  console.log('MOPIC ERP 1.4.8');
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  React.useEffect(() => {
    if (accounts[0])
    {
      //console.log(accounts);
      window.sessionStorage.setItem('user', accounts[0].name);
      window.sessionStorage.setItem('account', accounts[0].username);
      window.sessionStorage.setItem('roles', accounts[0].idTokenClaims?.roles);

      AccountInfomation.setUser(accounts[0].name);
      AccountInfomation.setAccount(accounts[0].username);
      AccountInfomation.setRoles(accounts[0].idTokenClaims?.roles);
    }
    else
    {
      window.sessionStorage.removeItem('user');
      window.sessionStorage.removeItem('account');
      window.sessionStorage.removeItem('roles');
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response) => {
      AccountInfomation.setToken(response.accessToken);
        fetch(graphConfig.graphUserEndpoint, {
            headers: {
                'Authorization': `Bearer ${response.accessToken}`
            }
        })
        .then(response => response.json())
        .then(data => {
            AccountInfomation.setMembers(data.value);
        })
        .catch(error => {
            console.error(error);
        });
    });

  })

  return (
    <>
      <AuthenticatedTemplate>
        <ThemeProvider theme={MPTheme}>
          <BrowserRouter>
            <MopicContext.Provider value={
              {
                account_info: AccountInfomation,
                listpage_info: ListPageInfomation
              }}>
              <ScrollTop>
                <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                  <CssBaseline />
                  <Box
                    component="nav"
                    sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
                  >
                    {isSmUp ? null : (
                      <Navigator
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                      />
                    )}
                    <Navigator
                      PaperProps={{ style: { width: drawerWidth } }}
                      sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}
                    />
                  </Box>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#f9fafd' }}>
                      <MPRouter />
                    </Box>
                    <Box component="footer" sx={{ p: 2, bgcolor: '#f9fafd' }}>
                      <MPCopyright />
                    </Box>
                  </Box>
                </Box>
              </ScrollTop>
            </MopicContext.Provider>
          </BrowserRouter>
        </ThemeProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <ThemeProvider theme={MPTheme}>
          <Login></Login>
        </ThemeProvider>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;


