// base
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// mui
import { FormControl, InputBase, InputLabel, Typography, Grid, Button, Stack, Container } from '@mui/material';
// hook
import useModelComponent from 'hooks/useModelComponent';
import { GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { DateFormat } from 'utils/Dateformat';
import { MopicContext } from 'hooks/useContextManager';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const Update = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
    const has_role_update = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_UPDATE");

    const navigate = useNavigate();
    const onSuccessMutate = (data, variables, context) => {
        alert("수정되었습니다");
        navigate('/spec/display');
    };

    const onErrorMutate = () => {
        console.log('Error Update');
    };

    // print variable
    const [type,setType]=useState('LCM');
    const [obj_parts, setParts] = useState({
        represent_name:'',
        display_type:'',
        display_name:'',
        display_brand:'',
        monitor_name:'',
        monitor_brand:'',
        lcm_name:'',
        lcm_brand:'',
        opencell_name:'',
        opencell_brand:'',
        display_param_spsize:'',
	    changed_message : '',
        changed_date: DateFormat(new Date()),
        changed_user: ''
    });
    

    const [checkstate, setCheckState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                if (data.display_type.toUpperCase() === 'MONITOR') {
                    data.display_brand = data.monitor_brand;
                    data.display_name = data.monitor_name;
                } 
                else if (data.display_type.toUpperCase() === 'LCM') {
                    data.display_brand = data.lcm_brand;
                    data.display_name = data.lcm_name;
                }
                else if (data.display_type.toUpperCase() === 'OPENCELL') {
                    data.display_brand = data.opencell_brand;
                    data.display_name = data.opencell_name;
                }
                setType(data.display_type.toUpperCase());
                setParts({
                    ...data
                })
                
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_parts])

    const { represent_name, display_type,  display_name, display_brand, monitor_name, monitor_brand,lcm_name, lcm_brand, opencell_name, opencell_brand,  display_param_spsize, changed_message, changed_date, changed_user } = obj_parts;

    // TODO : regist confirm 정리하자
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function")
        {
            return;
        }
        if (onCancel && typeof onCancel !== "function")
        {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message))
            {
                onConfirm();
            }
            else
            {
                onCancel();
            }
        }

        return confirmAction;
    };

    const updateConfirm = () => {
        if (has_role_write || has_role_update)
        {
            obj_parts.changed_user = account_info.getAccount();
            obj_parts.changed_date = new Date();
            if (obj_parts.display_name && obj_parts.display_brand && obj_parts.display_param_spsize && obj_parts.represent_name && obj_parts.changed_user)
            {
                if (obj_parts.display_type == 'MONITOR') {
                    obj_parts.monitor_name = obj_parts.display_name;
                    obj_parts.monitor_brand = obj_parts.display_brand;
                    obj_parts.lcm_name = '미사용';
                    obj_parts.lcm_brand = '';
                    obj_parts.opencell_name = '미사용';
                    obj_parts.opencell_brand = '';
                }
                else if(obj_parts.display_type == 'LCM') {
                    obj_parts.lcm_name = obj_parts.display_name;
                    obj_parts.lcm_brand = obj_parts.display_brand;
                    obj_parts.monitor_name = '미사용';
                    obj_parts.monitor_brand = '';
                    obj_parts.opencell_name = '미사용';
                    obj_parts.opencell_brand = '';
                }
                else if(obj_parts.display_type == 'OPENCELL') {
                    obj_parts.opencell_name = obj_parts.display_name;
                    obj_parts.opencell_brand = obj_parts.display_brand;
                    obj_parts.monitor_name = '미사용';
                    obj_parts.monitor_brand = '';
                    obj_parts.lcm_name = '미사용';
                    obj_parts.lcm_brand = '';
                }
                console.log(obj_parts);
                let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessMutate, onErrorMutate);
                axiosDescriptor.data = obj_parts;
                useModelComponent.axiosChangeDisplaySetInfo(axiosDescriptor);
            }
            else
            {
                alert('필수값을 입력해주세요');
            }
        } else
        {
            alert('권한이 없습니다');
        }
    };

    const cancelConfirm = () => alert("취소되었습니다");

    const confirmUpdate = useConfirm(
        "수정하시겠습니까?",
        updateConfirm,
        cancelConfirm
    );
    const onChangeHandler = (e) => {
        let { value, id } = e.target;
        setParts({
            ...obj_parts,
            [id]: value
        })
    };

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }}
                    variant="h6"
                >
                    DisplaySet 수정
                </Typography>
                

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="represent_name" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>대표이름</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240,}}>
                            <InputBase id="represent_name" className="inputStyle" value={represent_name} readOnly />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="represent_name" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Type</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240,}}>
                            <InputBase id="display_type" className="inputStyle" value={display_type} readOnly />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="display_name" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Model Name *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="display_name" className="inputStyle" value={display_name} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="display_brand" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Model Brand *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="display_brand" className="inputStyle" value={display_brand} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="display_param_spsize" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>spsize *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="display_param_spsize" className="inputStyle" value={display_param_spsize} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="changed_message" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Note</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2, py: '4px' }}>
                        <InputBase
                            id="changed_message" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', background: '#fff' }}
                            value={changed_message || ''}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="" sx={{ minWidth: 100 }} onClick={confirmUpdate}>수정</Button>
                </Stack>
            </Container>
        </>
    );
};
export default Update;

