import React from "react"
import Typography from '@mui/material/Typography';

function MPCopyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        MopicLabs
        {' '}
        {new Date().getFullYear()}.
      </Typography>
    );
  }

export default MPCopyright;