import * as config from 'config'

export const BASE_URL = {
    MODEL: config.MODEL_API_HOST,
    MODELCOMPONENT: config.MODELCOMPOENT_API_HOST,
    PRODUCT: config.PRODUCT_API_HOST,
    MOPICLIB_LOG: config.MOPICLIB_LOG_API_HOST,
    COMMAPI_LOG: config.COMMAPI_LOG_API_HOST

};

export const URL_API_LIST = {
    API_MODEL_INFORMATION: BASE_URL['MODEL'] + "/info",
    API_MODEL_LIST: BASE_URL['MODEL'] + "/list",
    API_PRODUCT_LIST: BASE_URL['PRODUCT'] + "/product/compatible/list",
    API_PRODUCT_USEDMODELNAME_LIST: BASE_URL['PRODUCT'] + "/product/compatible/list/modelname",
    API_PRODUCT_INFORMATION: BASE_URL['PRODUCT'] + "/product/compatible/info",
    API_PRODUCT_INFORMATION_FILE: BASE_URL['PRODUCT'] + "/product/compatible/fileinfo",
    API_PRODUCT_CUSTOMERS: BASE_URL['PRODUCT'] + "/product/compatible/customers",
    API_PRODUCT_STATUS: BASE_URL['PRODUCT'] + "/status",
    API_CUSTOMER_INFORMATION: BASE_URL['PRODUCT'] + "/customer/info",
    API_PRODUCT_LABEL: BASE_URL['PRODUCT'] + "/product/compatible/label",
    API_PRODUCT_EYETRACKER_WINDOWONLY: BASE_URL['PRODUCT'] + "/eyetracker",
    API_PRODUCT_EYETRACKER: BASE_URL['PRODUCT'] + "/eyetracker/compatible",
    API_PRODUCT_WARPAGE: BASE_URL['PRODUCT'] + "/product/compatible/warpage",
    API_PRODUCT_ACTIVATE: BASE_URL['PRODUCT'] + "/activate",
    API_PRODUCT_FILEDOWNLOAD: BASE_URL['PRODUCT'] + "/product/compatible/download",
    API_PRODUCT_ISSUED: BASE_URL['PRODUCT'] + "/product/compatible/serialnumber/issued",
    API_PRODUCT_CHECK_MODEL_SERIAL: BASE_URL['PRODUCT'] + "/product/compatible/serialnumber/issued_modelsn",
    API_MODELCOMPONENT_CAMERA: BASE_URL['MODELCOMPONENT'] + "/camera",
    API_MODELCOMPONENT_LENS: BASE_URL['MODELCOMPONENT'] + "/lens",
    API_MODELCOMPONENT_3DBOX: BASE_URL['MODELCOMPONENT'] + "/3dbox",
    API_MODELCOMPONENT_DISPLAY: BASE_URL['MODELCOMPONENT'] + "/display",
    API_MOPICLIB_LOGKEY: BASE_URL['MOPICLIB_LOG'] + "/keylist",
    API_MOPICLIB_LOGDATA: BASE_URL['MOPICLIB_LOG'],
    API_COMMAPI_LOGKEY: BASE_URL['COMMAPI_LOG'] + "/keylist",
    API_COMMAPI_LOGDATA: BASE_URL['COMMAPI_LOG'],

}

export const URI_QUERY_LIST = {
    SEARCH_QUERY_PRODUCTID: 'pid',
    SEARCH_QUERY_PRODUCTSTATUS: 'psta',
    SEARCH_QUERY_PRODUCTSERIAL: 'psn',
    SEARCH_QUERY_PRODUCTMANUFACTSTART: 'msd',
    SEARCH_QUERY_PRODUCTMANUFACTEND: 'med',
    SEARCH_QUERY_PRODUCTHISTORY: 'phis',
    SEARCH_QUERY_CUSTOMERID: 'cid',
    SEARCH_QUERY_COMPANYNAME: 'con',
    SEARCH_QUERY_CUSTOMERNAME: 'cun',
    SEARCH_QUERY_MANAGERNAME: 'man',
    SEARCH_QUERY_MODELNAME: 'mn',
    SEARCH_QUERY_MODELSERIALNUMBER: 'msn',
    SEARCH_QUERY_MODELSTATUS: 'msta',
    SEARCH_QUERY_MODELHISTORY: 'mhis',
    SEARCH_QUERY_COLUMNNAME: 'scol',
    SEARCH_QUERY_SERIALNUMBER: 'sn',
    SEARCH_QUERY_LOGLIMIT: 'limit',
    SEARCH_QUERY_LOGSTARTDATE: 'startdate',
    SEARCH_QUERY_LOGENDDATE: 'enddate',
    SEARCH_QUERY_LOGMESSAGE: 'message'

}

//TODO : 서버에서 발급한 암호화된 키로 적용
export const MOPICAPI_KEY = {
    COMMON: 'mopicapikey'
}
