// For Account

// For Customer

// For Product

// For Model
export const getModelTypeList = () => {
  return [
    { idx: 0, value: "Signage", text: "Signage" },
    { idx: 1, value: "Personal S.", text: "Personal S." },
    { idx: 2, value: "Mobile", text: "Mobile" },
  ];
};

export const getResolution = () => {
  return [
    { idx: 0, value: "U", text: "U : UHD" },
    { idx: 1, value: "S", text: "S : 8K UHD" },
    { idx: 2, value: "F", text: "F : FHD" },
    { idx: 3, value: "Q", text: "Q : QHD" },
  ];
};

export const getMonitorSize = () => {
  return [
    { idx: 0, value: "08", text: "8.9" },
    { idx: 1, value: "09", text: "9.2" },
    { idx: 2, value: 13, text: "13" },
    { idx: 3, value: 15, text: "15.6" },
    { idx: 4, value: 27, text: "26.5" },
    { idx: 5, value: 27, text: "27" },
    { idx: 6, value: 28, text: "28" },
    { idx: 7, value: 32, text: "32" },
    { idx: 8, value: 43, text: "43" },
    { idx: 9, value: 55, text: "55" },
    { idx: 10, value: 65, text: "65" },
  ];
};

export const getVersion1 = () => {
  return [
    { idx: 0, value: "A", text: "A : VER 1" },
    { idx: 1, value: "B", text: "B : VER 2" },
    { idx: 2, value: "C", text: "C : VER 3" },
    { idx: 3, value: "D", text: "D : VER 4" },
    { idx: 4, value: "E", text: "E : VER 5" },
    { idx: 5, value: "F", text: "F : VER 6" },
  ];
};

export const getUseCamera = () => {
  return [
    { idx: 0, value: "1", text: "1 : Multi + Display cut" },
    { idx: 1, value: "2", text: "2 : stereo + Display cut" },
    { idx: 2, value: "3", text: "3 : OLED" },
    { idx: 5, value: "5", text: "5 : Switchable" },
    { idx: 3, value: "6", text: "6 : w/o camera" },
    { idx: 4, value: "7", text: "7 : w/ camera for eye tracking" },
  ];
};

export const getGrade = () => {
  return [
    { idx: 0, value: "2", text: "2 : Demo제품" },
    { idx: 1, value: "3", text: "3 : 자체디자인" },
    { idx: 2, value: "5", text: "5 : 스탠다드" },
    { idx: 3, value: "7", text: "7 : 프리미엄" },
    { idx: 4, value: "9", text: "9 : 특별제품" },
  ];
};

export const getVersion2 = () => {
  return [
    { idx: 0, value: "0", text: "0 : 기본" },
    { idx: 1, value: "1", text: "1 : VER 1" },
    { idx: 2, value: "2", text: "2 : VER 2" },
    { idx: 3, value: "3", text: "3 : VER 3" },
    { idx: 4, value: "4", text: "4 : VER 4" },
    { idx: 5, value: "5", text: "5 : VER 5" },
    { idx: 6, value: "6", text: "6 : VER 6" },
    { idx: 7, value: "7", text: "7 : VER 7" },
    { idx: 8, value: "8", text: "8 : VER 8" },
    { idx: 9, value: "9", text: "9 : VER 9" },
  ];
};

export const getMobileModel = () => {
  return [
    { idx: 0, value: "G", text: "Galaxy" },
    { idx: 1, value: "I", text: "iPhone" },
  ];
};
export const getMobileModelNumber = (model) => {
  const items = [
    { idx: 0, value: "23", text: "Galaxy 23 Plus", model: "G" },
    { idx: 1, value: "24", text: "Galaxy 24", model: "G" },
    { idx: 0, value: "15", text: "iPhone 15", model: "I" },
  ];
  return items.filter((item) => item.model === model);
};

// export const getMonitorSet = () => {
//     return [
//         { idx: 0, value: '미사용', text: '미사용' },
//         { idx: 1, value: 'GL156UT', text: 'GL156UT (GO)' },
//         { idx: 2, value: 'GL156UTRP', text: 'GL156UTRP (GO)' },
//         { idx: 3, value: 'UL156U4K', text: 'UL156U4K (CarryVision)' },
//         { idx: 4, value: 'V15UPG-B', text: 'V15UPG-B (CarryView)' },
//         { idx: 6, value: 'AIO265', text: 'AIO265' },
//         { idx: 7, value: '27UL550', text: '27UL550' },
//         { idx: 8, value: 'U28H750UQK', text: 'U28H750UQK (SS)' },
//         { idx: 9, value: 'U32J590UQK', text: 'U32J590UQK (SS)' },
//         { idx: 10, value: 'QH43R', text: 'QH43R(SS)' },
//         { idx: 11, value: 'U431UHD', text: 'U431UHD (더함)' },
//         { idx: 12, value: 'DS5501', text: 'DS5501 (LikeMe)' },
//         { idx: 13, value: 'wisecoco', text: 'wisecoco' },
//         { idx: 14, value: '코텍 크리스티 변형', text: '코텍 크리스티 변형' }
//     ]
// };

// export const getLCMList = () => {
//     return [
//         { idx: 0, value: '미사용', text: '미사용' },
//         { idx: 1, value: 'KYOTO2', text: 'KYOTO2 (SHARP)' },
//     ]
// };

// export const getOpencellList = () => {
//     return [
//         { idx: 0, value: '미사용', text: '미사용' },
//     ]
// };

// export const getCameraList = () => {
//     return [
//         { idx: 0, value: '미사용', text: '미사용' },
//         { idx: 1, value: 'AF926H', text: 'AF926H' },
//         { idx: 2, value: 'AF-A11', text: 'AF-A11' },
//         { idx: 3, value: 'Intel RealSense D435', text: 'Intel RealSense D435' }
//     ]
// };

// export const get3DBoxList = () => {
//     return [
//         { idx: 0, value: '미사용', text: '미사용' },
//         { idx: 1, value: 'EM3399', text: 'EM3399' },
//         { idx: 2, value: 'VIM3 Basic', text: 'VIM3 Basic' },
//         { idx: 3, value: 'X', text: 'X' },
//         { idx: 4, value: 'X(Win only)', text: 'X(Win only)' }
//     ]
// };

// export const getLensList = () => {
//     return [
//         { lens_name: "260" },
//         { lens_name: "270" },
//         { lens_name: "280" },
//     ]
// }
