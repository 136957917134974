import React from "react";
import { JSPrintManager, WSStatus, ClientPrintJob, DefaultPrinter, InstalledPrinter } from 'jsprintmanager';
import $ from "jquery";
import { Button } from "@mui/material";
import { LabelDateFormat } from "utils/Dateformat";

const MPPrint = (props => {

    const options = props.options;

    //WebSocket settings
    JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    JSPrintManager.WS.onStatusChanged = function () {
        if (jspmWSStatus()) {
            //get client installed printers
            JSPrintManager.getPrinters().then(function (myPrinters) {
                var options = '';
                for (var i = 0; i < myPrinters.length; i++) {
                    options += '<option>' + myPrinters[i] + '</option>';
                }
                $('#installedPrinterName').html(options);
            });
        }
    };

    //Check JSPM WebSocket status
    function jspmWSStatus() {
        if (JSPrintManager.websocket_status === WSStatus.Open)
            return true;
        else if (JSPrintManager.websocket_status === WSStatus.Closed) {
            //alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
            return false;
        }
        else if (JSPrintManager.websocket_status === WSStatus.Blocked) {
            //alert('JSPM has blocked this website!');
            return false;
        }
    }

    //Do printing...
    function print(option) {

        if (jspmWSStatus()) {
            //Create a ClientPrintJob
            var cpj = new ClientPrintJob();
            //Set Printer type (Refer to the help, there many of them!)
            if ($('#useDefaultPrinter').prop('checked')) {
                cpj.clientPrinter = new DefaultPrinter();
            } else {
                cpj.clientPrinter = new InstalledPrinter($('#installedPrinterName').val());
            }
            var CR = "\x0D";
            var cmds;

            if (options.label_type === "stock") {
                console.trace('Print For Stock');
                //Set content to print...
                //Create Godex EZPL commands for sample label
                cmds = "^Q45,3" + CR;
                cmds += "^W90" + CR;
                cmds += "^H8" + CR;
                cmds += "^P1" + CR;
                cmds += "^S4" + CR;
                cmds += "^AT" + CR;
                cmds += "^C1" + CR;
                cmds += "^R0" + CR;
                cmds += "~Q+0" + CR;
                cmds += "^O0" + CR;
                cmds += "^D0" + CR;
                cmds += "^E18" + CR;
                cmds += "~R255" + CR;
                cmds += "^XSET,ROTATION,0" + CR;
                cmds += "^L" + CR;
                cmds += "Dy2-me-dd" + CR;
                cmds += "Th:m:s" + CR;
                cmds += "Dy2-me-dd" + CR;
                cmds += "Th:m:s" + CR;
                cmds += "AD,331,36,1,1,0,0,LightField LCD Monitor" + CR;
                cmds += "BQ,30,257,2,5,81,0,0," + options.serialnumber + CR;
                cmds += "AB,521,70,1,1,0,0,Made in Korea" + CR;
                cmds += "AT,30,18,110,90,0,0,0,0,MOPIC" + CR;
                cmds += "AC,30,117,1,1,0,0,Model Name: " + options.model_name + CR;
                cmds += "AC,30,149,1,1,0,0,Rating: " + options.rating + CR;
                cmds += "AC,30,181,1,1,0,0,Manufactured: " + LabelDateFormat(new Date(options.manufactured)) + CR;
                cmds += "AC,30,213,1,1,0,0,S/N: " + options.serialnumber + CR;
                cmds += "E" + CR;
                cpj.printerCommands = cmds;
                //Send print job to printer!
                cpj.sendToClient();
            } else if (options.label_type === "release") {
                console.trace('Print For Release');
                //Set content to print...
                //Create Godex EZPL commands for sample label
                cmds = "^Q45,3" + CR;
                cmds += "^W90" + CR;
                cmds += "^H8" + CR;
                cmds += "^P1" + CR;
                cmds += "^S4" + CR;
                cmds += "^AT" + CR;
                cmds += "^C1" + CR;
                cmds += "^R0" + CR;
                cmds += "~Q+0" + CR;
                cmds += "^O0" + CR;
                cmds += "^D0" + CR;
                cmds += "^E18" + CR;
                cmds += "~R255" + CR;
                cmds += "^XSET,ROTATION,0" + CR;
                cmds += "^L" + CR;
                cmds += "Dy2-me-dd" + CR;
                cmds += "Th:m:s" + CR;
                cmds += "Dy2-me-dd" + CR;
                cmds += "Th:m:s" + CR;
                cmds += "AD,356,261,1,1,0,0,LightField LCD Monitor" + CR;
                cmds += "BQ,30,27,2,5,81,0,0," + options.serialnumber + CR;
                cmds += "AB,543,295,1,1,0,0,Made in Korea" + CR;
                cmds += "AT,30,243,110,90,0,0,0,0,MOPIC" + CR;
                cmds += "AC,30,135,1,1,0,0,Model Name: " + options.model_name + CR;
                cmds += "AC,30,167,1,1,0,0,Customer: " + options.customer_company + CR;
                cmds += "AC,30,199,1,1,0,0,S/N: " + options.serialnumber + CR;
                cmds += "E" + CR;
                cpj.printerCommands = cmds;
                //Send print job to printer!
                cpj.sendToClient();
            }
        }
    }

    return (
        <>
            <select name="installedPrinterName" id="installedPrinterName"></select>
            <Button onClick={() => { print() }} autoFocus>
                인쇄
            </Button>
        </>
    );
})

export default MPPrint;