const winOnlyProductList = [
    { license : '37841-06392-41228-87745', pid : '4e5c702b-7df5-4f40-900e-39e53c710377' },
    { license : '70177-71720-44580-55216', pid : '4b7c8572-22a7-46e0-8acf-d05583b1c4f0' },
    { license : '31117-09489-28983-48933', pid : 'a610a368-4687-4320-80e1-a33c90fce45f' },
    { license : '31986-30281-60189-90222', pid : '4dbfc1a1-af65-4a58-9132-9c5030061132' },
    { license : '81048-07850-36408-92682', pid : 'f436377b-29d0-4d44-a3f2-e85bed593782' },
    { license : '13426-21475-95685-21429', pid : '7b0634da-cd21-4642-9bd2-3322b49c2756' },
    { license : '02045-81369-98197-94651', pid : '289c92dc-c331-4bd8-a538-0b426bbb1792' },
    { license : '19319-87495-00894-06548', pid : '2151fd92-44ac-422e-8933-fed946c714f8' },
    { license : '29465-84629-92918-47669', pid : '28dafd19-f922-47b0-9d43-18d8af6848c3' },
    { license : '53398-97668-99206-77097', pid : '2a45e05f-bdaf-4c49-b43c-2a9592abdb81' },
    { license : '46429-18251-19524-51958', pid : '2a65ef81-82d8-475b-b1c8-923bceb513ec' },
    { license : '54880-14698-84627-54590', pid : '2d6a0809-4683-453d-8c62-b89e4a99e5b5' },
    { license : '83775-55983-04649-91740', pid : '336b5b7b-0050-4059-9a06-32100adc1c61' },
    { license : '21979-85413-25015-15625', pid : '34ff42f2-23ee-4c97-bbe1-4d5be81e4641' },
    { license : '76301-40294-41140-33838', pid : '3afcf9c6-9af2-4367-97e8-20adff14e830' },
    { license : '81882-89054-74383-67658', pid : '405366f7-0532-4239-b53d-c898aef7ae5f' },
    { license : '00863-52126-80858-91827', pid : '41fedbd5-3517-4745-af6a-2c60bf30288d' },
    { license : '60128-42145-01541-74121', pid : '4bfe066e-d6eb-47f7-aa8d-d6f8d4f3baac' },
    { license : '16201-57521-67206-60526', pid : '4f23838c-1320-4330-a9fc-4c3cb7d9e61c' },
    { license : '97049-64412-25486-60121', pid : '52ae2c82-ed57-43a5-b2d5-c944b895bb2b' },
    //{ license : '52975-24088-17512-61157', pid : '540280fc-b7cd-41c2-8a37-862fdcf957a6' }, // 폐기 후 U15A731 로 재사용
    { license : '39145-67858-91241-48169', pid : '571c79bf-0cb1-4324-a03c-38ff90e13e39' },
    { license : '96082-96784-01599-27152', pid : '5c14dd0f-5aa8-475f-bd34-f4daf11c68e5' },
    { license : '78507-89872-43371-87265', pid : '5c16d33b-2cb5-46f6-bcb4-297f0224a5b6' },
    { license : '81536-10248-90875-49170', pid : '5f0a673d-c691-4119-97c9-0619c7870738' },
    { license : '98758-08414-10642-96464', pid : '5f688cad-10d3-4f5c-b744-0f93ef415494' },
    { license : '87287-71936-72353-96397', pid : '61f74def-aedb-4786-a158-6f0cec01a7ca' },
    { license : '75214-03662-65585-00877', pid : '676ceceb-e3e1-4cd6-b90b-6337958420a1' },
    { license : '93148-16889-31741-23512', pid : '72f84698-a52e-42d5-a140-eaff5ce94a6c' },
    { license : '54761-68466-95274-73098', pid : '73aa4df3-15fa-4096-8bb5-d925332156c0' },
    { license : '91553-02271-28886-20824', pid : 'a5fb0f32-898c-47ba-88c1-eb44c3f36287' },
    { license : '72852-58879-83169-11645', pid : '05f36c1b-6c87-4e92-9627-ddd4e062872a' },
    { license : '03133-41382-08717-35480', pid : '0c8dac28-e61f-4038-84a3-972f5d6166cf' },
    { license : '51427-03983-72219-98514', pid : '100e6740-ceab-4b39-ad28-7f0c97f411bc' },
    { license : '96808-88156-10734-08885', pid : '1509e7aa-c7b4-443e-9592-0a69f63ae227' },
    { license : '31506-89369-54574-22323', pid : '1a8de7dc-ee8a-4fb8-8a09-3769bcb7ccd0' },
    { license : '26816-13676-23777-13173', pid : '1fb04cc3-4ad0-47ec-8e3f-5812d2d364d5' },
    { license : '73260-79106-01359-75584', pid : 'af580c5f-c0cc-4066-9beb-d6263a51afcf' },
    { license : '46111-14065-55259-07033', pid : 'b1bb7fdf-e27c-4d89-9fb1-d7c33958f3f1' },
    { license : '10679-06459-54280-71303', pid : 'b655e5fa-3a45-43e6-b57d-fddbb927d72c' }, // 폐기
    { license : '70016-17655-56159-64191', pid : 'b8132e53-58e8-4f6b-af83-541b5acb650e' },
    { license : '15251-83550-25259-81909', pid : 'cc10d0f4-a1f8-4af4-a1f2-894d2093929f' },
    { license : '78751-78584-70938-89913', pid : 'd16dfa7c-aa2b-4451-a4b6-71c192deee29' },
    { license : '93628-88666-08068-94798', pid : 'd4663611-4978-4bef-8adf-767f7296171c' },
    { license : '21237-55014-26223-94007', pid : 'f77e6603-b3f9-4a37-a509-91619d6514e5' },
    { license : '22998-70774-69164-92544', pid : '82acc8c0-7908-4329-9802-a3b72a877bbd' },
    { license : '74882-04769-17552-70191', pid : '83e43536-ce2a-4c8d-959e-b904504be06a' },
    { license : '84927-23559-52098-62845', pid : '85e90645-d0a8-4ae9-9aa9-b6748edc91f4' },
    { license : '97357-61908-22605-25329', pid : '913b63c4-3d07-46d3-bbef-105b256e3597' },
    { license : '53582-84390-47894-85854', pid : '91d47656-1a1a-4ad7-9671-c496a453f20b' },
    { license : '79196-61712-79082-42407', pid : '963e1d83-eed1-43b6-a84b-b891fdef6f7a' },
    { license : '14293-15570-87234-14393', pid : '9f5f9872-2769-480e-b6af-1b40ea80cc34' },
    { license : '51311-17624-96354-19538', pid : 'a53bd205-1426-4d69-8a03-363f954866ab' },
    { license : '34535-44615-36641-26577', pid : 'a57295f1-7e72-435b-a470-58d434127fbc' },
    { license : '34894-77783-21869-25532', pid : '04145574-70b8-4421-a78f-0e35077a27ad' }, // 여기까지 솔렉트릭스 제품.

    // { license : '85472-28795-65824-61856', pid : 'e762fe59-e0c0-4871-bb3f-c2222d750e08' },
    // { license : '70178-72892-06922-69271', pid : '281523df-5a26-4c02-8576-88202f6569d6' },
    // { license : '65562-39370-11886-91116', pid : '9e535cdd-7539-4d20-8fbd-f90be4522299' },
    // { license : '82865-39702-69915-72603', pid : '05846f30-9f96-4d6e-bcfc-af7e14f15ab6' },
    // { license : '96906-96334-56586-88487', pid : '3ce251e3-5245-42b4-be50-4abf2a9ce077' },
    // { license : '40839-10663-11015-75347', pid : 'e063b503-4bfa-46e0-a0db-c8b7b795572f' },
    // { license : '57434-52762-51987-12442', pid : 'ce9e7ddb-9640-42a0-b5fd-6e6efe2e408e' },
    // { license : '53526-35798-18042-15731', pid : '56ff33cf-75d2-4b20-99c7-0fdca9f10376' } ,
    { license : '47392-50552-60863-42921', pid : '57afbc09-1c12-4ed5-b971-810db9bdc79d' },// 삭제 예정
    
]

export const isWindowsOnlyLicense = (searchValue) => {
    return winOnlyProductList.some(product => 
        product.pid === searchValue || product.license === searchValue
    );
}


// 예시 사용법
// const searchValue = '4e5c702b-7df5-4f40-900e-39e53c710377';
// const isFound = isValueInArray(searchValue, winOnlyProductList);
// console.log(isFound ? `${searchValue} is found!` : `${searchValue} is not found!`);
