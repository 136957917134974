// base
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
// mui
import { FormControl, InputBase, InputLabel, Typography, Grid, Button, Stack, Container } from '@mui/material';
// hook
import { changeCustomerInfo, QUERYKEY_CUSTOMER } from 'hooks/useCustomer';
import { DateFormat } from 'utils/Dateformat';
import { MopicContext } from 'hooks/useContextManager';

const CustomerUpdate = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_WRITE");
    const has_role_update = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_UPDATE");

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // update customer info
    const onSuccessMutateCustomer = (data, variables, context) => {
        // console.log('Success Update Customer');
        queryClient.invalidateQueries(QUERYKEY_CUSTOMER['getCustomerInfo']);
        alert("수정되었습니다");
        navigate('/customer/list');
    };

    const onErrorMutateCustomer = () => {
        console.log('Error Update Customer');
    };

    const mutate_options_customer = {
        'body': null,
        'callback_success': onSuccessMutateCustomer,
        'callback_error': onErrorMutateCustomer
    };

    const result_mutate_customer = changeCustomerInfo(mutate_options_customer);

    // print variable
    const [obj_customer, setCustomer] = useState({
        customer_id: '',
        customer_company: '',
        customer_name: '',
        customer_department: '',
        customer_rank: '',
        customer_phone: '',
        customer_mail: '',
        customer_address: '',
        customer_note: '',
        mopic_manager: '',
        changed_date: DateFormat(new Date()),
        changed_user: ''
    });

    const [checkstate, setCheckState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                setCustomer({
                    ...data
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_customer])

    const { customer_company, customer_name, customer_department, customer_rank, customer_phone, customer_mail, customer_address, customer_note, mopic_manager } = obj_customer;

    // TODO : regist confirm 정리하자
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function")
        {
            return;
        }
        if (onCancel && typeof onCancel !== "function")
        {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message))
            {
                onConfirm();
            }
            else
            {
                onCancel();
            }
        }

        return confirmAction;
    };

    const updateConfirm = () => {
        if (has_role_write || has_role_update)
        {
            obj_customer.changed_user = account_info.getAccount();
            obj_customer.changed_date = new Date();

            if (obj_customer.customer_company && obj_customer.customer_name && obj_customer.changed_user)
            {
                mutate_options_customer.body = obj_customer;
                result_mutate_customer.mutate();
            }
            else
            {
                alert('필수값을 입력해주세요');
            }
        } else
        {
            alert('고객 수정 권한이 없습니다');
        }
    };

    const cancelConfirm = () => alert("취소되었습니다");

    const confirmUpdate = useConfirm(
        "수정하시겠습니까?",
        updateConfirm,
        cancelConfirm
    );

    const onChangeHandler = (e) => {
        const { value, id } = e.target;
        setCustomer({
            ...obj_customer,
            [id]: value
        })
    };

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }}
                    variant="h6"
                >
                    고객 수정
                </Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-zstd" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>회사명 *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_company" className="inputStyle" value={customer_company} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-k" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>담당자 *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_name" className="inputStyle" value={customer_name} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-lp" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>부서</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_department" className="inputStyle" value={customer_department || ''} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-gn" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>직급</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_rank" className="inputStyle" value={customer_rank || ''} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-spsize" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>연락처</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_phone" className="inputStyle" value={customer_phone || ''} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-dx" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>이메일</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_mail" className="inputStyle" value={customer_mail || ''} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-k" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>회사 주소</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="customer_address" className="inputStyle" value={customer_address} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-dy" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>모픽 담당자</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="mopic_manager" className="inputStyle" value={mopic_manager || ''} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-note" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Note</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2, py: '4px' }}>
                        <InputBase
                            id="customer_note" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', background: '#fff' }}
                            value={customer_note || ''}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="" sx={{ minWidth: 100 }} onClick={confirmUpdate}>수정</Button>
                </Stack>
            </Container>
        </>
    );
};
export default CustomerUpdate;

