// base
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
// mui
import { Typography, Grid, Button, Box, Container, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
// hooks
import { MopicContext } from 'hooks/useContextManager';
import { getModelInfo } from 'hooks/useModel';
// MP Components
import { MPTablePaginationAction, StyledTableCell, StyledTableCellEllipsis } from 'components/MPTable';
// etc
import MPUtils from 'utils/Common';
import { DateFormat } from 'utils/Dateformat';

export default function ModelList() {
  // context
  const account_info = useContext(MopicContext).account_info;
  const listpage_info = useContext(MopicContext).listpage_info;

  // role
  const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
  const has_role_read = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_READ");

  // data
  const [modelrows, setModelRows] = useState([]);
  const use_grid_header_key_model = ["model_name"];

  // page
  const [page, setPage] = useState(0);
  const [rowcount, setRowCount] = useState(10);

  // Effects
  useEffect(() => {
    // global effect
  }, [])

  useEffect(() => {
    setPage(listpage_info.getModelPageIndex());
    setRowCount(listpage_info.getModelPageRowCount());
  }, [listpage_info, page, rowcount]);

  // Query Data
  const onSuccessQueryModel = (data) => {
    let obj_models = [];
    let obj_model = {};
    let obj_modelname = {};

    data.forEach((elem, idx, arr) => {
      obj_model = {};
      for (const key in elem)
      {
        obj_model[key] = elem[key];
      }
      for (const spkey in elem.base)
      {
        if (use_grid_header_key_model.indexOf(spkey) > -1)
        {
          obj_modelname = MPUtils.ValidateModelName(elem.base[spkey]);
          obj_model[spkey] = obj_modelname;
          // console.log(obj_modelname);
        }
      }
      obj_models.push(obj_model);
    })

    setModelRows(obj_models);
  };

  const onErrorQueryModel = (error) => {
    console.log('Error Query Model');
  };

  const query_options_model = {
    'keys': [],
    'callback_success': onSuccessQueryModel,
    'callback_error': onErrorQueryModel
  };

  getModelInfo(query_options_model);

  // Event Handler
  const onPageIndexChange = (e, new_page_idx) => {
    setPage(new_page_idx);
    listpage_info.setModelPageIndex(new_page_idx);
  };

  const onPageRowCountChange = e => {
    const rowcount = parseInt(e.target.value, 10);

    setPage(0);
    setRowCount(rowcount);

    listpage_info.setModelPageIndex(0);
    listpage_info.setModelPageRowCount(rowcount);
  };

  const onModelDetailClick = e => {
    listpage_info.setModelPageIndex(page);
    listpage_info.setModelPageRowCount(rowcount);
  }

  return (
    <>
      {/* Title Container */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItem: 'right',
          flexWrap: 'wrap',
          m: 0
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography sx={{ m: 0 }} variant="h5">모델 관리</Typography>
          </Grid>
          {(has_role_write) ?
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button variant="contained" component={Link} to="/model/write">모델 추가</Button>
            </Grid>
            : null}
        </Grid>
      </Container>
      {/* DataTable Container */}
      <Container sx={{ mx: 0, mt: 3, display: 'flex', flexWrap: 'wrap' }}>
        <TableContainer sx={{}} component={Box}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell component="th" scope="row" align="center">모델명</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '65px' }}> 상태</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '110px' }}>구분</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">DisplaySet</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '175px' }}>Camera</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '110px' }}>등록일</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">Note</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modelrows.slice(page * rowcount, page * rowcount + rowcount).map((row, idx) => {
                return (
                  <TableRow key={idx} hover={true}>
                    <StyledTableCell align="center"><Link to="/model/view" sx={{ cursor: "pointer" }} state={{ data: row }} onClick={onModelDetailClick}>{row.model_name.model_name}</Link></StyledTableCell>
                    <StyledTableCell align="center">{row.base.status_name}</StyledTableCell>
                    <StyledTableCell align="center">{row.base.model_type}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.base.component_monitor_name === '미사용' ?
                        (row.base.component_lcm_name === "미사용" ? row.base.component_opencell_name : row.base.component_lcm_name)
                        : row.base.component_monitor_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.base.component_camera_name}</StyledTableCell>
                    <StyledTableCell align="center">{DateFormat(new Date(row.base.model_released_date))}</StyledTableCell>
                    <StyledTableCellEllipsis align="center" sx={{ maxWidth: '400px' }}>{row.base.model_note}</StyledTableCellEllipsis>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={8}
                  count={modelrows.length}
                  rowsPerPage={rowcount}
                  page={modelrows.length <= 0 ? 0 : page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                  onPageChange={onPageIndexChange}
                  onRowsPerPageChange={onPageRowCountChange}
                  ActionsComponent={MPTablePaginationAction}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};