import { UseQueryKey, FetchGET, FetchPOST, FetchPUT, FetchDELETE } from './useFetch'
import { axiosGet } from './useAxios';

import { URL_API_LIST, URI_QUERY_LIST } from './useMopicAPIConfig.js'

const makeQueries = (objInfo) => {
    const props_len = Object.keys(objInfo).length;
    if (props_len === 0)
    {
        return;
    }

    let arr_queries = [];
    if (objInfo.customer_id)
    {
        arr_queries.push(URI_QUERY_LIST['SEARCH_QUERY_CUSTOMERID'] + "=" + encodeURIComponent(objInfo.customer_id));
    }
    else
    {
        if (objInfo.customer_name)
        {
            arr_queries.push(URI_QUERY_LIST['SEARCH_QUERY_CUSTOMERNAME'] + "=" + encodeURIComponent(objInfo.customer_name));
        }
        if (objInfo.company_name)
        {
            arr_queries.push(URI_QUERY_LIST['SEARCH_QUERY_COMPANYNAME'] + "=" + encodeURIComponent(objInfo.company_name));
        }
        if (objInfo.manager_name)
        {
            arr_queries.push(URI_QUERY_LIST['SEARCH_QUERY_MANAGERNAME'] + "=" + encodeURIComponent(objInfo.manager_name));
        }
    }

    let ret = "";
    arr_queries.forEach((elem, idx, arr) => {
        ret += elem;
        if (idx < arr.length - 1)
        {
            ret += "&";
        }
    })

    return ret;
};

export const QUERYKEY_CUSTOMER = {
    getCustomerInfo: UseQueryKey("GET", "customerinfo"),
    addCustomer: UseQueryKey("POST", "customerinfo"),
    changeCustomerInfo: UseQueryKey("PUT", "customerinfo"),
    deleteCustomer: UseQueryKey("DELETE", "customerinfo")
};

export const getCustomerInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_CUSTOMER['getCustomerInfo'];
    let req_url = URL_API_LIST['API_CUSTOMER_INFORMATION'];
    let req_queries = makeQueries(objOptions.keys);
    if (req_queries)
    {
        req_url += "?" + req_queries;
    }

    return FetchGET(req_url, querykey, objOptions);
};
export const axiosGetCustomerInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_CUSTOMER_INFORMATION'];
    let req_queries = makeQueries(descriptor.keys);
    if (req_queries)
    {
        req_url += "?" + req_queries;
    }

    await axiosGet(req_url, descriptor);
};

export const addCustomer = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_CUSTOMER['addCustomer'];

    let req_url = URL_API_LIST['API_CUSTOMER_INFORMATION'];

    return FetchPOST(req_url, querykey, objOptions);
};

export const changeCustomerInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_CUSTOMER['changeCustomerInfo'];

    let req_url = URL_API_LIST['API_CUSTOMER_INFORMATION'];

    return FetchPUT(req_url, querykey, objOptions);
};

export const deleteCustomer = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_CUSTOMER['deleteCustomer'];

    let req_url = URL_API_LIST['API_CUSTOMER_INFORMATION'];

    return FetchDELETE(req_url, querykey, objOptions);
};