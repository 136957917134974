import { axiosGet,axiosPut, axiosPost, axiosDelete } from './useAxios';
import { URL_API_LIST } from './useMopicAPIConfig.js'

const useModelComponent = {};

useModelComponent.axiosGetAllCameraInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_CAMERA'] + "/info/0";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetUsableCameraInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_CAMERA'] + "/info/0/2";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetAllLensInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_LENS'] + "/info/0";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetUsableLensInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_LENS'] + "/info/0/2";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetAll3DBoxInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_3DBOX'] + "/info/0";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetUsable3DBoxInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_3DBOX'] + "/info/0/2";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetAllDisplaySetInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_DISPLAY'] + "/info/0";

    await axiosGet(req_url, descriptor);
};
useModelComponent.axiosGetUsableDisplaySetInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_DISPLAY'] + "/info/0/2";

    await axiosGet(req_url, descriptor);
};




useModelComponent.axiosChangeCameraInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_CAMERA'] + "/info";
    
    descriptor.url = req_url;
    await axiosPut(descriptor);
};

useModelComponent.axiosAddCameraInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_CAMERA'] + "/info";
    
    descriptor.url = req_url;
    await axiosPost(descriptor);
};
useModelComponent.axiosRemoveCameraInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_CAMERA'] + "/info";
    
    descriptor.url = req_url;
    await axiosDelete(descriptor);
};



useModelComponent.axiosChange3DBoxInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_3DBOX'] + "/info";
    
    descriptor.url = req_url;
    await axiosPut(descriptor);
};

useModelComponent.axiosAdd3DBoxInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_3DBOX'] + "/info";
    
    descriptor.url = req_url;
    await axiosPost(descriptor);
};
useModelComponent.axiosRemove3DBoxInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_3DBOX'] + "/info";
    
    descriptor.url = req_url;
    await axiosDelete(descriptor);
};


useModelComponent.axiosChangeDisplaySetInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_DISPLAY'] + "/info";
    
    descriptor.url = req_url;
    await axiosPut(descriptor);
};

useModelComponent.axiosAddDisplaySetInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_DISPLAY'] + "/info";
    
    descriptor.url = req_url;
    await axiosPost(descriptor);
};
useModelComponent.axiosRemoveDisplaySetInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_DISPLAY'] + "/info";
    
    descriptor.url = req_url;
    await axiosDelete(descriptor);
};


useModelComponent.axiosChangeLensInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_LENS'] + "/info";
    
    descriptor.url = req_url;
    await axiosPut(descriptor);
};

useModelComponent.axiosAddLensInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_LENS'] + "/info";
    
    descriptor.url = req_url;
    await axiosPost(descriptor);
};
useModelComponent.axiosRemoveLensInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODELCOMPONENT_LENS'] + "/info";
    
    descriptor.url = req_url;
    await axiosDelete(descriptor);
};



export default useModelComponent;