/* eslint-disable */

import { createTheme } from '@mui/material/styles';

let MPTheme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#24292e',
      dark: '#24292e',
    },
  },
  typography: {
    h5: { fontWeight: 500, fontSize: 26, letterSpacing: 0.5, },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

export const theme_buttom = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#24292e',
      dark: '#24292e',
    },
    stock: {
      main: '#2e7d32',
      light: '4caf50',
      dark: '1b5e20'
    },
    prerelease: {
      main: '#9C27B0',
      light: '#BC2FD4',
      dark: '#8C239E'
    },
    release: {
      main: '#0288D1',
      light: '#0292E0',
      dark: '#0276B5'
    },
    recall: {
      main: '#E3CC0E',
      light: '#F5DC0F',
      dark: '#D4BE0D'
    },
    defective: {
      main: '#E35D22',
      light: '#F06224',
      dark: '#C7511E'
    },
    dispose: {
      main: '#D32F2F',
      light: '#E33333',
      dark: '#C92D2D'
    },
    rental: {
      main: '#0D9AE8',
      light: '#0DA3F5',
      dark: '#0C92DB'
    },
    inneruse: {
      main: '#527CE3',
      light: '#5785F2',
      dark: '#4B73D1'
    }
  },
});

MPTheme = {
  ...MPTheme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#24292e',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: MPTheme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: MPTheme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [MPTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: MPTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: MPTheme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: MPTheme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

export default MPTheme;


