import { Box, Button, gridClasses } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GetAxiosCommonDescriptior } from "hooks/useAxios";
import { axiosPostProductIssued } from "hooks/useProduct";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LogDataGrid = (prop) => {
    const navigate = useNavigate();
    const  {logdata, onLogClick, page, onChangeListPage} = prop;

  useEffect(() => {
    //console.log(page)
  });

  const handlePageChange = (params) => {
    onChangeListPage(params);
  };


  const onSuccessProductIssued = (data) => {
    navigate('/product/view', { state: { data: data.product_id } });
  };
  
  const onErrorAxiosCommons = (data) => {
    //console.log(data);
  }

  const columns = [
    { field: 'id', headerName: 'ID', sortable: false , hide: true},
    { field: 'date', headerName: 'Date', width: 200},
    { field: 'serial', headerName: 'Serial Number', width: 200,
      renderCell: (params) => (
        <Link to="" className="logLinkSerial" onClick={(e)=> {
            console.log(params.row);
          let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductIssued, onErrorAxiosCommons);
          axiosDescriptor.data = {serialnumber: params.value};

          axiosPostProductIssued(axiosDescriptor);
          e.preventDefault();
          return false;
        }} sx={{ cursor: "pointer" }}>{params.value}</Link>
      ),
    },
    { field: 'type', headerName: 'Type', type: 'string', width: 100 },
    { field: 'log', headerName: 'Log', minWidth: 600, flex: 1, sortable: false,  minWidth:576,
      renderCell: (params) => (
        <Button onClick={()=> {onLogClick(params.row)}} sx={{textAlign:"left",  paddingTop:"5px", paddingBottom:"5px", letterSpacing:"normal"}}>
          <span title={params.value}>{params.value}</span>
        </Button>
      )
   },
   { field: 'customer_company', headerName: 'Customer', type: 'string', width: 200 },
  ];

  
  function CustomNoRowsOverlay() {
    return (
      <Box sx={{ mt: 6, textAlign:'center' }}>조회된 로그 결과가 없습니다.</Box>
    );
  }

    return (<>
    <Box sx={{  width: '100%' }} className='logContWrap' mt={2}>
        <DataGrid
          className='logCont'
          rows={logdata}
          columns={columns}
          autoHeight 
          getRowHeight={() => 'auto'}
          disablePortal
          disableRowSelectionOnClick
          page={parseInt(page)}
          onPageChange={handlePageChange}

          
          components={{
            NoRowsOverlay: CustomNoRowsOverlay
          }}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 1000]}
          pagination
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1, fontSize: "13px"
            },
          }}
        //   filterModel={filterModel}
          disableMultipleColumnsFiltering={true}
        />
      </Box>
    </>);
  }
  

export default LogDataGrid;

