import PropTypes from 'prop-types';

import { Box, IconButton, TableCell, tableCellClasses } from '@mui/material';

import { styled } from '@mui/material/styles';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#24292e',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  [`& a`]: {
    color: '#24292e'
  }
}));
export const StyledTableCellEllipsis = styled(StyledTableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export const MPTablePaginationAction = props => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const onFirstPageButtonClick = e => {
    onPageChange(e, 0);
  };
  const onLastPageButtonClick = e => {
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const onPrevPageButtonClick = e => {
    onPageChange(e, page - 1);
  };

  const onNextPageButtonClick = e => {
    onPageChange(e, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={onFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={onPrevPageButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={onNextPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={onLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

MPTablePaginationAction.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};