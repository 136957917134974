import { Box, Button, Card, CardContent, CardHeader, Container } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../components/auth/config";

function onMsalLogin(instance, type) {
    if (type === "redirect")
    {
        instance.loginRedirect(loginRequest)
        .catch(e => { 
            console.error(e); 
        })
    }
    else if (type === "popup")
    {
        instance.loginPopup(loginRequest)
        .catch(e => { 
            console.error(e); 
        })
    }
}

export default function MPLogin() {

    const { instance } = useMsal();
    return ( 
        <>
            <Box sx={{ display: 'flex',alignItems:'center', justifyContent:'center', height:'100vh' }}>
                <Card >
                    <CardHeader
                    title="MOPIC" sx={{textAlign:'center', backgroundColor:'#24292e', p:3, color:'#fff',
                        '& .MuiTypography-root' : { fontWeight:'700'}
                    }}
                    />
                    <CardContent>
                        <Container  sx={{ m : 0, p:3 ,display: 'display', width:'400px', textAlign:'center'}}>
                        <h3>Sign in</h3>
                        <p>
                            Microsoft Teams 계정과 연동해주세요.
                        </p>
                        <Button size="large" variant="contained" onClick={() => onMsalLogin(instance, 'redirect')} sx={{mt:4}}>로그인</Button>
                        </Container>
                    </CardContent>
                </Card>
                
            </Box>
        </>
    );
}