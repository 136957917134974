// base
import * as React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// mui
import {
  Card, CardContent, Checkbox, FormGroup, FormControlLabel,
  InputBase, InputLabel, Typography,
  Grid, Box, Container,
  Button, Select, MenuItem,
  FormControl,
  Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
} from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DeveloperModeRoundedIcon from "@mui/icons-material/DeveloperModeRounded";
// hooks
import { GetAxiosCommonDescriptior, GetAxiosGetDescriptior } from "hooks/useAxios";
import { getProductListInfo, getProductStatusList, changeProductStatus, changeProductMultiStatus, changeProductMultiCustomers, axiosGetProductLabelInfo, axiosGetUsedModelNameList, QUERYKEY_PRODUCT } from "hooks/useProduct";
import { MopicContext } from "hooks/useContextManager";
// MP Components
import { MPTablePaginationAction, StyledTableCell } from "components/MPTable";
import DialogProductManagement from "components/ProductManagementDialog";
import MPButton, { MPStatusButton } from "components/MPButton";

import { axiosGetCustomerInfo } from 'hooks/useCustomer';
import MPLabel from "components/MPLabel";
import MPPrint from "components/MPPrint";
// etc
import _ from "lodash";
import { DateFormat } from "utils/Dateformat";
import {isWindowsOnlyLicense} from "utils/WindowsOnlyProductList";
import { grey } from '@mui/material/colors';

export default function ProductList() {
  // context
  const account_info = useContext(MopicContext).account_info;
  const listpage_info = useContext(MopicContext).listpage_info;

  const componentRef = useRef(null);
  const queryClient = useQueryClient();

  // role
  const has_role_write = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_WRITE");
  const has_role_update = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_UPDATE");

  // data
  const [list_modelname, setModelNameList] = useState([]);
  const [findstatusrows, setFindStatusRows] = useState([]);
  const [customerrow, setCustomerRows] = useState([]);
  const [productrows, setProductRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [filterStats,setFilterStats] =  useState([]);

  const use_key_customerinfo = ["customer_company"];
  const use_key_modelinfo = ["model_name"];
  const use_key_statusinfo = ["status_idx", "status_name"];

  // dialog
  const [barcode_dialog_open, setBarcodeDialogOpen] = useState(false);

  // 상태 변경 팝업
  const [status_dialog_open, setStatusDialogOpen] = useState(false);
  const [multi_status_dialog_open, setMultiStatusDialogOpen] = useState(false);
  const [multi_customer_dialog_open, setMultiCustomerDialogOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState();

  // Count Info
  let obj_count = {};
  const [statuscnt, setStatusCnt] = useState({});

  const [startDate, setStartDate] = useState(listpage_info.getProductSearchKeyStartDate() || null);
  const [endDate, setEndDate] = useState(listpage_info.getProductSearchKeyEndDate() || null);

  // page
  const [page, setPage] = useState(0);
  const [rowcount, setRowCount] = useState(10);
  const [rows, setRows] = useState(null);
  
  const [filter_status, setFilterStatus] = useState([1,2,3,4,5,6,7,8]);
  
  const [selected, setSelected] = React.useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;


  
  const handleClickCheckBox = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    //console.log(selected);
  };


  // Utils
  const initProductStatus = (model_name) => {
    if (!obj_count._all)
    {
      obj_count._all = { cnt_1: 0, cnt_2: 0, cnt_3: 0, cnt_4: 0, cnt_5: 0, cnt_6: 0, cnt_7: 0, cnt_8: 0 };
    }
    obj_count[model_name] = { cnt_1: 0, cnt_2: 0, cnt_3: 0, cnt_4: 0, cnt_5: 0, cnt_6: 0, cnt_7: 0, cnt_8: 0 };
  };
  const CountProductStatus = (model_name, status_idx) => {
    obj_count._all["cnt_" + status_idx]++;
    obj_count[model_name]["cnt_" + status_idx]++;
  };

  // Product List
  const onSuccessQueryProduct = (data) => {
    let obj_products = [];
    let obj_product = {};
    let obj_models = [];
    let obj_model = {};
    let obj_customers = [];
    let obj_customer = {};
    let obj_statuss = [];
    let obj_status = {};

    obj_count = {};
    let initialized_product_status_cache_list = [];

    data.forEach((elem) => {
      obj_product = elem;
      obj_model = {};
      obj_status = {};

      const windowOnly = isWindowsOnlyLicense(elem.product_id);
      obj_product.windowOnly = windowOnly;
      for (const spkey in elem)
      {
        if (use_key_customerinfo.indexOf(spkey) > -1 && elem[spkey])
        {
          obj_customer = {};
          obj_customer[spkey] = elem[spkey];
          obj_customers.push(obj_customer);
        }
        if (use_key_modelinfo.indexOf(spkey) > -1)
        {
          obj_model[spkey] = elem[spkey];
        }
        if (use_key_statusinfo.indexOf(spkey) > -1)
        {
          obj_status[spkey] = elem[spkey];
        }
      }

      if (initialized_product_status_cache_list.indexOf(elem.model_name) === -1)
      {
        initProductStatus(elem.model_name);
        initialized_product_status_cache_list.push(elem.model_name);
      }

      CountProductStatus(elem.model_name, elem.status_idx);

      obj_products.push(obj_product);
      obj_models.push(obj_model);
      obj_statuss.push(obj_status);
    });

    // product list
    setRowData(obj_products);
    setProductRows(fnStatusFilter(obj_products));
    // console.log(obj_products);
    //setRows(obj_products.slice(page * rowcount, page * rowcount + rowcount))
    

    // customer list
    //console.log(obj_products);
    const uniqueCustomers = _.uniqBy(obj_customers, "customer_company");
    uniqueCustomers.sort((a, b) => {
      const company_a = a.customer_company.toUpperCase();
      const company_b = b.customer_company.toUpperCase();
      if (company_a < company_b)
      {
        return -1;
      }
      if (company_a > company_b)
      {
        return 1;
      }

      return 0;
    })
    setCustomerRows(uniqueCustomers);
    // status list
    const uniqueStatus = _.uniqBy(obj_statuss, "status_idx");
    setFindStatusRows(uniqueStatus);

    setStatusCnt(obj_count);
  };
  const fnStatusFilter = (data) => {
    let f = data.filter(item => filter_status.includes(item.status_idx)); 
    
    return f;
  }
  const onErrorQueryProduct = (error) => {
    console.log("Error Query Product");
  };

  const [query_options_product, setQueryOptionProduct] = useState({
    keys: {
      customer_company: listpage_info.getProductSearchKeyCustomer() || "",
      model_name: listpage_info.getProductSearchKeyModelName() || "",
      model_serialnumber: listpage_info.getProductSearchKeyModelSerialNumber() || "",
      status_idx: listpage_info.getProductSearchKeyStatus() || "",
      manufacture_start_date: listpage_info.getProductSearchKeyStartDate() ? listpage_info.getProductSearchKeyStartDate().toISOString() : "",
      manufacture_end_date: listpage_info.getProductSearchKeyEndDate() ? listpage_info.getProductSearchKeyEndDate().toISOString() : "",
      product_serialnumber: listpage_info.getProductSearchKeySerialNumber() || "",
    },
    callback_success: onSuccessQueryProduct,
    callback_error: onErrorQueryProduct,
  });
  getProductListInfo(query_options_product);

  // Label Data
  const [obj_label, setLabel] = useState({});

  const onSuccessGetProductLabelData = (data) => {
    if (data.product_id)
    {
      data.label_type = "stock";
      setLabel(data);
      setBarcodeDialogOpen(true);
    }
  };

  const onErrorGetProductLabelData = (data) => {
    console.log(data);
  };

  // Unique ModelName Data
  const onSuccessGetUsedModelNameListData = (data) => {
    setModelNameList(data);
  };

  const onErrorGetUsedModelNameListData = (data) => {
    console.log("onErrorGetUsedModelNameListData");
    console.log(data);
  };

  // Status Change
  const onSuccessMutateStatus = (data) => {
    setTimeout(() => {
      queryClient.invalidateQueries(QUERYKEY_PRODUCT["getProductListInfo"]);
      alert("변경되었습니다");
    }, 500);
  };

  const onErrorMutateStatus = (error) => {
    console.log(error);
    console.log("Error Change Status");
  };

  const getCustomerInfo = (customer_company, customer_name) => {
    if (customer_company === "삭제" || customer_company === null) {
      return "";
    }
    return customer_company + " (" + customer_name + ")";
  }
  const [obj_option_status, setOptionStatus] = useState({
    product_id: "",
    status_idx: "",
    changed_date: "",
    changed_user: "",
  });
  const [obj_option_multi_status, setOptionMultiStatus] = useState({});

  const mutate_options_status = {
    body: obj_option_status,
    callback_success: onSuccessMutateStatus,
    callback_error: onErrorMutateStatus,
  };
  const mutate_options_multi_status = {
    body: obj_option_multi_status,
    callback_success: onSuccessMutateStatus,
    callback_error: onErrorMutateStatus,
  };
  const mutate_options_multi_customer = {
    body: obj_option_multi_status,
    callback_success: onSuccessMutateStatus,
    callback_error: onErrorMutateStatus,
  };

  const result_mutate_product_status = changeProductStatus(
    mutate_options_status
  );
  const result_mutate_multi_product_status = changeProductMultiStatus(
    mutate_options_multi_status
  );
  const result_mutate_multi_product_customer = changeProductMultiCustomers(
    mutate_options_multi_customer
  );
  
  // Event Handler
  const onPageIndexChange = (event, newPage) => {
    setPage(newPage);
    listpage_info.setProductPageIndex(newPage);
  };

  const onPageRowCountChange = (event) => {
    const rowcount = parseInt(event.target.value, 10);

    setPage(0);
    setRowCount(rowcount);

    listpage_info.setProductPageIndex(0);
    listpage_info.setProductPageRowCount(rowcount);
  };

  const onChangeFindValueCommon = (event) => {
    const { value, name } = event.target;
    switch (name)
    {
      case "model_name":
        listpage_info.setProductSearchKeyModelName(value);
        break;
      case "model_serialnumber":
        listpage_info.setProductSearchKeyModelSerialNumber(value);
        break;
      case "customer_company":
        listpage_info.setProductSearchKeyCustomer(value);
        break;
      case "product_serialnumber":
        listpage_info.setProductSearchKeySerialNumber(value);
        break;
      case "status_idx":
        listpage_info.setProductSearchKeyStatus(value);
        break;
      default:
        break;
    }
    setQueryOptionProduct({
      ...query_options_product,
      keys: {
        ...query_options_product.keys,
        [name]: value,
      },
    });

    queryClient.removeQueries(QUERYKEY_PRODUCT["getProductListInfo"]);
  };
  const onChangeFindValueStartDate = (date) => {
    let date_obj = new Date(date);
    setStartDate(date_obj);
    listpage_info.setProductSearchKeyStartDate(date_obj);

    setQueryOptionProduct({
      ...query_options_product,
      keys: {
        ...query_options_product.keys,
        manufacture_start_date: date_obj.toISOString(),
      },
    });
    queryClient.removeQueries(QUERYKEY_PRODUCT["getProductListInfo"]);
  };
  const onChangeFindValueEndDate = (date) => {
    let date_obj = new Date(date);
    setEndDate(date_obj);
    listpage_info.setProductSearchKeyEndDate(date_obj);

    setQueryOptionProduct({
      ...query_options_product,
      keys: {
        ...query_options_product.keys,
        manufacture_end_date: date_obj.toISOString(),
      },
    });
    queryClient.removeQueries(QUERYKEY_PRODUCT["getProductListInfo"]);
  };

  const onProductDetailClick = (e) => {
    listpage_info.setProductPageIndex(page);
    listpage_info.setProductPageRowCount(rowcount);

    if (query_options_product.keys.model_name)
      listpage_info.setProductSearchKeyModelName(query_options_product.keys.model_name);
    if (query_options_product.keys.model_serialnumber)
      listpage_info.setProductSearchKeyModelSerialNumber(query_options_product.keys.model_serialnumber);
    if (query_options_product.keys.customer_company)
      listpage_info.setProductSearchKeyCustomer(query_options_product.keys.customer_company);
    if (query_options_product.keys.product_serialnumber)
      listpage_info.setProductSearchKeySerialNumber(query_options_product.keys.product_serialnumber);
    if (query_options_product.keys.status_idx)
      listpage_info.setProductSearchKeyStatus(query_options_product.keys.status_idx);
    if (query_options_product.keys.manufacture_start_date)
      listpage_info.setProductSearchKeyStartDate(new Date(query_options_product.keys.manufacture_start_date));
    if (query_options_product.keys.manufacture_end_date)
      listpage_info.setProductSearchKeyEndDate(new Date(query_options_product.keys.manufacture_end_date));
  };

  const onBarcodeDialogOpen = (row) => {
    if (row)
    {
      let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessGetProductLabelData, onErrorGetProductLabelData);
      axiosDescriptor.keys["product_id"] = row.product_id;

      axiosGetProductLabelInfo(axiosDescriptor);
    }
  };

  const onBarcodeDialogClose = () => {
    setBarcodeDialogOpen(false);
  };

  const onStatusChangeDialogOpenMulti = () => {
    if (has_role_update)
    {
      
      setMultiStatusDialogOpen(true);
      //console.log('onStatusChangeDialogOpenMulti' );
    }
    else
    {
      alert("상태 변경 권한이 없습니다");
    }
  };
  const onCustomerChangeDialogOpenMulti = () => {
    if (has_role_update)
    {
      
      setMultiCustomerDialogOpen(true);
      //console.log('onStatusChangeDialogOpenMulti' );
    }
    else
    {
      alert("상태 변경 권한이 없습니다");
    }
  };

  const onStatusChangeDialogOpen = (row) => {
    // console.log('onStatusChangeDialogOpen', row);
    if (has_role_update)
    {
      setOptionStatus({
        product_id: row.product_id,
        status_idx: row.status_idx,
        changed_date: "",
        changed_user: "",
      });
      setSelectedValue(row);
      setStatusDialogOpen(true);
    }
    else
    {
      alert("상태 변경 권한이 없습니다");
    }
  };

  const onMultiStatusChangeDialogClose = (obj) => {
    setMultiStatusDialogOpen(false);
    if (!obj || typeof obj == "string")
    {
      console.log("multi status dialog action cancel", obj);
    }
    else if (obj)
    {
      //obj_option_status.product_id = obj.product_id;
      let jsonObject = {
        "products" : []
      };
      jsonObject.products = selected;
      
      obj_option_multi_status.product_id = JSON.stringify(jsonObject);
      obj_option_multi_status.status_idx = obj.status_idx;
      obj_option_multi_status.changed_date = new Date();
      obj_option_multi_status.changed_user = account_info.getAccount();
      
      result_mutate_multi_product_status.mutate();
      
    }
  };
  const onMultiCustomerChangeDialogClose = (obj) => {
    setMultiCustomerDialogOpen(false);
    if (!obj || typeof obj == "string")
    {
      console.log("multi customer dialog action cancel", obj);
    }
    else if (obj)
    {
      //obj_option_status.product_id = obj.product_id;
      let jsonObject = {
        "products" : []
      };
      jsonObject.products = selected;
      
      obj_option_multi_status.product_id = JSON.stringify(jsonObject);
      obj_option_multi_status.customer_id = obj.customer_id;
      obj_option_multi_status.changed_date = new Date();
      obj_option_multi_status.changed_user = account_info.getAccount();
      // console.log(obj_option_multi_status.product_id);
      result_mutate_multi_product_customer.mutate();
      
    }
  };
  const onStatusChangeDialogClose = (obj) => {
    setStatusDialogOpen(false);
    if (!obj || typeof obj == "string")
    {
      console.log("status dialog action cancel");
    }
    else if (obj)
    {
      if (obj_option_status.status_idx !== obj.status_idx)
      {
        obj_option_status.product_id = obj.product_id;
        obj_option_status.status_idx = obj.status_idx;
        obj_option_status.changed_date = new Date();
        obj_option_status.changed_user = account_info.getAccount();
        result_mutate_product_status.mutate();
      }
      else
      {
        console.log("no change status");
      }
    }
  };
  const onProductAddDialogClose = () => {
    queryClient.removeQueries(QUERYKEY_PRODUCT["getProductListInfo"]);
  };

  // Effect
  useEffect(() => {
    // global effect
    let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessGetUsedModelNameListData, onErrorGetUsedModelNameListData);
    axiosGetUsedModelNameList(axiosDescriptor);
  }, []);
  useEffect(() => {
    
    setProductRows(fnStatusFilter(rowData));
    // console.log('filter_status',filter_status);
    // console.log(rowData);
  }, [filter_status]);

  useEffect(() => {
    setPage(listpage_info.getProductPageIndex());
    setRowCount(listpage_info.getProductPageRowCount());
    //setRows(productrows.slice(page * rowcount, page * rowcount + rowcount));
    //setProductRows(fnStatusFilter(productrows));
  }, [listpage_info, page, rowcount]);

  const onClickStatusBtn = (e) => {
    setFilterStatus([]);
    let idx = Number(e.target.value);
    let temp_status = filter_status;
    if (temp_status.includes(idx))
    {
      temp_status = temp_status.filter((item) => item !== idx);
    }
    else {
      temp_status.push(idx);
    }
    // console.log('onClickStatusBtn', temp_status);
    setFilterStatus(temp_status);
    setProductRows(fnStatusFilter(rowData));
  }
  const handleSelectAllClick = (event) => {
    // console.log('handleSelectAllClick', rows)
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.product_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  
  return (
    <>
      {/* Title Container */}
      <Container sx={{ m: 0, p:0 }} className="product_list_wrap">
        <Container sx={{ display: "flex", justifyContent: "space-between", alignItem: "right", flexWrap: "wrap", m: 0, }}>
          <Typography sx={{ m: 0, fontWeight:700 }} variant="h5" className="page_title">
            제품 관리
          </Typography>
        </Container>
        {/* Search Container */}
        <Container sx={{ mx: 0, mt: 2, mb: 5, display: "flex", flexWrap: "wrap" }} className="searchWrap">
          <Card sx={{ width: "100%", p: 2 }}>
            <CardContent>
              <Grid container spacing={2} sx={{ fontSize: "14px", itemAlign: "center" }}>
                <Grid item xs={4} md={2} sx={{}} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px"}}>
                    모델
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <FormControl sx={{ m: 0,maxWidth:"80%" }} fullWidth>
                    <Select name="model_name" className="inputStyle" displayEmpty input={<InputBase />} value={list_modelname.length > 0 ? query_options_product.keys.model_name : ""} onChange={onChangeFindValueCommon}>
                      <MenuItem value="">모델을 선택해주세요</MenuItem>
                      {list_modelname.map((row, idx) => (
                        <MenuItem key={idx} value={row.model_name}>
                          {row.model_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2} sx={{}} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    고객사
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <FormControl sx={{ m: 0,maxWidth:"80%" }} fullWidth>
                    <Select name="customer_company" className="inputStyle" displayEmpty input={<InputBase />} value={customerrow.length > 0 ? query_options_product.keys.customer_company : ""} onChange={onChangeFindValueCommon}>
                      <MenuItem value="">고객사를 선택해주세요</MenuItem>
                      {customerrow.map((row, idx) => (
                        <MenuItem key={idx} value={row.customer_company}>
                          {row.customer_company}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px"}}>
                    상태
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <FormControl sx={{ m: 0,maxWidth:"80%" }} fullWidth>
                    <Select name="status_idx" className="inputStyle" displayEmpty input={<InputBase />} value={findstatusrows.length > 0 ? query_options_product.keys.status_idx : ""} onChange={onChangeFindValueCommon}>
                      <MenuItem value="">상태를 선택해주세요</MenuItem>
                      {findstatusrows.map((row, idx) => (
                        <MenuItem key={idx} value={row.status_idx}>
                          {row.status_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2} sx={{}} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    기간
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <Grid container spacing={0} sx={{maxWidth:"80%"}}>
                    <Grid item xs={5}
                      sx={{
                        "& .MuiInputBase-root": { width: "100%", textAlign: "center" },
                        "& .MuiInputBase-root input": { textAlign: "center", color: "rgba(0,0,0,0.9)" }
                      }}
                    >
                      <DatePicker className="inputStyle" customInput={<InputBase />} dateFormat={"yyyy-MM-dd"} selected={startDate} selectsStart startDate={startDate} endDate={endDate} maxDate={endDate} onChange={onChangeFindValueStartDate} />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        textAlign: "center",
                        px: 1,
                        lineHeight: "42px",
                        fontSize: "14px",
                      }}
                    >
                      <span> ~ </span>
                    </Grid>
                    <Grid item xs={5} 
                      sx={{
                        "& .MuiInputBase-root": { width: "100%" },
                        "& .MuiInputBase-root input": { textAlign: "center" }
                      }}
                    >
                      <DatePicker
                        fullWidth
                        selected={endDate}
                        dateFormat={"yyyy-MM-dd"}
                        onChange={onChangeFindValueEndDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        customInput={<InputBase />}
                        className="inputStyle"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    License
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <InputBase name="product_serialnumber" sx={{maxWidth:"80%"}} className="inputStyle" fullWidth value={query_options_product.keys.product_serialnumber} onChange={onChangeFindValueCommon} />
                </Grid>
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    Model S/N
                  </Typography>
                </Grid>
                <Grid item xs={8} md={4} className="searchContent">
                  <InputBase name="model_serialnumber" sx={{maxWidth:"80%"}} className="inputStyle" fullWidth value={query_options_product.keys.model_serialnumber} onChange={onChangeFindValueCommon} />
                </Grid>
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    수량
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent mx100">
                  <FormGroup aria-label="status" row>
                    <FormControlLabel sx={{mr:3}} label={"재고 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_1 || statuscnt._all?.cnt_1 || 0)} onChange={onClickStatusBtn}control={<Checkbox  size="small" defaultChecked color="default" value={1} />} />
                    <FormControlLabel sx={{mr:3}} label={"출고대기 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_2 || statuscnt._all?.cnt_2 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={2} />} />
                    <FormControlLabel sx={{mr:3}} label={"출고 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_3 || statuscnt._all?.cnt_3 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={3} />} />
                    <FormControlLabel sx={{mr:3}} label={"대여 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_7 || statuscnt._all?.cnt_7 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={7} />} />
                    <FormControlLabel sx={{mr:3}} label={"내부사용 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_8 || statuscnt._all?.cnt_8 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={8} />} />
                    <FormControlLabel sx={{mr:3}} label={"회수 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_4 || statuscnt._all?.cnt_4 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={4} />} />
                    <FormControlLabel sx={{mr:3}} label={"재고 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_5 || statuscnt._all?.cnt_5 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={5} />} />
                    <FormControlLabel sx={{mr:0}} label={"폐기 : " + (statuscnt[query_options_product.keys.model_name]?.cnt_6 || statuscnt._all?.cnt_6 || 0)} onChange={onClickStatusBtn} control={<Checkbox  size="small" defaultChecked color="default"  value={6} />} />
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        
        <Container sx={{ mx: 0, mt: 1, display: "flex" }} className="productlist_btn_wrap">
          { selected.length ? 
            <>
            <MPButton variant="contained" text={selected.length + "개 제품 상태 변경"} callbackfn={onStatusChangeDialogOpenMulti}></MPButton>
            <MPButton variant="contained" text={selected.length + "개 제품 고객 변경"} callbackfn={onCustomerChangeDialogOpenMulti} sx={{m:1}}></MPButton>
            </>
          : null }
          <ProductAddButtons has_role={has_role_write} callbackfn={onProductAddDialogClose}></ProductAddButtons>
        </Container>
        
        {/* DataTable Container */}
        <Container sx={{ mx: 0, mt: 3, display: "flex", flexWrap: "wrap" }} className="list_box">
          <TableContainer sx={{}} component={Box} className="product_list">
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    {/* 전체 체크 박스 기능 봉인. */}
                    {/* <Checkbox
                      sx={{
                        color: grey[100],
                        '&.Mui-checked': {
                          color: grey[200],
                        },
                      }}
                      checked={rowcount > 0 && selected.length === rowcount}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    /> */}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Model
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: 105 }}>
                    Model S/N
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: 210 }}>
                    License
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Customer
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Registered Date
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    H/W 
                    {/* Status */}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    S/W 
                    {/* Status */}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Status
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: 108 }}>
                    Label
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productrows
                  .slice(page * rowcount, page * rowcount + rowcount)
                  .map((row, idx) => {
                    
                    const isItemSelected = isSelected(row.product_id);
                    const labelId = `enhanced-table-checkbox-${idx}`;
                    return (
                      <TableRow key={idx} hover={true}>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onClick={(event) => handleClickCheckBox(event, row.product_id)}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row.model_name}>
                          <Link to="/product/view" sx={{ cursor: "pointer" }} state={{ data: row.product_id }} onClick={onProductDetailClick}>
                            {row.model_name}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row.model_serialnumber ? row.model_serialnumber : "Empty"}>
                          <Link to="/product/view" sx={{ cursor: "pointer" }} state={{ data: row.product_id }} onClick={onProductDetailClick}>
                            {row.model_serialnumber ? row.model_serialnumber : "Empty"}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center" title={row.serialnumber} >
                          <Link to="/product/view" sx={{ cursor: "pointer" }} state={{ data: row.product_id }} onClick={onProductDetailClick} > {/* className={row.windowOnly ? "windowsOnly" : ""} */}
                            {row.serialnumber} 
                            {/* {row.windowOnly ? "(Windows Only)" : ""} */}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center" title={getCustomerInfo(row.customer_company, row.customer_name)}>
                          {getCustomerInfo(row.customer_company, row.customer_name)}
                        </StyledTableCell>
                        <StyledTableCell align="center" title={DateFormat(new Date(row.changed_date))}>
                          {DateFormat(new Date(row.changed_date))}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <ProductHardwareStatus data={row} />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <ProductSoftwareStatus data={row} />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <MPStatusButton variant="outlined" rowdata={row} text={row.status_name} status_idx={row.status_idx} callbackfn={onStatusChangeDialogOpen}></MPStatusButton>
                        </StyledTableCell>
                        <StyledTableCell align="center" width="120" sx={{ px: 1 }}>
                          <MPButton variant="contained" rowdata={row} text="재고라벨" callbackfn={onBarcodeDialogOpen}></MPButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

              <TableFooter>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={10}
                    count={productrows.length}
                    rowsPerPage={rowcount}
                    page={productrows.length <= 0 ? 0 : page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={onPageIndexChange}
                    onRowsPerPageChange={onPageRowCountChange}
                    ActionsComponent={MPTablePaginationAction}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Container>
      </Container>

      {selectedValue ? <ProductStatDialog selectedValue={selectedValue || {}} open={status_dialog_open} onClose={onStatusChangeDialogClose} /> : null}
      <ProductMultiStatDialog selectedValue={{}} open={multi_status_dialog_open} onClose={onMultiStatusChangeDialogClose} />
      <ProductMultiCustomerDialog selectedValue={{}} open={multi_customer_dialog_open} onClose={onMultiCustomerChangeDialogClose} />
      
      <Dialog open={barcode_dialog_open} onClose={onBarcodeDialogClose}>
        <div ref={componentRef}>
          <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: "#24292e", color: "#fff" }}>
            재고용 라벨
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ pt: 2 }}>
              <MPLabel options={obj_label} />
            </DialogContentText>
          </DialogContent>
        </div>
        <DialogActions sx={{ mx: 2 }}>
          <MPPrint options={obj_label}></MPPrint>
          <MPButton variant="contained" text="닫기" callbackfn={onBarcodeDialogClose}></MPButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// 상태 변경 팝업
const ProductStatDialog = (props) => {
  const { onClose, selectedValue, open } = props;
  const [prd_stat, set_prd_stat] = useState();

  // Status
  const [obj_productstatus, setProductStatus] = useState();

  const onSuccessQueryProductStatus = (data) => {
    setProductStatus(data);
  };

  const onErrorQueryProductStatus = (error) => {
    console.log("error");
  };

  const query_options_product_status = {
    callback_success: onSuccessQueryProductStatus,
    callback_error: onErrorQueryProductStatus,
  };
  getProductStatusList(query_options_product_status);

  const onCloseStatusChangeDialog = (evt) => {
    if (!evt || typeof evt == "string" || evt.currentTarget)
    {
      set_prd_stat(selectedValue.status_idx);
      onClose();
    }
    else
    {
      evt.status_idx = prd_stat;
      onClose(evt);
    }
  };

  const handleStatChange = (event) => {
    set_prd_stat(event.target.value);
  };

  useEffect(() => {
    set_prd_stat(selectedValue.status_idx);
  }, [selectedValue]);

  return (
    <Dialog onClose={onCloseStatusChangeDialog} open={open}>
      <DialogTitle sx={{ textAlign: "center", backgroundColor: "#24292e", color: "#fff" }}>
        제품 상태
      </DialogTitle>
      <Box noValidate component="form" sx={{ display: "flex", flexDirection: "column", m: "auto", p: 3, width: "fit-content" }}>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor="max-width">상태</InputLabel>
          <Select autoFocus value={obj_productstatus && obj_productstatus.length ? prd_stat : ""} onChange={handleStatChange} label="maxWidth">
            {obj_productstatus?.map((row, idx) => (
              <MenuItem key={idx} value={row.status_idx}>
                {row.status_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputLabel htmlFor="outlined-multiline-static" sx={{ mt: 2 }}>
          Note
        </InputLabel>
        <InputBase id="outlined-multiline-static" className="inputStyle" defaultValue="" multiline rows={4} sx={{ width: 250, background: "#fff" }} />
      </Box>
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={onCloseStatusChangeDialog.bind(this, "cancel")}>
          취소
        </Button>
        <Button onClick={onCloseStatusChangeDialog.bind(this, selectedValue)}>
          변경
        </Button>
      </DialogActions>
    </Dialog>
  );
};


// 상태 변경 팝업
const ProductMultiStatDialog = (props) => {
  const { onClose, selectedValue, open } = props;
  const [prd_stat, set_prd_stat] = useState(1);

  // Status
  const [obj_productstatus, setProductStatus] = useState();

  const onSuccessQueryProductStatus = (data) => {
    setProductStatus(data);
  };

  const onErrorQueryProductStatus = (error) => {
    console.log("error");
  };

  const query_options_product_status = {
    callback_success: onSuccessQueryProductStatus,
    callback_error: onErrorQueryProductStatus,
  };
  getProductStatusList(query_options_product_status);

  const onCloseStatusChangeDialog = (evt) => {
    if (!evt || typeof evt == "string" || evt.currentTarget)
    {
      onClose();
    }
    else
    {
      evt.status_idx = prd_stat;
      onClose(evt);
    }
  };

  const handleMultiStatChange = (event) => {
    set_prd_stat(event.target.value);
  };

  return (
    <Dialog onClose={onCloseStatusChangeDialog} open={open}>
      <DialogTitle sx={{ textAlign: "center", backgroundColor: "#24292e", color: "#fff" }}>
        일괄 상태 변경
      </DialogTitle>
      <Box noValidate component="form" sx={{ display: "flex", flexDirection: "column", m: "auto", p: 3, width: "fit-content" }}>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor="max-width">고객</InputLabel>
          <Select autoFocus value={obj_productstatus && obj_productstatus.length ? prd_stat : ""} onChange={handleMultiStatChange} sx={{ width: 250, background: "#fff" }} label="maxWidth">
            {obj_productstatus?.map((row, idx) => (
              <MenuItem key={idx} value={row.status_idx}>
                {row.status_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={onCloseStatusChangeDialog.bind(this, "cancel")}>
          취소
        </Button>
        <Button onClick={onCloseStatusChangeDialog.bind(this, selectedValue)}>
          변경
        </Button>
      </DialogActions>
    </Dialog>
  );
};


// 고객 변경 팝업
const ProductMultiCustomerDialog = (props) => {
  const { onClose, selectedValue, open } = props;
  const [prd_customer, set_prd_customer] = useState("default");

  const [list_customer, SetCustomerList] = useState([]);
  
  const [is_loaded_customer_list, LoadedCustomerList] = useState(false);
  // Status
  const [obj_productstatus, setProductStatus] = useState();

  const onSuccessQueryProductStatus = (data) => {
    setProductStatus(data);
  };

  const onErrorQueryProductStatus = (error) => {
    console.log("error");
  };

  const query_options_product_customer = {
    callback_success: onSuccessQueryProductStatus,
    callback_error: onErrorQueryProductStatus,
  };
  //getProductStatusList(query_options_product_customer);
  
  const onSuccessGetCustomerInfo = (data) => {
    let obj_customers = [];
    let obj_customer = {};
    let customer_company, customer_name;
    const use_key_customer_company = ['customer_company'];
    const use_key_customer_name = ['customer_name'];
    const use_key_customer_id = ['customer_id'];

    data.forEach((elem, idx, arr) => {
        obj_customer = {};
        for (const key in elem)
        {
            if (use_key_customer_company.indexOf(key) > -1)
            {
                customer_company = elem[key]
            }
            if (use_key_customer_name.indexOf(key) > -1)
            {
                customer_name = elem[key]
            }
            if (use_key_customer_id.indexOf(key) > -1)
            {
                obj_customer[key] = elem[key]
            }
            
            if (customer_company && customer_name)
            {
                obj_customer['customer'] = customer_company + "(" + customer_name + ")";
                customer_company = undefined;
                customer_name = undefined;
            }
        }
        obj_customers.push(obj_customer);
    });
    const set = new Set(obj_customers);
    const uniqueCustomers = [...set];

    SetCustomerList(uniqueCustomers);
    LoadedCustomerList(true);
    // console.log(list_customer)
  }
  const onErrorGetCustomerInfo = (data) => { }
  useEffect(() => {
    if (is_loaded_customer_list === false)
    {
      let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetCustomerInfo, onErrorGetCustomerInfo);
      axiosGetCustomerInfo(axiosDescriptor);
    }
  },[is_loaded_customer_list])

  const onCloseStatusChangeDialog = (evt) => {
    if (!evt || typeof evt == "string" || evt.currentTarget)
    {
      onClose();
    }
    else
    {
      evt.customer_id = prd_customer;
      onClose(evt);
    }
  };

  const handleMultiStatChange = (event) => {
    set_prd_customer(event.target.value);
  };

  return (
    <Dialog onClose={onCloseStatusChangeDialog} open={open}>
      <DialogTitle sx={{ textAlign: "center", backgroundColor: "#24292e", color: "#fff" }}>
        고객 일괄 변경
      </DialogTitle>
      <Box noValidate component="form" sx={{ display: "flex", flexDirection: "column", m: "auto", p: 3, width: "fit-content" }}>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          <InputLabel htmlFor="max-width">고객</InputLabel>
          <Select autoFocus value={prd_customer} onChange={handleMultiStatChange} sx={{ width: 250, background: "#fff" }} label="maxWidth">
            <MenuItem value="default">
                <em>고객사를 선택해주세요</em>
            </MenuItem>
            {list_customer?.map((row, idx) => (
                <MenuItem key={idx} value={row.customer_id}>{row.customer}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={onCloseStatusChangeDialog.bind(this, "cancel")}>
          취소
        </Button>
        <Button onClick={onCloseStatusChangeDialog.bind(this, selectedValue)}>
          변경
        </Button>
      </DialogActions>
    </Dialog>
  );
};



// Sub Component
const ProductHardwareStatus = (props) => {
  let ret;
  switch (props.data.hw_status)
  {
    case "SUCCESS":
      //ret = <div className="status_icon status_success"><span className="status_name">HW</span></div>;
      ret = <CheckCircleRoundedIcon color="success" />;
      break;
    case "WARNING":
      //ret = <div className="status_icon status_warning"><span className="status_name">HW</span></div>;
      ret = <WarningRoundedIcon color="warning" />;
      break;
    case "ERROR":
      //ret = <div className="status_icon status_error"><span className="status_name">HW</span></div>;
      ret = <ErrorRoundedIcon color="error" />;
      break;
    default:
      ret = <ErrorRoundedIcon color="error" />;
      break;
  }

  return ret;
};
// Sub Component
const ProductSoftwareStatus = (props) => {
  let ret;
  switch (props.data.sw_status)
  {
    case "SUCCESS":
      //ret = <DeveloperModeRoundedIcon color="success" />;
      //ret = <div className="status_icon status_success"><span className="status_name">HW</span></div>;
      ret = <CheckCircleRoundedIcon color="success" />;
      break;
    case "WARNING":
      //ret = <div className="status_icon status_warning" name="WARNING"><span className="status_name">SW</span></div>;
      ret = <WarningRoundedIcon color="warning" />;
      break;
    case "DEVELOP":
      //ret = <div className="status_icon status_success"><span className="status_name">SW</span></div>;
      ret = <DeveloperModeRoundedIcon color="success" />;
      break;
    case "ERROR":
      //ret = <div className="status_icon status_error"><span className="status_name">SW</span></div>;
      ret = <ErrorRoundedIcon color="error" />;
      break;
    default:
      ret = <ErrorRoundedIcon color="error" />;
      break;
  }

  return ret;
};

const ProductAddButtons = (props) => {
  if (props.has_role === true)
  {
    return (
      <>
        <Button variant="contained" component={Link} to="/product/write" className="btn_create">
          제품 생성
        </Button>
        <DialogProductManagement callbackfn_onClose={props.callbackfn} />
      </>
    );
  }
  else
  {
    return <></>;
  }
};

ProductStatDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};
