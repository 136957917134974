import { useState } from 'react';

import { Fab, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Add, FileDownload } from '@mui/icons-material';

import { GetAxiosGetDescriptior, GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { axiosPostProductAddWithCSVFile, axiosPutProductInformationChangeWithCSVFile, fileDownloadProductTemplate } from 'hooks/useProduct';

import "./ProductManagementDialog.css";

const DialogProductManagement = (props) => {
    const REQUEST_FILE_NAME = "template_product_information.csv";

    const [dialog_open, setDialogOpen] = useState(false);

    const [file_name, setFileName] = useState("");
    const [file_data, setFileData] = useState(null);
    const [result, setResult] = useState("");

    // http response handler
    const onSuccessProductAdd = (data) => {
        setResult(data.reason);
    }
    const onErrorProductProductAdd = (data) => {
        setResult(data.reason);
    }
    const onSuccessProductInformationChange = (data) => {
        setResult(data.reason);
    }
    const onErrorProductInformationChange = (data) => {
        setResult(data.reason);
    }
    const onSuccessCSVDownload = (data) => {
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = REQUEST_FILE_NAME;
        link.click();
        URL.revokeObjectURL(url);
    }
    const onErrorProductCSVDownload = (data) => { }

    // ui event handler
    const onDialogOpen = () => {
        setDialogOpen(true);

        setFileName("");
        setFileData(null);
        setResult("");
    }
    const onDialogClose = (e) => {
        setDialogOpen(false);

        if (props.callbackfn_onClose)
            props.callbackfn_onClose();
    }
    const onFileChange = (e) => {
        let ext_seperate_idx = e.currentTarget.value.lastIndexOf('.');
        let selected_file_ext = e.currentTarget.value.substring(ext_seperate_idx);

        if (selected_file_ext === ".csv")
        {
            setFileName(e.currentTarget.value);
            setFileData(e.currentTarget.files[0]);
        }
        else
        {
            setFileName("not csv file");
            setFileData(null);
        }
        setResult("");
    }
    const onSubmit = (event_id) => {
        if (file_data == null)
        {
            setFileName("not selected file");
            setResult("");
            return;
        }

        const formData = new FormData();
        formData.append('file', file_data);

        let axiosDescriptor;

        switch (event_id)
        {
            case 1:
                axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductAdd, onErrorProductProductAdd);
                axiosDescriptor.data = formData;
                axiosPostProductAddWithCSVFile(axiosDescriptor);
                break;
            case 2:
                axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductInformationChange, onErrorProductInformationChange);
                axiosDescriptor.data = formData;
                axiosPutProductInformationChangeWithCSVFile(axiosDescriptor);
                break;
            default:
                break;
        }
    }

    const onTemplateFileDownload = (e) => {
        let axiosDescriptor = GetAxiosGetDescriptior(onSuccessCSVDownload, onErrorProductCSVDownload);
        axiosDescriptor.keys.file_name = REQUEST_FILE_NAME;

        fileDownloadProductTemplate(axiosDescriptor);
    }

    return (
        <>
            <Button variant="contained" onClick={onDialogOpen}>제품 관리( File )</Button>
            <Dialog className="product-add" open={dialog_open} onClose={onDialogClose}>
                <DialogTitle>제품 관리 ( CSV File )</DialogTitle>
                <DialogContent>
                    <Fab component="span" className="btn" color="inherit" size="small" variant="extended" onClick={onTemplateFileDownload}>
                        <FileDownload /> Download CSV Template File
                    </Fab>
                    <label htmlFor="upload-csv">
                        <input id="upload-csv" type="file" onChange={onFileChange} />
                        <Fab component="span" className="btn" color="secondary" size="small" variant="extended">
                            <Add /> ADD CSV File
                        </Fab>
                    </label>
                    <DialogContentText>Select : {file_name}</DialogContentText>
                    <DialogContentText>Result : {result}</DialogContentText>
                </DialogContent>
                <DialogActions className='product-add_bottom'>
                    <Button variant="contained" onClick={() => { onSubmit(1) }}>제품 추가</Button>
                    <Button variant="contained" onClick={() => { onSubmit(2) }}>제품 수정</Button>
                    <Button variant="outlined" onClick={onDialogClose}>닫기</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogProductManagement;