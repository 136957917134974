import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GetAxiosGetDescriptior, GetAxiosCommonDescriptior } from 'hooks/useAxios';
import axiosLog from 'hooks/useLog';
import { axiosPostProductIssued } from 'hooks/useProduct';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { MopicContext } from 'hooks/useContextManager';
import {
    Card, CardContent,
    Typography, Autocomplete, TextField,
    Grid, Box, Container, Radio, RadioGroup, FormControlLabel,
    FormControl, InputBase
  } from "@mui/material";
  import "react-datepicker/dist/react-datepicker.min.css";


const AppProductAPILog = () => {
  const navigate = useNavigate();
  const listpage_info = useContext(MopicContext).listpage_info;


  const [log_serial, SetLogSerial] = React.useState(listpage_info.getLogProductSearchKeySerialNumber() || "");
  const log_startdate = listpage_info.getLogProductSearchStartDate() || new Date().toISOString().split('T')[0];; //searchParams.get("log_startdate") || new Date().toISOString().split('T')[0];
  const log_enddate = listpage_info.getLogProductSearchEndDate() || log_startdate; //searchParams.get("log_enddate") || log_startdate;
  const log_msg = listpage_info.getLogProductSearchMessage() || ""; //searchParams.get("log_msg") || "";
  const log_page = listpage_info.getLogProductPageIndex() || 0; //searchParams.get("log_page");



  const [logdata, SetLogData] = useState([]);
  const [logkey, SetLogKey] = useState(listpage_info.getLogProductSearchKeySerialNumber() || null);
  const [loglimit, SetLogLimit] = React.useState(1);
  
  const [is_loaded_keylist, SetIsLoadedKeyList] = React.useState(false);
  const [serialList, SetSerialList] = React.useState([]);
  
  const [customerList, SetCustomerList] = React.useState([]);
  const [filterCustomer, setFilterCustomer] = useState('');

  const [page, setPage] = useState(log_page);
  const [rowcount, setRowCount] = useState(10);


  const [startDate, setStartDate] = useState(new Date(log_startdate));
  const [endDate, setEndDate] = useState(new Date(log_enddate));
  const [radio_date, setRadioDate] = useState(0);


  const filterModel = {
    items: [
      { columnField: 'api', operatorValue: 'equals', value: filterCustomer },
      //{ columnField: 'model_name', operatorValue: 'contains', value: filterModelName }
    ],
  };

  
  function CustomNoRowsOverlay() {
    return (
      <Box sx={{ mt: 1 }}>조회된 로그 결과가 없습니다.</Box>
    );
  }
  
  const columns = [
    { field: 'id', headerName: 'ID', sortable: false , hide: true},
    { field: 'date', headerName: 'Date', width: 200},
    { field: 'serial', headerName: 'Serial Number', width: 200,
      renderCell: (params) => (
        <Link to="" className="logLinkSerial" onClick={(e)=> {
          console.log(params.value);
          let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductIssued, onErrorAxiosCommons);
          axiosDescriptor.data = {serialnumber: params.value};

          axiosPostProductIssued(axiosDescriptor);
          e.preventDefault();
          return false;
        }} sx={{ cursor: "pointer" }}>{params.value}</Link>
      ),
    },
    { field: 'api', headerName: 'API', type: 'string', width: 190 },
    { field: 'user', headerName: 'User', type: 'string', width: 220 },
    { field: 'log', headerName: 'Log', flex: 1, sortable: false , minWidth:576,
      renderCell: (params) => (
        <div title={params.value}>{params.value}</div>
      )
    }
  ];


  useEffect(() => {
    if (!is_loaded_keylist)
    {
      let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetKeyList, onErrorAxiosCommons);
      axiosLog.axiosGetCommAPILogKeyList(axiosDescriptor);
       SetIsLoadedKeyList(true);
       SetLogLimit(1);
    }
    getLogData(log_serial, log_msg, startDate, endDate);
  }, [log_serial, startDate, endDate, log_msg, is_loaded_keylist]);

  
  //const [searchSerialNumber, setSearchSerialNumber] = useState(listpage_info.getProductSearchKeyModelSerialNumber() || null);


  //listpage_info.setProductSearchKeyModelSerialNumber(value);

  // page
  useEffect(() => {
    setPage(listpage_info.getLogProductPageIndex());
    setRowCount(listpage_info.getLogProductPageRowCount());
  }, [listpage_info, page, rowcount]);


  
  const onChangeFindValueStartDate = (date) => {
    let date_obj = new Date(date);
    setStartDate(date_obj);
    listpage_info.setLogProductSearchStartDate(date.toISOString().split('T')[0]);
    listpage_info.setLogProductSearchEndDate(endDate);
    changeDueBtn(date_obj, endDate);
  };
  const onChangeFindValueEndDate = (date) => {
    let date_obj = new Date(date);
    setEndDate(date_obj);
    //setRadioDate(0);
    changeDueBtn(startDate, date_obj);

    listpage_info.setLogProductSearchStartDate(startDate);
    listpage_info.setLogProductSearchEndDate(date.toISOString().split('T')[0]);
  };
  function changeDueBtn(date1, date2) {
    const day = daysBetweenDates(date1, date2)+1;
    if (day === 1 || day === 7 || day === 30) {
      setRadioDate(day);
    }
    else {
      setRadioDate(0);
    }
  }

  const onSuccessProductIssued = (data) => {
    navigate('/product/view', { state: { data: data.product_id } });
    
    console.log('onSuccessProductIssued', data.product_id);
  };
  const onSuccessGetKeyList = (data) => {
    let _serialList = [];
    _serialList.push({label: "전체", value: ""});
    for (var i=0; i<data.length; i++) {
        _serialList.push({label: data[i].serialnumber, value: data[i].serialnumber});
    }
    SetSerialList(_serialList);
  }
  
  function daysBetweenDates(date1, date2) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
  
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24) +1;
  
    return Math.abs(differenceInDays); 
  }
  function datePlusDays(date1, days) {
    const date = new Date(date1);
    date.setDate(date.getDate() + days);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  function dateMinusDays(date1, days) {
    const date = new Date(date1);
    date.setDate(date.getDate() - days);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  function calculateTimezoneDifference() {
    return new Date().getTimezoneOffset() / 60;
  }


  function convertToUTCString(dateString) {
    console.log(dateString);
    const date = new Date(dateString);
    const utc = date.getTime() + calculateTimezoneDifference() * 60 * 60 * 1000;
    return new Date(utc).toISOString().substring(0,16).replace("T"," ");
  }

  const getLogData = (key, msg, startdate, enddate) => {
    let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetLogData, onErrorAxiosCommons);
      axiosDescriptor.keys.serialnumber = key;
      axiosDescriptor.keys.message = msg;
      axiosDescriptor.keys.startdate = convertToUTCString(startdate);
      axiosDescriptor.keys.enddate = convertToUTCString(datePlusDays(enddate,1));

      axiosLog.axiosGetCommAPILogData(axiosDescriptor);
  }
  const onSuccessGetLogData = (data) => {
    if (data.length)
    {
      let tempData = [];
      let _customerList = [];
      _customerList.push({label: "전체", value: ""});
        for (var i=0; i<data.length; i++) {
            tempData.push({ 
                id : i,
                date: new Date(data[i].log_time).toLocaleString(),
                serial: data[i].serialnumber, 
                //type: data[i].log_level, 
                api: data[i].api_name,
                user: data[i].log_user,
                log: data[i].log_message 
            });
            if (!(_customerList.findIndex((item) => item.value === data[i].api_name) !== -1)) {
              _customerList.push({label: data[i].api_name, value: data[i].api_name});
            }
        }
        SetLogData(tempData);
        SetCustomerList(_customerList);
    }
    else
    {
      SetLogData([]);
    }
  }
  const onErrorAxiosCommons = (data) => {
    //console.log(data);
  }


  const ShowLogDataComp = () => {
    return (<>
    <Box sx={{  width: '100%' }} className='logContWrap' mt={2}>
        <DataGrid
          className='logCont'
            rows={logdata}
            columns={columns}
            autoHeight 
            getRowHeight={() => 'auto'}
            disablePortal
            disableRowSelectionOnClick
            componentsProps={{
                noRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay : CustomNoRowsOverlay
            }}
            sx={{
                [`& .${gridClasses.cell}`]: {
                py: 1,
                },
            }}
            
          filterModel={filterModel}
        />
      </Box>
    </>);
  }

  const account_info = useContext(MopicContext).account_info;
  if (account_info.hasRole("PRODUCTAPI_READ") === false) {
    return null;
  }
  return (
    <div>
      <Container sx={{ m: 0, p:0 }} className="product_list_wrap">
        <Container sx={{ display: "flex", justifyContent: "space-between", alignItem: "right", flexWrap: "wrap", m: 0, }}>
          <Typography sx={{ m: 0, fontWeight:700 }} variant="h5" className="page_title">
            Product API Log
          </Typography>
        </Container>
        {/* Search Container */}
        <Container sx={{ mx: 0, mt: 2, mb: 5, display: "flex", flexWrap: "wrap" }} className="searchWrap">
          <Card sx={{ width: "100%", p: 2 }}>
            <CardContent>
              <Grid container spacing={2} sx={{ fontSize: "14px", itemAlign: "center" }}>
                
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    License
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent" >
                    <Autocomplete
                    disablePortal
                        id="combo-box-demo"
                        options={serialList}
                        // getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        sx={{ maxWidth:"500px !important" }}
                        className="inputStyle"
                        renderInput={(params) => <TextField {...params} label="License" />}
                        value={logkey}
                        onChange={(event, newValue) => {
                            if (newValue) {
                              
                                getLogData(newValue.value,log_msg, startDate, endDate);
                                //getLogData(loglimit, newValue.value);
                                SetLogKey(newValue.value);
                                listpage_info.setLogProductSearchKeySerialNumber(newValue.value);
                            }
                            console.log(newValue);
                            
                        }}
                    />
                </Grid>
                
                <Grid item xs={4} md={2} sx={{}} className="searchTitle range_title">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    기간
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="log_range searchContent" >
                  <div className="range_wrap">

                    <Grid container spacing={0} sx={{maxWidth:"350px !important"}}>
                      <Grid item xs={5}
                        sx={{
                          "& .MuiInputBase-root": { width: "100%", textAlign: "center" },
                          "& .MuiInputBase-root input": { textAlign: "center", color: "rgba(0,0,0,0.9)" }
                        }}
                      >
                        <DatePicker className="inputStyle" customInput={<InputBase />} dateFormat={"yyyy-MM-dd"} selected={startDate} selectsStart startDate={startDate} endDate={endDate} maxDate={endDate} onChange={onChangeFindValueStartDate} />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center", px: 1, lineHeight: "42px", fontSize: "14px" }}>
                        <span> ~ </span>
                      </Grid>
                      <Grid item xs={5} 
                        sx={{
                          "& .MuiInputBase-root": { width: "100%" },
                          "& .MuiInputBase-root input": { textAlign: "center" }
                        }}
                      >
                        <DatePicker
                          fullWidth
                          selected={endDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={onChangeFindValueEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          customInput={<InputBase />}
                          className="inputStyle"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="range_radio_wrap">
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radio_date}
                            onChange={(event, value)=> {
                              if(value>0) {
                                const ed = dateMinusDays(endDate, value-1);
                                setStartDate(new Date(ed));
                                console.log(ed);
                                ///setSearchParams({ log_serial, log_page : 0, log_msg, log_startdate, log_enddate : ed});
                                //listpage_info.setLogMopiclibPageIndex(0);
                                listpage_info.setLogProductSearchStartDate(ed);
                              }
                              setRadioDate(value);
                            }}
                        >
                          <FormControlLabel value="0" control={<Radio />} label="기타" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px'}} />
                          <FormControlLabel value="1" control={<Radio />} label="1일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px' }} />
                          <FormControlLabel value="7" control={<Radio />} label="7일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px' }} />
                          <FormControlLabel value="30" control={<Radio />} label="30일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px'}} />
                        </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                
                <Grid item xs={4} md={2} sx={{}} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    API
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    sx={{ maxWidth:"350px !important" }}
                    className="inputStyle"
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="API" />}
                    onChange={(event, newValue) => {
                        if (newValue) {
                          setFilterCustomer(newValue.value);
                        }
                        else {
                          setFilterCustomer("")
                        }
                        
                    }}
                  />
                </Grid>
                
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    Message
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent">
                  <InputBase name="log_message" sx={{maxWidth:"80%"}} className="inputStyle" fullWidth 
                    defaultValue={log_msg}
                    onChange={(event)=> {
                      getLogData(log_serial ,event.target.value, startDate, endDate);
                      ///setSearchParams({ log_serial, log_page : 0, log_msg : event.target.value, log_startdate, log_enddate });
                      listpage_info.setLogProductPageIndex(0);
                      listpage_info.setLogProductSearchMessage(event.target.value);
                    }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <ShowLogDataComp />

        </Container>
    </Container>
      
    </div>
  );  
}

export default AppProductAPILog;
