import { axiosGet, axiosPost, axiosPut } from './useAxios';

import { URL_API_LIST, URI_QUERY_LIST } from './useMopicAPIConfig'

const axiosLog = {
    axiosGetMopicLibLogKeyList : async(descriptor) => {
        if (!descriptor) {
            return;
        }

        let req_url = URL_API_LIST['API_MOPICLIB_LOGKEY'];
        await axiosGet(req_url, descriptor);
    },
    axiosGetCommAPILogKeyList : async(descriptor) => {
        if (!descriptor) {
            return;
        }

        let req_url = URL_API_LIST['API_COMMAPI_LOGKEY'];

        await axiosGet(req_url, descriptor);
    },
    axiosGetMopicLibLogData : async(descriptor) => {
        if (!descriptor) {
            return;
        }

        let req_url = URL_API_LIST['API_MOPICLIB_LOGDATA'];

        let str_query = "";
        if (descriptor.keys.serialnumber) {
            str_query += URI_QUERY_LIST['SEARCH_QUERY_SERIALNUMBER'] + "=" + encodeURIComponent(descriptor.keys.serialnumber);
        }
        if (descriptor.keys.limit) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGLIMIT'] + "=" + encodeURIComponent(descriptor.keys.limit);
        }
        if (descriptor.keys.message) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGMESSAGE'] + "=" + encodeURIComponent(descriptor.keys.message);
        }
        if (descriptor.keys.startdate) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGSTARTDATE'] + "=" + encodeURIComponent(descriptor.keys.startdate);
        }
        if (descriptor.keys.enddate) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGENDDATE'] + "=" + encodeURIComponent(descriptor.keys.enddate);
        }
        

        if (str_query) {
            req_url += "?" + str_query;
        }

        await axiosGet(req_url, descriptor);
    },
    axiosGetCommAPILogData : async(descriptor) => {
        if (!descriptor) {
            return;
        }

        let req_url = URL_API_LIST['API_COMMAPI_LOGDATA'];

        let str_query = "";
        if (descriptor.keys.serialnumber) {
            str_query += URI_QUERY_LIST['SEARCH_QUERY_SERIALNUMBER'] + "=" + encodeURIComponent(descriptor.keys.serialnumber);
        }
        if (descriptor.keys.limit) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGLIMIT'] + "=" + encodeURIComponent(descriptor.keys.limit);
        }
        if (descriptor.keys.message) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGMESSAGE'] + "=" + encodeURIComponent(descriptor.keys.message);
        }
        if (descriptor.keys.startdate) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGSTARTDATE'] + "=" + encodeURIComponent(descriptor.keys.startdate);
        }
        if (descriptor.keys.enddate) {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_LOGENDDATE'] + "=" + encodeURIComponent(descriptor.keys.enddate);
        }

        if (str_query) {
            req_url += "?" + str_query;
        }

        await axiosGet(req_url, descriptor);
    }
}

export default axiosLog;
