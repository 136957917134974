/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link, Typography } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { logoutRequest } from "../../components/auth/config";


function onMsalLogout(instance, type) {
  if (type === "redirect") {
    instance.logoutRedirect(logoutRequest)
      .catch(e => {
        console.error(e);
      });
  }
  else if (type === "popup") {
    instance.logoutPopup(logoutRequest)
      .catch(e => {
        console.error(e);
      });
  }
}


function Header(props) {
  const { onDrawerToggle } = props;
  const { instance } = useMsal();
  const user = window.sessionStorage.getItem('user');
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">

            <Grid sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs >
            </Grid>
            <Grid item>
              <Typography variant="button" component="div">반갑습니다. {user}님
                <Link variant="text" onClick={() => onMsalLogout(instance, 'redirect')} sx={{ color: '#fff', ml: 2, fontSize: '12px' }}>[로그아웃]</Link>

              </Typography>

            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;