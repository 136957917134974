import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "../auth/config"
import { callMsGraph } from "../auth/graph";

import './grid.css';

  const Grid = (props) => {

    const [isFetched, setIsFetched] = useState(0);
    
    const [rows, setRows] = useState([]);

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [graphData, setGraphData] = useState(null);

    const name = accounts[0] && accounts[0].name;

    const RequestAccessToken = () => {
      const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });
    }
    const RequestProfileData = () => {
      const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
          callMsGraph(response.accessToken).then(response => setGraphData(response));
      }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then(response => setGraphData(response));
          });
      });
    }

    useEffect(() => {
      //console.log(accessToken)
      //console.log(graphData)
      if (!accessToken)
      {
        RequestAccessToken();
      }

      if (!graphData)
      {
        RequestProfileData();
      }

      if (!isFetched && accessToken)
      {
          fetch(props.data_url)
            .then(response => response.json())
            .then(response => {
                updateRowDataset(response);
                setIsFetched(1);
            })
      }
    });
    
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'log_user_id',
          headerName: 'user_id',
          width: 150,
          editable: true,
        },
        {
          field: 'log_state',
          headerName: 'log_state',
          width: 150,
          editable: true,
        },
        {
          field: 'log_date',
          headerName: 'log_state',
          type: 'number',
          width: 110,
          editable: true,
        }
      ];

      // 샘플용 함수. 쓸일없을듯..
    const addRowData = (data) => {
        let row = { id: rows.length + 1, log_user_id: 'Snow', log_state: 'End', log_date: "1234-11-11" };

        setRows([...rows, row]);
    }

    // row 일괄갱신
    const updateRowDataset = (dataset) => {
        if (dataset.length)
        {
            let row_data = []
            dataset.forEach(element => {
                let row = {
                    id : element.idx,
                    log_user_id: element.log_user_id,
                    log_state: element.log_state,
                    log_date: element.log_date
                }
                row_data.push(row);

            });

            setRows(row_data);
        }
    }
    return (
        <div className={props.className}>
            <div><button onClick={addRowData}>데이터추가</button></div>
            <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} disableSelectionOnClick />
        </div>
    );
}

export default Grid;