/* eslint-disable no-unused-vars */

// react base
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// react ui
import DatePicker from 'react-datepicker';

// mui
import { Stack, Button, Container, Grid, FormControl, InputBase, MenuItem, Select, Typography, Alert } from '@mui/material';

// etc ui
import 'react-datepicker/dist/react-datepicker.min.css'

// custome hook
import { addProduct, QUERYKEY_PRODUCT, getEyeTrackingTypeList, getValidTimeList } from 'hooks/useProduct';
import { GetAxiosGetDescriptior, GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { axiosGetModeInfo } from 'hooks/useModel';
import { axiosGetCustomerInfo } from 'hooks/useCustomer';
import { axiosGetEyeTrackerTypeList, axiosGetEyeTrackerLicenseTypeList, axiosPostProductAdd, axiosPostProductSerialNumber } from 'hooks/useProduct';
import { MopicContext } from 'hooks/useContextManager';

// etc import
import _ from 'lodash';

const ProductWrite = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_WRITE");

    const navigate = useNavigate();

    // list selector loader
    const [is_loaded_model_list, LoadedModelList] = useState(false);
    const [is_loaded_customer_list, LoadedCustomerList] = useState(false);
    const [is_loaded_eyetracker_list, LoadedEyeTrackerList] = useState(false);
    const [is_loaded_eyetracker_license_type_list, LoadedEyeTrackerLicenseTypeList] = useState(false);

    const [list_model, SetModelList] = useState([]);
    const [list_customer, SetCustomerList] = useState([]);
    const [list_eyetracker, SetEyeTrackerList] = useState([]);
    const [list_eyetracker_license_type_origin, SetEyeTrackerLicenseTypeOriginList] = useState([]);
    const [list_eyetracker_license_type, SetEyeTrackerLicenseTypeList] = useState([]);
    const [list_modelparam, SetModelParameter] = useState({});
    const [serial_check, SetSerialCheck] = useState(true);

    const onSuccessGetModelInfo = (data) => {
        let obj_models = [];
        let obj_model = {};
        let obj_modelparam = {};
        const use_key_model = ['model_name'];

        data.forEach(elem => {
            for (const key in elem.base)
            {
                if (use_key_model.indexOf(key) > -1)
                {
                    if (!elem.base['model_deprecated_date'])
                    {
                        obj_model = {};
                        obj_model[key] = elem.base[key];
                        obj_models.push(obj_model);
                    }
                }
            }
            obj_modelparam[elem.base.model_name] = {
                theta: elem.base.theta,
                spsize: elem.base.spsize,
            }
            SetModelParameter(obj_modelparam);
        });
        const uniqueModels = _.uniqBy(obj_models, "model_name");

        SetModelList(uniqueModels);
        LoadedModelList(true);
    }
    const onErrorGetModelInfo = (data) => { }

    const onSuccessGetCustomerInfo = (data) => {
        let obj_customers = [];
        let obj_customer = {};
        let customer_company, customer_name;
        const use_key_customer_company = ['customer_company'];
        const use_key_customer_name = ['customer_name'];
        const use_key_customer_id = ['customer_id'];

        data.forEach((elem, idx, arr) => {
            obj_customer = {};
            for (const key in elem)
            {
                if (use_key_customer_company.indexOf(key) > -1)
                {
                    customer_company = elem[key]
                }
                if (use_key_customer_name.indexOf(key) > -1)
                {
                    customer_name = elem[key]
                }
                if (use_key_customer_id.indexOf(key) > -1)
                {
                    obj_customer[key] = elem[key]
                }
                
                if (customer_company && customer_name)
                {
                    obj_customer['customer'] = customer_company + "(" + customer_name + ")";
                    customer_company = undefined;
                    customer_name = undefined;
                }
            }
            obj_customers.push(obj_customer);
        });
        const set = new Set(obj_customers);
        const uniqueCustomers = [...set];

        SetCustomerList(uniqueCustomers);
        LoadedCustomerList(true);
    }
    const onErrorGetCustomerInfo = (data) => { }

    const onSuccessGetEyetrackerList = (data) => {

        SetEyeTrackerList(data);
        LoadedEyeTrackerList(true);
    }
    const onErrorGetEyetrackerList = (data) => { }

    const onSuccessGetEyetrackerLicenseTypeList = (data) => {

        SetEyeTrackerLicenseTypeOriginList(data);
        LoadedEyeTrackerLicenseTypeList(true);
    }
    const onErrorGetEyetrackerLicenseTypeList = (data) => { }

    const onSuccessProductAdd = (data) => {
        navigate('/product/list');
    }
    const onSuccessProductCheckSN = (data) => {
        if(data && data.result === "ERROR" && data.reason === "NOT_REGISTED") {
            SetSerialCheck(true);
        }
        else {
            SetSerialCheck(false);
        }
    }
    
    const onErrorProductAdd = (data) => {
        if (data && data.result === "ERROR")
        {
            alert(data.reason);
        }
    }

    useEffect(() => {
        let axiosDescriptor;
        if (is_loaded_model_list === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetModelInfo, onErrorGetModelInfo);
            axiosGetModeInfo(axiosDescriptor);
        }
        if (is_loaded_customer_list === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetCustomerInfo, onErrorGetCustomerInfo);
            axiosGetCustomerInfo(axiosDescriptor);
        }
        if (is_loaded_eyetracker_list === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetEyetrackerList, onErrorGetEyetrackerList);
            axiosGetEyeTrackerTypeList(axiosDescriptor);
        }
        if (is_loaded_eyetracker_license_type_list === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetEyetrackerLicenseTypeList, onErrorGetEyetrackerLicenseTypeList);
            axiosGetEyeTrackerLicenseTypeList(axiosDescriptor);
        }
    }, [is_loaded_model_list, is_loaded_customer_list, is_loaded_eyetracker_list, is_loaded_eyetracker_license_type_list]);

    const [startDate, setStartDate] = useState(new Date());
    const [obj_product, setProduct] = useState({
        model_name: '',
        model_serialnumber: '',
        manufacture_date: startDate,
        product_slantedangle: '',
        product_offset: '',
        product_spsize: '',
        valid_time: '-1',
        eyetracker_idx: '0',
        eyetracker_license_type_idx: '0',
        customer_id: '',
        product_manager: '',
        product_note: '',
        changed_date: '',
        changed_user: ''
    });

    let { model_name, model_serialnumber, manufacture_date, product_slantedangle, product_offset, product_spsize, valid_time, eyetracker_idx, eyetracker_license_type_idx, customer_id, product_manager, product_note, changed_date, changed_user } = obj_product;

    // regist validator
    const is_valid_user = (has_role_write) ? true : false;
    const [is_valid_data, SetValidData] = useState(false);
    const [is_valid_parameter, SetValidParameter] = useState(false);

    useEffect(() => {
        let model_theta, input_sa;
        let model_spsize, input_spsize;

        let valid_product_info = (obj_product.model_name !== '' && obj_product.manufacture_date !== '' && obj_product.valid_time !== '');
        let valid_product_parameter = false;
        let valid_product_parameter_sa = false;
        let valid_product_parameter_spsize = false;

        if (obj_product.model_name !== '')
        {
            if (obj_product.product_slantedangle !== '')
            {
                if (isNaN(obj_product.product_slantedangle))
                {
                    valid_product_parameter_sa = false;
                }
                else
                {
                    model_theta = +list_modelparam[obj_product.model_name].theta;
                    input_sa = +obj_product.product_slantedangle;

                    valid_product_parameter_sa = (model_theta + 1 > input_sa) && (model_theta - 1 < input_sa);
                }
            }
            else
            {
                valid_product_parameter_sa = true;
            }

            if (obj_product.product_spsize !== '')
            {
                if (isNaN(obj_product.product_spsize))
                {
                    valid_product_parameter_spsize = false;
                }
                else
                {
                    model_spsize = +list_modelparam[obj_product.model_name].spsize;
                    input_spsize = +obj_product.product_spsize;
                    // console.log('Math.abs(input_spsize - model_spsize)', Math.abs(input_spsize - model_spsize), model_spsize);
                    if (Math.abs(input_spsize - model_spsize) > 0.001)
                    {
                        valid_product_parameter_spsize = false;
                    }
                    else
                    {
                        valid_product_parameter_spsize = true;
                    }
                }
            }
            else
            {
                valid_product_parameter_spsize = true;
            }

            valid_product_parameter = (valid_product_parameter_sa === true) && (valid_product_parameter_spsize === true);
        }

        if (valid_product_info)
        {
            SetValidData(true);
        }
        else
        {
            SetValidData(false);
        }
        
        if (valid_product_parameter)
        {
            SetValidParameter(true);
        }
        else
        {
            SetValidParameter(false);
        }

    }, [obj_product.model_name, obj_product.manufacture_date, obj_product.valid_time, obj_product.product_slantedangle, obj_product.product_spsize, list_modelparam])

    useEffect(() => {
        if (!list_eyetracker_license_type_origin.length)
            return;

        let type = +eyetracker_idx;
        if (isNaN(type))
            return;

        let show_list = [];
        switch (type)
        {
            case 0:
            case 1:
            case 2:
                list_eyetracker_license_type_origin.forEach((elem, idx) => {
                    if (elem.license_type_name === 'Default')
                    {
                        show_list.push(elem);
                    }
                })
                break;
            case 3:
                list_eyetracker_license_type_origin.forEach((elem, idx) => {
                    if (elem.license_type_name === 'Default')
                    {
                        show_list.push(elem);
                    }
                    if (elem.license_type_name.indexOf('Blink') > -1)
                    {
                        show_list.push(elem);
                    }
                })
                break;
            default:
                break;
        }
        SetEyeTrackerLicenseTypeList(show_list);
    }, [eyetracker_idx, list_eyetracker_license_type_origin])

    const UIValidData = () => {
        if (is_valid_user === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>제품등록 권한이 없습니다. 제품을 등록할수 있는 계정으로 등록해주십시오.</Alert>;
        }
        else if (serial_check === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>
                <div>모델 시리얼 번호가 이미 존재합니다. </div>
            </Alert>
        }
        else if (is_valid_data === false || is_valid_parameter === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>
                <div>등록에 필요한 값이 올바르지 않습니다.</div>
                {is_valid_data === false ? <div>필요한값 : {!obj_product.model_name ? "모델명" : ""} {!obj_product.manufacture_date ? "제작일" : ""} {(obj_product.valid_time === '') ? "유효기간" : ""}</div> : <></>}
                {is_valid_parameter === false ? <div>잘못된 파라미터 범위</div> : <></>}
            </Alert>
        }
        else
        {
            return <Alert severity="success" sx={{ width: 'fit-content' }}>제품등록이 가능합니다.</Alert>
        }
    }
    const registData = () => {
        if (is_valid_user === false || is_valid_data === false || is_valid_parameter === false || serial_check === false)
        {
            return;
        }

        obj_product.changed_user = account_info.getAccount();
        obj_product.changed_date = new Date();

        let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductAdd, onErrorProductAdd);
        axiosDescriptor.data = obj_product;

        axiosPostProductAdd(axiosDescriptor);
    };

    // UI Event handler
    const onChangeHandler = (e) => {
        const { value, name } = e.target;
        setProduct({ ...obj_product, [name]: value })
        if (e.target.name === 'model_serialnumber')
        {
            if ( e.target.value !== '') {
                let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductCheckSN, onSuccessProductCheckSN);
                axiosDescriptor.data = {model_serialnumber: e.target.value};
                axiosPostProductSerialNumber(axiosDescriptor);
            }
            else {
                SetSerialCheck(true);
            }
        }
    };

    const getMembers = (email) => {
        const name = account_info.members.filter(item => item.mail === email)[0].displayName;
        return name ? name + "(" + email + ")" : "";
    }

    return (
        <>
            <Container className="product-write">
                <Typography sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '100%' }} variant="h6">
                    제품 등록
                </Typography>

                <Grid container>
                    <Grid item xs={12} sm={3} xl={2} className="label">Model *</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <Select name="model_name" className="inputStyle" value={model_name} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            <MenuItem value="">
                                <em>모델을 선택해주세요</em>
                            </MenuItem>
                            {list_model?.map((row, idx) => (
                                <MenuItem key={idx} value={row.model_name}>{row.model_name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">Model S/N</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="model_serialnumber"  className={serial_check ? "inputStyle" : "inputStyle check_error"}  value={model_serialnumber} onChange={onChangeHandler} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} xl={2} className="label">Slanted Angle</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_slantedangle" className="inputStyle" value={product_slantedangle} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">Offset</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_offset" className="inputStyle" value={product_offset} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">Spsize</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_spsize" className="inputStyle" value={product_spsize} onChange={onChangeHandler} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} xl={2} className="label">제작일자 *</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <DatePicker className="inputStyle" dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => { obj_product.manufacture_date = date; setStartDate(date); }} customInput={<InputBase />} />
                    </Grid>

                    <Grid item xs={12} sm={3} xl={2} className="label">유효기간 *</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <Select name="valid_time" className="inputStyle" value={valid_time} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            <MenuItem value="">
                                <em>기간을 선택해주세요</em>
                            </MenuItem>
                            {getValidTimeList().map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} xl={2} className="label">Eyetracker</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <Select name="eyetracker_idx" className="inputStyle" value={list_eyetracker.length ? eyetracker_idx : ""} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            {list_eyetracker?.map((row, idx) => (
                                <MenuItem key={idx} value={row.eyetracker_idx}>{row.eyetracker_name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">Eyetracker Policy</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <Select name="eyetracker_license_type_idx" className="inputStyle" value={list_eyetracker_license_type.length ? eyetracker_license_type_idx : ""} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            {list_eyetracker_license_type?.map((row, idx) => (
                                <MenuItem key={idx} value={row.license_type_idx}>{row.license_type_name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} xl={2} className="label">고객사</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        <Select name='customer_id' className="inputStyle" value={customer_id} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            <MenuItem value="">
                                <em>고객사를 선택해주세요</em>
                            </MenuItem>
                            {list_customer?.map((row, idx) => (
                                <MenuItem key={idx} value={row.customer_id}>{row.customer}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">영업담당자</Grid>
                    <Grid item xs={12} sm={9} xl={4} className="inputBase-item">
                        {/* <InputBase name='product_manager' className="inputStyle" value={product_manager} onChange={onChangeHandler} /> */}
                        <Select name='product_manager' className="inputStyle" value={product_manager} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            <MenuItem value="">
                                <em>담당자를 선택해주세요</em>
                            </MenuItem>
                            {account_info.members?.map((row, idx) => (
                                <MenuItem key={idx} value={getMembers(row.mail)}>{getMembers(row.mail)}</MenuItem>
                            ))}
                        </Select>
                            
                    </Grid>
                    <Grid item xs={12} sm={3} xl={2} className="label">Note</Grid>
                    <Grid item xs={12} sm={9} xl={10} className="inputBase-item">
                        <InputBase
                            id="outlined-multiline-static" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', background: '#fff' }}
                            name="product_note"
                            value={product_note}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 2 }} className='alertWrap'>
                <Stack direction="row" spacing={0} sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {UIValidData()}
                    </div>

                    <div style={{ width: '100%', paddingTop: 15, display: 'flex', justifyContent: 'center' }}>
                        <Button component={Link} variant="contained" sx={{ minWidth: 100 }} to="" onClick={registData}>등록</Button>
                    </div>
                </Stack>
            </Container>
        </>
    );
};
export default ProductWrite;

