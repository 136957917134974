import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { GetAxiosGetDescriptior, GetAxiosCommonDescriptior } from 'hooks/useAxios';
import useEyetracker from 'hooks/useEyetracker';

import { MopicContext } from 'hooks/useContextManager';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function EyetrackerManager() {
    // Properties
    const [is_loaded_issuedinfo, LoadedIssuedEyetrackerInfo] = React.useState(false);
    const [is_loaded_usedinfo, LoadedUsedEyetrackerInfo] = React.useState(false);
    const [is_loaded_unusedinfo, LoadedUnusedEyetrackerInfo] = React.useState(false);

    const [index, setIndex] = React.useState(0);
    const [issued_data, setIssuedData] = React.useState([]);
    const [used_data, setUsedData] = React.useState([]);
    const [unused_data, setUnusedData] = React.useState([]);

    
    const account_info = React.useContext(MopicContext).account_info;
    const has_role_all = account_info.hasRole("EYETRACKER_ALL");

    // Utilities
    const createIssuedTableData = (index, eyetracker_user, eyetracker_key, model_name, product_id, product_serialnumber) => {
        return { index, eyetracker_user, eyetracker_key, model_name, product_id, product_serialnumber };
    }
    const createUsedTableData = (index, id, eyetracker_user, eyetracker_key, expire_date) => {
        return { index, id, eyetracker_user, eyetracker_key, expire_date };
    }
    const mobileKey = [
        //"EFGND-XMQOY-DNRYY-GSROM",
        "KWQSF-ZNGHC-WKCEO-ATLVL",
        "CFQSR-GXBTZ-ROZPZ-IPUXH",
        "JGZES-AMTKM-RDAQK-BAUCT",
        "JCEFF-SJVUR-VNRZV-ZLJJB",
        "LXCLU-UKMUN-RNLUF-QSQZM",
        "IQGWF-GDGSZ-TZCJM-MZSAD",
        "ISNGU-TYEQO-UIGKW-ILPQM",
        "HWMVJ-FWVHK-YOIFF-YCUYE",
        "FZFDA-DSDQQ-HNRUA-IUYIH",
        "KMFTQ-PYYWI-ABQKI-KEIWN",
        "KDYLU-BGZXS-RGLBD-XDPBE",
        "DTIUF-EOFVJ-BTOCB-PTRWV",
        "DECHM-XHNPZ-VTSBR-JHVLE"
    ]
    
    const getEyetrackerData = () => {
        if (!has_role_all)
            return;

        switch (index)
        {
            case 0:
                if (!is_loaded_issuedinfo)
                {
                    GetIssuedBlinkKeyData();
                }
                break;
            case 1:
                if (!is_loaded_usedinfo)
                {
                    GetUsedBlinkKeyData();
                }
                break;
            case 2:
                if (!is_loaded_unusedinfo)
                {
                GetUnusedBlinkKeyData();
                }
                break;
            default:
                break;
        }
    }


    
    
    const GetUnusedBlinkKeyData = () => {
        let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetUnusedEyetrackerInfo, onErrorGetData);
        useEyetracker.axiosGetUnusedEyeTrackerKeyInformation(axiosDescriptor);
    }
    const GetIssuedBlinkKeyData = () => {
        let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetIssuedEyetrackerInfo, onErrorGetData);
        useEyetracker.axiosGetIssuedEyeTrackerKeyInformation(axiosDescriptor);
    }
    const GetUsedBlinkKeyData = () => {
        let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetUsedEyetrackerInfo, onErrorGetData);
        useEyetracker.axiosGetUsedEyeTrackerKeyInformation(axiosDescriptor);
    }
    const SetBlinkKeyUnIssue = (eyetracker_id) => {
        let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessBlinkKeyUnIssue, onErrorGetData);
        axiosDescriptor.data = {
            blink_id: eyetracker_id,
            changed_user: account_info.getAccount()
        };
        useEyetracker.axiosPostUnIssueEyeTrackerKey(axiosDescriptor);
    }

    // EventHandler
    const onTabButtonChange = (event, tabbutton_index) => {
        setIndex(tabbutton_index);
    };
    const onUnIssuedButtonClick = (eyetracker_id) => {
        SetBlinkKeyUnIssue(eyetracker_id);
    }

    const onSuccessGetIssuedEyetrackerInfo = (data) => {
        LoadedIssuedEyetrackerInfo(true);

        let assign_data = [];
        for (let idx in data)
        {
            let info = data[idx];
            assign_data[idx] = createIssuedTableData(idx, info.blink_user, info.blink_key, info.model_name, info.product_id, info.serialnumber);
        }

        setIssuedData(assign_data);
    }
    const onSuccessGetUsedEyetrackerInfo = (data) => {
        LoadedUsedEyetrackerInfo(true);

        let assign_data = [];
        for (let idx in data)
        {
            let info = data[idx];
            assign_data[idx] = createUsedTableData(idx, info.blink_id, info.blink_user, info.blink_key, info.blink_expiredate);
        }

        setUsedData(assign_data);
    }
    
    
    const onSuccessAddBlinkKeyCSV = () => {
        // console.log('onSuccessAddBlinkKeyCSV');
        alert('라이선스가 추가되었습니다.');
        LoadedUnusedEyetrackerInfo(false);
        getEyetrackerData();

    }
    const onSuccessBlinkKeyUnIssue = () => {
        LoadedUsedEyetrackerInfo(false);
        getEyetrackerData();
    }
    const onSuccessGetUnusedEyetrackerInfo = (data) => {
        LoadedUnusedEyetrackerInfo(true);

        let assign_data = [];
        for (let idx in data)
        {
            let info = data[idx];
            assign_data[idx] = createUsedTableData(idx, info.blink_id, info.blink_user, info.blink_key);
        }

        setUnusedData(assign_data);
    }
    

    const onErrorGetData = (data) => {
        console.log("onErrorGetData");
    }

    // React Hooks
    React.useEffect(() => {
        getEyetrackerData();
    });

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    const handleCSVFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const csvData = event.target.result;
              //console.log(csvData.split('\n'));

              let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessAddBlinkKeyCSV, onErrorGetData);
                axiosDescriptor.data = {
                    csv_file: csvData
                };
                useEyetracker.axiosPostAddEyeTrackerKeyCSV(axiosDescriptor);
            };
            reader.readAsText(file);

            
            
        }
        
        
      }
    

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {/* TabButton */}
                <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                    <Tabs value={index} onChange={onTabButtonChange}>
                        <Tab label="Issued Key Data" />
                        <Tab label="Used Key Data" />
                        <Tab label="Unused Key Data" />
                    </Tabs>
                </Box>
                {/* TabPanel1 ( Issued Eyetracker Data Table ) */}
                <TabPanel value={index} index={0}>
                    <TableContainer component={Box}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Eyetracker User</StyledTableCell>
                                    <StyledTableCell sx={{ minWidth: 270 }}>Eyetracker Key</StyledTableCell>
                                    <StyledTableCell sx={{ minWidth: 115 }}>Model Name</StyledTableCell>
                                    <StyledTableCell sx={{ minWidth: 220 }}>SerialNumber</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {issued_data.map((row) => (
                                    <StyledTableRow key={row.index}>
                                        <StyledTableCell>{row.eyetracker_user}</StyledTableCell>
                                        <StyledTableCell>{row.eyetracker_key}</StyledTableCell>
                                        <StyledTableCell>{row.model_name}</StyledTableCell>
                                        <StyledTableCell>
                                            <Link to="/product/view" sx={{ cursor: "pointer" }} state={{ data: row.product_id }}>{row.product_serialnumber}</Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                {/* TabPanel2 ( Used Eyetracker Key Table ) */}
                <TabPanel value={index} index={1}>
                    <p>* 키 리셋 시 모바일(안드로이드) 배정된 키 리셋하지 않도록 확인</p>
                    <TableContainer component={Box}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Eyetracker User</StyledTableCell>
                                    <StyledTableCell>Eyetracker Key</StyledTableCell>
                                    <StyledTableCell>Expire Date</StyledTableCell>
                                    <StyledTableCell>UnIssued</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {used_data.map((row) => (
                                    <StyledTableRow key={row.index} >
                                        {mobileKey.includes(row.eyetracker_key) ? 
                                        <StyledTableCell className='alert'>{row.eyetracker_user} (Mobile Key)</StyledTableCell>
                                         :
                                        <StyledTableCell>{row.eyetracker_user}</StyledTableCell>
                                        }   
                                        {mobileKey.includes(row.eyetracker_key) ? <StyledTableCell className='alert'>{row.eyetracker_key}</StyledTableCell> : <StyledTableCell>{row.eyetracker_key}</StyledTableCell>}
                                        
                                        <StyledTableCell>{row.expire_date ? row.expire_date : '-'}</StyledTableCell>
                                        
                                        <StyledTableCell>
                                        {mobileKey.includes(row.eyetracker_key) ? null :
                                            <IconButton onClick={() => { onUnIssuedButtonClick(row.id); }}>
                                                <LockOpenIcon />
                                            </IconButton>
                                        }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                
                {/* TabPanel2 ( Unused Eyetracker Key Table ) */}
                <TabPanel value={index} index={2}>
                    
                    <Box sx={{ mb:2, textAlign:'right'}}>
                        <Button component="a" variant="contained" startIcon={<CloudDownloadIcon />} href={'/resources/sample/test.csv'} sx={{mr:1}} >
                            Sample CSV
                            
                        </Button>
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Upload CSV
                            <VisuallyHiddenInput type="file" accept=".csv"  onChange={handleCSVFileUpload} />
                        </Button>
                    </Box>
                    <TableContainer component={Box}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Eyetracker User</StyledTableCell>
                                    <StyledTableCell>Eyetracker Key</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {unused_data.map((row) => (
                                    <StyledTableRow key={row.index}>
                                        <StyledTableCell>{row.eyetracker_user}</StyledTableCell>
                                        <StyledTableCell>{row.eyetracker_key}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <StyledTableCell colSpan={2} sx={{textAlign: 'right'}}>미사용 키 : {unused_data.length}개</StyledTableCell>
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    
                </TabPanel>

            </Box>
        </>
    );
}
