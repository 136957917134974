
// Base
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
// mui
import { Card, CardContent, Typography } from '@mui/material';
import { Box, Container, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// hook
import { DateFormat } from 'utils/Dateformat';

import { useQueryClient } from 'react-query';
import { MopicContext } from 'hooks/useContextManager';
import { GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { deleteCustomer, QUERYKEY_CUSTOMER } from 'hooks/useCustomer';

function createData(title, value) {
    return { title, value };
};

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    '&:first-of-type': {
        fontWeight: '700'
    }
}));

const CustomerView = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_WRITE");
    const has_role_update = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_UPDATE");

    const [obj_customer, setCustomer] = useState({
        customer_id: '',
        customer_company: '',
        customer_name: '',
        customer_department: '',
        customer_rank: '',
        customer_phone: '',
        customer_mail: '',
        customer_address: '',
        customer_note: '',
        mopic_manager: '',
        changed_date: DateFormat(new Date()),
        changed_user: ''
    });

    const [checkstate, setCheckState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                setCustomer({
                    ...data
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_customer]);

    const { customer_company, customer_name, customer_department, customer_rank, customer_phone, customer_mail, customer_address, customer_note, mopic_manager } = obj_customer;

    const basicInfo = [
        createData('회사명', customer_company),
        createData('담당자', customer_name),
        createData('부서', customer_department),
        createData('직급', customer_rank),
        createData('연락처', customer_phone),
        createData('이메일', customer_mail),
        createData('회사 주소', customer_address),
        createData('모픽 담당자', mopic_manager),
        createData('노트', customer_note),
    ];

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // update customer info
    const onSuccessMutateCustomer = (data, variables, context) => {
        // console.log('Success Update Customer');
        queryClient.invalidateQueries(QUERYKEY_CUSTOMER['deleteCustomer']);
        alert("수정되었습니다");
        navigate('/customer/list');
    };

    const onErrorMutateCustomer = () => {
        console.log('Error Delete Customer');
    };
    
    const mutate_options_customer = {
        'body': null,
        'callback_success': onSuccessMutateCustomer,
        'callback_error': onErrorMutateCustomer
    };
    const result_mutate_customer = deleteCustomer(mutate_options_customer);

    const onErrorDelete = (e) => {
        console.log('Error Delete');
    };

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold' }}
                    variant="h6"
                >
                    고객 정보
                </Typography>

                <Card {...props}>
                    <CardContent>
                        <Container sx={{ m: 0, p: 0, display: 'flex', flexWrap: 'wrap' }}>
                            <TableContainer component={Box} sx={{}}>
                                <Table sx={{}} aria-label="Spec">
                                    <TableBody>
                                        {basicInfo.map((row, idx) => (
                                            <TableRow
                                                key={idx}
                                                hover={true}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="col" align="left" width="200px">{row.title}</StyledTableCell>
                                                <StyledTableCell align="left">{row.value}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </CardContent>
                </Card>
            </Container>

            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="/customer/list">리스트</Button>
                    {(has_role_write || has_role_update) ?
                        <Button component={Link} variant="contained" to="/customer/update" state={{ data: obj_customer }}>수정</Button>
                        : null
                    }
                    <Button variant="contained" onClick={() => { 
                        obj_customer.changed_user = account_info.getAccount();
                        mutate_options_customer.body = obj_customer;
                        
                        result_mutate_customer.mutate();
                        // obj_customer.changed_user = account_info.getAccount();
                        // let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessDelete, onErrorDelete);
                        // axiosDescriptor.data = obj_customer;
                        // //deleteCustomer(obj_customer);
                        // useProd.axiosRemoveLensInfo(axiosDescriptor);
                    }}>삭제</Button>
                </Stack>
            </Container>
        </>
    );
};
export default CustomerView;
