const MPUtils = {};

export default MPUtils;

MPUtils.ValidateModelName = (name) => {
    let obj_temp = {};
    if (name.length > 0) {
        obj_temp.model_name = name;
        let tempstr = '';
        if (name.substr(0, 1) == 'G' || name.substr(0, 1) == 'I') {
            switch (name.substr(0, 3)) {
                case 'G23':
                case 'g23':
                    tempstr = 'Galaxy 23';
                    break;
                case 'I15':
                case 'i15':
                    tempstr = 'iPhone 15';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_m_model = tempstr;
            
            switch (name.substr(3, 1)) {
                case 'A':
                case 'a':
                    tempstr = 'VER1';
                    break;
                case 'B':
                case 'b':
                    tempstr = 'VER2';
                    break;
                case 'C':
                case 'c':
                    tempstr = 'VER3';
                    break;
                case 'D':
                case 'd':
                    tempstr = 'VER4';
                    break;
                case 'E':
                case 'e':
                    tempstr = 'VER5';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_ver1 = tempstr;

            switch (name.substr(4, 1)) {
                case '1':
                    tempstr = 'Multi + Display cut';
                    break;
                case '2':
                    tempstr = 'stereo + Display cut';
                    break;
                case '3':
                    tempstr = 'OLED';
                    break;
                case '6':
                    tempstr = 'w/o camera';
                    break;
                case '7':
                    tempstr = 'w/ camera for eye tracking';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_usecamera = tempstr;

            switch (name.substr(5, 1)) {
                case '2':
                    tempstr = 'demo';
                    break;
                case '3':
                    tempstr = '자체디자인';
                    break;
                case '5':
                    tempstr = '스탠다드';
                    break;
                case '7':
                    tempstr = '프리미엄';
                    break;
                case '9':
                    tempstr = '특별제품';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_grade = tempstr;

            switch (name.substr(6, 1)) {
                case '0':
                    tempstr = '기본';
                    break;
                case '1':
                    tempstr = 'ver1';
                    break;
                case '2':
                    tempstr = 'ver2';
                    break;
                case '3':
                    tempstr = 'ver3';
                    break;
                case '4':
                    tempstr = 'ver4';
                    break;
                case '5':
                    tempstr = 'ver5';
                    break;
                case '6':
                    tempstr = 'ver6';
                    break;
                case '7':
                    tempstr = 'ver7';
                    break;
                case '8':
                    tempstr = 'ver8';
                    break;
                case '9':
                    tempstr = 'ver9';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_ver2 = tempstr;
        }
        else {
            switch (name.substr(0, 1)) {
                case 'U':
                case 'u':
                    tempstr = 'UHD';
                    break;
                case 'S':
                case 's':
                    tempstr = '8K UHD';
                    break;
                case 'F':
                case 'f':
                    tempstr = 'FHD';
                    break;
                case 'Q':
                case 'q':
                    tempstr = 'QHD';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_resolution = tempstr;

            if (name.substr(1, 2))
                obj_temp.model_screensize = name.substr(1, 2);

            switch (name.substr(3, 1)) {
                case 'A':
                case 'a':
                    tempstr = 'VER1';
                    break;
                case 'B':
                case 'b':
                    tempstr = 'VER2';
                    break;
                case 'C':
                case 'c':
                    tempstr = 'VER3';
                    break;
                case 'D':
                case 'd':
                    tempstr = 'VER4';
                    break;
                case 'E':
                case 'e':
                    tempstr = 'VER5';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_ver1 = tempstr;

            switch (name.substr(4, 1)) {
                case '1':
                    tempstr = 'Multi + Display cut';
                    break;
                case '2':
                    tempstr = 'stereo + Display cut';
                    break;
                case '3':
                    tempstr = 'OLED';
                    break;
                case '6':
                    tempstr = 'w/o camera';
                    break;
                case '7':
                    tempstr = 'w/ camera for eye tracking';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_usecamera = tempstr;

            switch (name.substr(5, 1)) {
                case '2':
                    tempstr = 'demo';
                    break;
                case '3':
                    tempstr = '자체디자인';
                    break;
                case '5':
                    tempstr = '스탠다드';
                    break;
                case '7':
                    tempstr = '프리미엄';
                    break;
                case '9':
                    tempstr = '특별제품';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_grade = tempstr;

            switch (name.substr(6, 1)) {
                case '0':
                    tempstr = '기본';
                    break;
                case '1':
                    tempstr = 'ver1';
                    break;
                case '2':
                    tempstr = 'ver2';
                    break;
                case '3':
                    tempstr = 'ver3';
                    break;
                case '4':
                    tempstr = 'ver4';
                    break;
                case '5':
                    tempstr = 'ver5';
                    break;
                case '6':
                    tempstr = 'ver6';
                    break;
                case '7':
                    tempstr = 'ver7';
                    break;
                case '8':
                    tempstr = 'ver8';
                    break;
                case '9':
                    tempstr = 'ver9';
                    break;
                default:
                    tempstr = '';
                    break;
            }
            obj_temp.model_ver2 = tempstr;
        }
    }

    return obj_temp;
};

