// base
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// mui
import { FormControl, InputBase, InputLabel, Typography, Grid, Button, Stack, Container } from '@mui/material';
// hook
import useModelComponent from 'hooks/useModelComponent';
import { GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { DateFormat } from 'utils/Dateformat';
import { MopicContext } from 'hooks/useContextManager';

const Update = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
    const has_role_update = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_UPDATE");

    const navigate = useNavigate();
    const onSuccessMutate = (data, variables, context) => {
        alert("수정되었습니다");
        navigate('/spec/lens');
    };

    const onErrorMutate = () => {
        console.log('Error Update');
    };

    // print variable
    const [obj_parts, setParts] = useState({
        lens_name:'',
        lens_param_lp:'',
	    changed_message : '',
        changed_date: DateFormat(new Date()),
        changed_user: ''
    });

    const [checkstate, setCheckState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                setParts({
                    ...data
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_parts])

    const { lens_name, lens_param_lp,  changed_message, changed_date, changed_user } = obj_parts;

    // TODO : regist confirm 정리하자
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function")
        {
            return;
        }
        if (onCancel && typeof onCancel !== "function")
        {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message))
            {
                onConfirm();
            }
            else
            {
                onCancel();
            }
        }

        return confirmAction;
    };

    const updateConfirm = () => {
        if (has_role_write || has_role_update)
        {
            obj_parts.changed_user = account_info.getAccount();
            obj_parts.changed_date = new Date();
             // cng
            if (obj_parts.lens_param_lp && obj_parts.lens_name && obj_parts.changed_user)
            {
                let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessMutate, onErrorMutate);
                axiosDescriptor.data = obj_parts;
                useModelComponent.axiosChangeLensInfo(axiosDescriptor);
            }
            else
            {
                alert('필수값을 입력해주세요');
            }
        } else
        {
            alert('권한이 없습니다');
        }
    };

    const cancelConfirm = () => alert("취소되었습니다");

    const confirmUpdate = useConfirm(
        "수정하시겠습니까?",
        updateConfirm,
        cancelConfirm
    );
    const onChangeHandler = (e) => {
        let { value, id } = e.target;
        
        setParts({
            ...obj_parts,
            [id]: value
        })
    };

    return (
        <>
            <Container sx={{ m: 0 }}>
                {/* cng */}
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }}
                    variant="h6"
                >
                    Lens 수정
                </Typography>
                

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="lens_name" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>제품명 *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240,}}>
                            <InputBase id="lens_name" className="inputStyle" value={lens_name} readOnly />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="lens_param_lp" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>lp *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0, minWidth: 240 }}>
                            <InputBase id="lens_param_lp" className="inputStyle" value={lens_param_lp} onChange={onChangeHandler} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="changed_message" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Note</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2, py: '4px' }}>
                        <InputBase
                            id="changed_message" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', background: '#fff' }}
                            value={changed_message || ''}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="" sx={{ minWidth: 100 }} onClick={confirmUpdate}>수정</Button>
                </Stack>
            </Container>
        </>
    );
};
export default Update;

