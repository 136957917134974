import React, { useState, useEffect, useContext} from 'react';
import { GetAxiosGetDescriptior } from 'hooks/useAxios';
import axiosLog from 'hooks/useLog';
import { BackToTop } from '../../common/ScrollToTop';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";

import {
    Card, CardContent,
    Typography, Autocomplete, TextField,
    Grid, Container, Radio, RadioGroup, FormControlLabel,
    FormControl, InputBase
  } from "@mui/material";
import "react-datepicker/dist/react-datepicker.min.css";
import LogDialog from './LogDialog';
import LogDataGrid from './LogDataGrid';
import { MopicContext } from 'hooks/useContextManager';

const AppMopicLibLog = () => {
  
  const listpage_info = useContext(MopicContext).listpage_info;
  const [log_serial, SetLogSerial] = React.useState(listpage_info.getLogMopiclibSearchKeySerialNumber() || "");
  const log_startdate = listpage_info.getLogMopiclibSearchStartDate() || new Date().toISOString().split('T')[0];; //searchParams.get("log_startdate") || new Date().toISOString().split('T')[0];
  const log_enddate = listpage_info.getLogMopiclibSearchEndDate() || log_startdate; //searchParams.get("log_enddate") || log_startdate;
  const log_msg = listpage_info.getLogMopiclibSearchMessage() || ""; //searchParams.get("log_msg") || "";
  const log_page = listpage_info.getLogMopiclibPageIndex() || 0; //searchParams.get("log_page");

  const [logdata, SetLogData] = useState([]);
  
  const [is_loaded_keylist, SetIsLoadedKeyList] = React.useState(false);
  const [serialList, SetSerialList] = React.useState([]);

  const [customerList, SetCustomerList] = React.useState([]);
  const [modelList, SetModelList] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState({});


  
  const [page, setPage] = useState(log_page);
  const [rowcount, setRowCount] = useState(10);


  const [startDate, setStartDate] = useState(new Date(log_startdate));
  const [endDate, setEndDate] = useState(new Date(log_enddate));
  const [radio_date, setRadioDate] = useState(1);
  

  let org_log_data=[];
  
  const handleDialogClose = (value) => {
    setOpenDialog(false);
  };
  const handleLogClick = (value) => {
    setSelectedValue(value);
    setOpenDialog(true);
  }
  const handleChangePage = (page) => {
    ///setSearchParams({ log_serial, log_page : page, log_startdate, log_enddate });
    listpage_info.setLogMopiclibPageIndex(page);
    setPage(page);
    window.scrollTo(0, 0);
  };
  
  const getLogData = (key, msg, startdate, enddate) => {
    // console.log(key, msg, startdate, enddate);
    let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetLogData, onErrorAxiosCommons);
      axiosDescriptor.keys.serialnumber = key;
      axiosDescriptor.keys.message = msg;
      axiosDescriptor.keys.startdate = convertToUTCString(startdate);
      axiosDescriptor.keys.enddate = convertToUTCString(datePlusDays(enddate,1));
      

      axiosLog.axiosGetMopicLibLogData(axiosDescriptor);
  }

  useEffect(() => {
    getLogData(log_serial, log_msg, startDate, endDate);
    if (!is_loaded_keylist) {
       let axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetKeyList, onErrorAxiosCommons);
       axiosLog.axiosGetMopicLibLogKeyList(axiosDescriptor);
       SetIsLoadedKeyList(true);
       
    }
  }, [log_serial, startDate, endDate, log_msg, is_loaded_keylist]);
  
  useEffect(() => {
    setPage(listpage_info.getLogMopiclibPageIndex());
    setRowCount(listpage_info.getLogMopiclibPageRowCount());
  }, [listpage_info, page, rowcount]);

  function daysBetweenDates(date1, date2) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
  
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24) +1;
  
    return Math.abs(differenceInDays); 
  }
  //
  function datePlusDays(date1, days) {
    const date = new Date(date1);
    date.setDate(date.getDate() + days);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  function dateMinusDays(date1, days) {
    const date = new Date(date1);
    date.setDate(date.getDate() - days);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  function calculateTimezoneDifference() {
    return new Date().getTimezoneOffset() / 60;
  }


  function convertToUTCString(dateString) {
    const date = new Date(dateString);
    const utc = date.getTime() + calculateTimezoneDifference() * 60 * 60 * 1000;
    return new Date(utc).toISOString().substring(0,16).replace("T"," ");
  }



  const onChangeFindValueStartDate = (date) => {
    let date_obj = new Date(date);
    setStartDate(date_obj);
    
    ///setSearchParams({ log_serial, log_page : 0, log_msg, log_startdate: date.toISOString().split('T')[0], log_enddate });
    listpage_info.setLogMopiclibSearchStartDate(date.toISOString().split('T')[0]);
    listpage_info.setLogMopiclibSearchEndDate(endDate);
    //console.log(date.toISOString().split('T')[0]);
    changeDueBtn(date_obj, endDate);
  };
  const onChangeFindValueEndDate = (date) => {
    let date_obj = new Date(date);
    setEndDate(date_obj);
    //setRadioDate(0);
    changeDueBtn(startDate, date_obj);

    listpage_info.setLogMopiclibSearchStartDate(startDate);
    listpage_info.setLogMopiclibSearchEndDate(date.toISOString().split('T')[0]);
  };
  function changeDueBtn(date1, date2) {
    const day = daysBetweenDates(date1, date2)+1;
    if (day === 1 || day === 7 || day === 30) {
      setRadioDate(day);
    }
    else {
      setRadioDate(0);
    }
  }
  
  const onSuccessGetKeyList = (data) => {
    let _serialList = [];
    _serialList.push({label: "전체", value: ""});
    for (var i=0; i<data.length; i++) {
        _serialList.push({label: data[i].serialnumber, value: data[i].serialnumber});
    }
    SetSerialList(_serialList);
  }
  
  const onSuccessGetLogData = (data) => {
    if (data.length)
    {
      let tempData = [];
      let _modelList = [];
      let _customerList = [];
      _modelList.push({label: "전체", value: ""});
      _customerList.push({label: "전체", value: ""});
      for (var i=0; i<data.length; i++) {
          let msg = data[i].log_message;
          let log_info = data[i].log_message.split('|||');
          let info = {ip:"", model_name:"", product_id:"", customer_company:"",customer_name:"", model_serialnumber:""};
          if (log_info.length >1) {
            msg = log_info[0];
            info = JSON.parse(log_info[1]);
          }
          
          if (data[i].log_level === "VERIFY1") {
            msg = msg.replace("{","").replace("}","").replace(/"/gi," ").replace(/,/gi,"<br />" );
          }
          
          let company = info.customer_company ? info.customer_company : "";
          company += info.customer_name ? " ("+info.customer_name+")" : "";
          tempData.push({ 
              id : i,
              date: new Date(data[i].log_time).toLocaleString(), 
              serial: data[i].serialnumber, 
              type: data[i].log_level, 
              log: msg,
              ip: info.ip,
              model_name: info.model_name,
              customer_company: company ,
              customer_name: info.customer_name,
              model_serialnumber: info.model_serialnumber,
              product_id : info.product_id
          });
          if (!(_modelList.findIndex((item) => item.value === info.model_name) !== -1)) {
            _modelList.push({label: info.model_name, value: info.model_name});
          }
          // if (_modelList.indexOf(info.model_name) < 0) {
          //   _modelList.push({label: info.model_name, value: info.model_name});
          // }
          if (!(_customerList.findIndex((item) => item.value === info.customer_company) !== -1)) {
            _customerList.push({label: info.customer_company, value: info.customer_company});
          }
          
      }
      SetModelList(_modelList);
      SetCustomerList(_customerList);
      org_log_data = tempData;
      SetLogData(tempData);
    }
    else
    {
      SetModelList([]);
      SetCustomerList([]);
      //org_log_data = [];
      SetLogData([]);
    }
  }
  const onErrorAxiosCommons = (data) => {
    console.log(data);
  }

  return (
    <>
      <Container sx={{ m: 0, p:0 }} className="log_list_wrap">
        <Container sx={{ display: "flex", justifyContent: "space-between", alignItem: "right", flexWrap: "wrap", m: 0, }}>
          <Typography sx={{ m: 0, fontWeight:700 }} variant="h5" className="page_title">
            MopicLib Log
          </Typography>
        </Container>
        {/* Search Container */}
        <Container sx={{ mx: 0, mt: 2, mb: 5, display: "flex", flexWrap: "wrap" }} className="searchWrap">
          <Card sx={{ width: "100%", p: 2 }}>
            <CardContent>
              <Grid container spacing={2} sx={{ fontSize: "14px", itemAlign: "center" }}>
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    License
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent" >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={serialList}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      sx={{ maxWidth:"350px !important" }}
                      className="inputStyle"
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="License" />}
                      value={log_serial}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          getLogData(newValue.value,log_msg, startDate, endDate);
                          ///setSearchParams({ log_serial: newValue.value, log_page : 0, log_startdate, log_enddate });
                          listpage_info.setLogMopiclibSearchKeySerialNumber(newValue.value);
                        }
                        else {
                          getLogData("");
                          ///setSearchParams({ log_serial: "", log_page : 0, log_startdate, log_enddate });
                          listpage_info.setLogMopiclibSearchKeySerialNumber("");
                          listpage_info.setLogMopiclibPageIndex(0);
                        }
                        SetLogSerial(newValue.value);
                      }}
                    />
                </Grid>
                
                <Grid item xs={4} md={2} sx={{}} className="searchTitle range_title">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    기간
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="log_range" >
                  <div className="range_wrap">

                    <Grid container spacing={0} sx={{maxWidth:"350px !important"}}>
                      <Grid item xs={5}
                        sx={{
                          "& .MuiInputBase-root": { width: "100%", textAlign: "center" },
                          "& .MuiInputBase-root input": { textAlign: "center", color: "rgba(0,0,0,0.9)" }
                        }}
                      >
                        <DatePicker className="inputStyle" customInput={<InputBase />} dateFormat={"yyyy-MM-dd"} selected={startDate} selectsStart startDate={startDate} endDate={endDate} maxDate={endDate} onChange={onChangeFindValueStartDate} />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center", px: 1, lineHeight: "42px", fontSize: "14px" }}>
                        <span> ~ </span>
                      </Grid>
                      <Grid item xs={5} 
                        sx={{
                          "& .MuiInputBase-root": { width: "100%" },
                          "& .MuiInputBase-root input": { textAlign: "center" }
                        }}
                      >
                        <DatePicker
                          fullWidth
                          selected={endDate}
                          dateFormat={"yyyy-MM-dd"}
                          onChange={onChangeFindValueEndDate}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          customInput={<InputBase />}
                          className="inputStyle"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="range_radio_wrap">
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radio_date}
                            onChange={(event, value)=> {
                              if(value>0) {
                                const ed = dateMinusDays(endDate, value-1);
                                setStartDate(new Date(ed));
                                listpage_info.setLogProductSearchStartDate(ed);
                              }
                              setRadioDate(value);
                            }}
                        >
                          <FormControlLabel value="0" control={<Radio />} label="기타" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px'}} />
                          <FormControlLabel value="1" control={<Radio />} label="1일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px' }} />
                          <FormControlLabel value="7" control={<Radio />} label="7일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px' }} />
                          <FormControlLabel value="30" control={<Radio />} label="30일" sx={{ '& span': { fontSize: 14}, ml:0, marginRight:'10px'}} />
                        </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                
                
                <Grid item xs={4} md={2} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    Message
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} className="searchContent">
                  <InputBase name="log_message" sx={{maxWidth:"80%"}} className="inputStyle" fullWidth 
                    defaultValue={log_msg}
                    onChange={(event)=> {
                      //console.log('searchContent', event.target.value);
                      getLogData(log_serial ,event.target.value, startDate, endDate);
                      ///setSearchParams({ log_serial, log_page : 0, log_msg : event.target.value, log_startdate, log_enddate });
                      listpage_info.setLogMopiclibPageIndex(0);
                      listpage_info.setLogMopiclibSearchMessage(event.target.value);
                    }} />
                </Grid>
                
                {/* <Grid item xs={4} md={2} sx={{}} className="searchTitle">
                  <Typography sx={{ m: 0, fontSize: "14px" }}>
                    고객사
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    sx={{ maxWidth:"350px" }}
                    className="inputStyle"
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Customer" />}
                    onChange={(event, newValue) => {
                        if (newValue) {
                          setFilterCustomer(newValue.value);
                        }
                        else {
                          setFilterCustomer("")
                        }
                        
                    }}
                  />
                </Grid>  */}
              </Grid>
            </CardContent>
          </Card>
          <LogDataGrid logdata={logdata} onLogClick={handleLogClick} page={page} onChangeListPage={handleChangePage}/>
        </Container>
    </Container>

    <LogDialog  open={openDialog}  dialogData={selectedValue} onDialogClose={handleDialogClose}/>
    <BackToTop />
    </>
  );  
}

export default AppMopicLibLog;

