import { Route, Routes } from 'react-router-dom';



import Redirect from 'view/common/Redirect';

import ModelList from 'view/pages/model/ModelList';
import ModelView from 'view/pages/model/ModelView';
import ModelWrite from 'view/pages/model/ModelWrite';
import ModelUpdate from 'view/pages/model/ModelUpdate';

import ModelComponentsManager from 'view/pages/model/ModelComponentsManager';

import CustomerList from 'view/pages/customer/CustomerList';
import CustomerView from 'view/pages/customer/CustomerView';
import CustomerWrite from 'view/pages/customer/CustomerWrite';
import CustomerUpdate from 'view/pages/customer/CustomerUpdate';

import ProductList from 'view/pages/product/ProductList';
import ProductView from 'view/pages/product/ProductView';
import ProductWrite from 'view/pages/product/ProductWrite';
import ProductUpdate from 'view/pages/product/ProductUpdate';

import SpecCameraList from 'view/pages/spec/camera/List';
import SpecLensList from 'view/pages/spec/lens/List';
import SpecDisplayList from 'view/pages/spec/display/List';
import Spec3dboxList from 'view/pages/spec/3dbox/List';

import SpecCameraView from 'view/pages/spec/camera/View';
import SpecLensView from 'view/pages/spec/lens/View';
import SpecDisplayView from 'view/pages/spec/display/View';
import Spec3dboxView from 'view/pages/spec/3dbox/View';

import SpecCameraUpdate from 'view/pages/spec/camera/Update';
import SpecLensUpdate from 'view/pages/spec/lens/Update';
import SpecDisplayUpdate from 'view/pages/spec/display/Update';
import Spec3dboxUpdate from 'view/pages/spec/3dbox/Update';

import SpecCameraWrite from 'view/pages/spec/camera/Write';
import SpecLensWrite from 'view/pages/spec/lens/Write';
import SpecDisplayWrite from 'view/pages/spec/display/Write';
import Spec3dboxWrite from 'view/pages/spec/3dbox/Write';

import Test from 'view/pages/sample/Test';
import Grid from 'components/grid/Grid';





import EyetrackerManager from 'view/pages/eyetracker/EyetrackerManager';
import LogProductAPI from 'view/pages/logviewer/LogProductAPI';
import LogMopiblib from 'view/pages/logviewer/LogMopicLib';

function MPRouter() {
    return (
        <Routes>
            <Route exact path="/model/list" element={<ModelList />}></Route>
            <Route exact path="/model/view" element={<ModelView />}></Route>
            <Route exact path="/model/write" element={<ModelWrite />}></Route>
            <Route exact path="/model/update" element={<ModelUpdate />}></Route>

            <Route exact path="/modelcomponentsmanager" element={<ModelComponentsManager />}></Route>

            <Route exact path="/customer/list" element={<CustomerList />}></Route>
            <Route exact path="/customer/view" element={<CustomerView />}></Route>
            <Route exact path="/customer/write" element={<CustomerWrite />}></Route>
            <Route exact path="/customer/update" element={<CustomerUpdate />}></Route>

            <Route exact path="/product/list" element={<ProductList />}></Route>
            <Route exact path="/product/view" element={<ProductView />}></Route>
            <Route exact path="/product/write" element={<ProductWrite />}></Route>
            <Route exact path="/product/update" element={<ProductUpdate />}></Route>


            <Route exact path="/spec/camera" element={<SpecCameraList />}></Route>
            <Route exact path="/spec/camera/view" element={<SpecCameraView />}></Route>
            <Route exact path="/spec/camera/update" element={<SpecCameraUpdate />}></Route>
            <Route exact path="/spec/camera/write" element={<SpecCameraWrite />}></Route>

            <Route exact path="/spec/display" element={<SpecDisplayList />}></Route>
            <Route exact path="/spec/display/view" element={<SpecDisplayView />}></Route>
            <Route exact path="/spec/display/update" element={<SpecDisplayUpdate />}></Route>
            <Route exact path="/spec/display/write" element={<SpecDisplayWrite />}></Route>

            <Route exact path="/spec/lens" element={<SpecLensList />}></Route>
            <Route exact path="/spec/lens/view" element={<SpecLensView />}></Route>
            <Route exact path="/spec/lens/update" element={<SpecLensUpdate />}></Route>
            <Route exact path="/spec/lens/write" element={<SpecLensWrite />}></Route>

            <Route exact path="/spec/3dbox" element={<Spec3dboxList />}></Route>
            <Route exact path="/spec/3dbox/view" element={<Spec3dboxView />}></Route>
            <Route exact path="/spec/3dbox/update" element={<Spec3dboxUpdate />}></Route>
            <Route exact path="/spec/3dbox/write" element={<Spec3dboxWrite />}></Route>

            

            <Route exact path="/eyetrackermanager" element={<EyetrackerManager />}></Route>
            
            <Route exact path="/logviewer/mopiclib" element={<LogMopiblib />}></Route>
            <Route exact path="/logviewer/product" element={<LogProductAPI />}></Route>

            <Route exact path="/redirect" element={<Redirect />}></Route>

            
            <Route exact path="/test" element={<Test />}></Route>
            <Route exact path="/grid" element={<Grid />}></Route>
            {/* <Route exact path="/" element={<ERPMain />}></Route> */}
            
            <Route exact path="/" element={<ProductList />}></Route>
        </Routes>
    );
  }

export default MPRouter;