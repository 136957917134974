import React, { useEffect} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ISO2Name } from './NationISO';

import {
     Button
  } from "@mui/material";

 const fineMsg =  [
  "All Good.",
  "Monitor is fine.",
  "Camera is fine.",
  "Eyetracking is fine.",
  "Eyetracking is running.",
  "License is fine.",
  "License local is fine .",
  "License is changed.",
  "Render is fine.",
  "Mopiclib is fine.",
  "Communication is fine."
]
const failMsg = [
"Monitor power is not connected.",
"Monitor not connected.",
"Monitor resolution is not correct.",
"Monitor frame rate is not correct.",
"Monitor color format is not correct.",
"Monitor's GPU has not been set.",
"Camera initialize failed.",
"Camera is not connected.",
"Camera is not connected to the USB 3.0.",
"Eyetracking initialize failed.",
"Eyetracking default initialize failed.",
"Eyetracking type is invalid.",
"Eyetracking not found.",
"Eyetracking stoped.",
"License is empty.",
"License is incorrect.",
"License is expired.",
"License local load failed.",
"Render interface is not loaded.",
"Render API not found.",
"Render initialize failed.",
"Mopiclib is already initialized.",
"Mopiclib does not initialize.",
"Mopiclib version is not invalid.",
"Mopiclib not found.",
"Mopiclib entry point not found.",
"Communication request failed.",
"Communication initialize failed.",
"Communication load parameter failed."
]


const LogDialog = (prop) => {
    useEffect(() => {
        //console.log(prop)
    });

  const { open , dialogData , onDialogClose} = prop;


  const handleDialogClose = () => {
    setCountry("Loading...");
    onDialogClose();
  };

  function createData(name, value) {
    if (value) {
      dialogRows.push({name, value});
    }
  }

  let dialogRows = [];
  const [country, setCountry] = React.useState('');
  const DialogView = () => {
    if(!dialogData) return (<></>);
    dialogRows = [];
    createData('License', dialogData.serial);
    createData('Type', dialogData.type);
    createData('Date', dialogData.date);
    //if (dialogData.customer_name) {
    //    createData('Customer', dialogData.customer_company + " (" + dialogData.customer_name + ")");
    //}
    //else {
        createData('Customer', dialogData.customer_company );
    //}
    createData('PRODUCT ID', dialogData.product_id);
    createData('IP', dialogData.ip);
    createData('Model Name', dialogData.model_name);
    createData('Model Serial', dialogData.model_serialnumber);

    const getGeoData = async (ip) => {
      
      /*setCountry(ISO2Name(res_data.country));
      console.log( res_data.country ); 
      console.log(ISO2Name(res_data.country));*/
      let req_url = "https://api.mopiclabs.com/dev/board/ip?ip=";

      //let req_url = "https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8/";
      req_url += ip;
  
      const res = await fetch(req_url, {method: "GET"});
      const res_data = await res.json();
      console.log(res_data);
      let pos = ISO2Name(res_data.country);
      if (res_data.r1) {
        pos += " " + res_data.r1;
      }
      if (res_data.r2) {
        pos += " " + res_data.r2;
      }
      if (res_data.r3) {
        pos += " " + res_data.r3;
      }
      setCountry(pos);
      return ;
    }
    
    if (dialogData.ip) {
      getGeoData(dialogData.ip);
    }
    if (dialogData.type === "VERIFY") {
      // console.log(dialogData.log);
      let msg = dialogData.log.replace("{","").replace("}","").replace(/"/gi," ").replace(/,/gi,"<br />" );
      const sections = msg.split("<br />").map(s => s.trim());
      const wrappedSections = sections.map(section => {
        const [title, ...content] = section.split(":").map(s => s.trim());
        const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
        let cont = content.join(':').trim();
        
        if (fineMsg.indexOf(cont) > -1) {
          cont = "<span class='fine'>" +cont+ "</span>";
        }
        else if (failMsg.indexOf(cont) > -1) {
          cont = "<span class='fail'>" +cont+ "</span>";
        }
        else {
          cont = "<span class='other'>" +cont+ "</span>";
        }
        return `<b>${formattedTitle}</b> : ${cont}`;
      });

      const wrappedStr = wrappedSections.join(" <br> ");
      createData('LOG', wrappedStr);
    }
    else {
      createData('LOG', dialogData.log);
    }

    
    return (

        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table" size="middle"> 
            <TableBody>
              {dialogRows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{minWidth:'130px'}}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    <p style={{margin:0}}  dangerouslySetInnerHTML={{ __html: row.value }}></p>
                    {/* {row.value} */}
                  </TableCell>
                </TableRow>
              ))}
              {dialogData.ip ? (
              <TableRow
                  key={
                    'COUNTRY'
                  }
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Country
                  </TableCell>
                  <TableCell align="left">
                    {country}
                  </TableCell>
                </TableRow>
                ) : null}
            </TableBody>
          </Table>
        </TableContainer>
    )
  }
  


return (
    <>
        <Dialog
            open={open}
            onClose={handleDialogClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            // TransitionComponent={({ children }) => children}
        >
            <DialogTitle id="scroll-dialog-title">Log</DialogTitle>
            <DialogContent dividers={true} sx={{backgroundColor : "#f5f5f5"}}>
                <DialogView />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>
        </Dialog>

    </>
  );  
}

export default LogDialog;


  
LogDialog.propTypes = {
    onDialogClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };