// react base
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// react ui
import DatePicker from 'react-datepicker'

// mui
import { Grid, Container, Button, Stack, InputBase, MenuItem, Select, Typography, Alert } from '@mui/material';

// etc ui
import 'react-datepicker/dist/react-datepicker.min.css'

// hook
import { getValidTimeList,  getConvertValidTime, sendEmail } from 'hooks/useProduct';

import { MopicContext } from 'hooks/useContextManager';

import { GetAxiosGetDescriptior, GetAxiosCommonDescriptior } from 'hooks/useAxios';
import { axiosGetCustomerInfo } from 'hooks/useCustomer';
import { axiosGetEyeTrackerTypeList, axiosGetEyeTrackerLicenseTypeList, axiosPutProductInfoChange, axiosPostProductValidTimeChange, axiosPostProductSerialNumber } from 'hooks/useProduct';
import { axiosGetModeInfo } from 'hooks/useModel';

const ProductUpdate = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_WRITE");
    const has_role_update = account_info.hasRole("PRODUCT_ALL") || account_info.hasRole("PRODUCT_UPDATE");

    const navigate = useNavigate();
    // list selector loader
    const [is_loaded_modelinfo, LoadedModelInfo] = useState(false);
    const [is_loaded_customerlist, LoadedCustomerList] = useState(false);
    const [is_loaded_eyetrackerlist, LoadedEyeTrackerList] = useState(false);
    const [is_loaded_eyetracker_license_type_list, LoadedEyeTrackerLicenseTypeList] = useState(false);

    const [list_customer, SetCustomerList] = useState([]);
    const [list_eyetracker, SetEyeTrackerList] = useState([]);
    const [list_eyetracker_license_type_origin, SetEyeTrackerLicenseTypeOriginList] = useState([]);
    const [list_eyetracker_license_type, SetEyeTrackerLicenseTypeList] = useState([]);
    const [model_param, SetModelParam] = useState({});
    const [serial_check, SetSerialCheck] = useState(true);

    const [checkstate, setCheckState] = useState(false);
    const location = useLocation();

    const onSuccessGetModelInfo = (data) => {
        let temp_obj = {};
        
        data.forEach(elem => {
            temp_obj = {
                theta: elem.base.theta,
                spsize: elem.base.spsize,
            };
        })
        SetModelParam(temp_obj);
        LoadedModelInfo(true);
    }
    const onErrorGetModelInfo = (data) => { }

    const onSuccessGetCustomerInfo = (data) => {
        let obj_customers = [];
        let obj_customer = {};
        let customer_company, customer_name;
        const use_key_customer_company = ['customer_company'];
        const use_key_customer_name = ['customer_name'];
        const use_key_customer_id = ['customer_id'];

        data.forEach((elem, idx, arr) => {
            obj_customer = {};
            for (const key in elem)
            {
                if (use_key_customer_company.indexOf(key) > -1)
                {
                    customer_company = elem[key]
                }
                if (use_key_customer_name.indexOf(key) > -1)
                {
                    customer_name = elem[key]
                }
                if (use_key_customer_id.indexOf(key) > -1)
                {
                    obj_customer[key] = elem[key]
                }

                if (customer_company && customer_name)
                {
                    obj_customer['customer'] = customer_company + "(" + customer_name + ")";
                    customer_company = undefined;
                    customer_name = undefined;
                }
            }
            obj_customers.push(obj_customer);
        });
        const set = new Set(obj_customers);
        const uniqueCustomers = [...set];

        SetCustomerList(uniqueCustomers);
        LoadedCustomerList(true);
    }
    const onErrorGetCustomerInfo = (data) => { }

    const onSuccessGetEyetrackerList = (data) => {
        SetEyeTrackerList(data);
        LoadedEyeTrackerList(true);
    }
    const onErrorGetEyetrackerList = (data) => { }

    const onSuccessProductInfoChange = (data) => {
        alert("수정되었습니다");
        navigate('/product/list');
    };
    const onErrorProductInfoChange = (data) => { };

    const onSuccessProductValidTimeChange = (data) => { };
    const onErrorProductValidTimeChange = (data) => { };

    const onSuccessGetEyetrackerLicenseTypeList = (data) => {

        SetEyeTrackerLicenseTypeOriginList(data);
        LoadedEyeTrackerLicenseTypeList(true);
    }
    const onErrorGetEyetrackerLicenseTypeList = (data) => { }
    
    const onSuccessProductCheckSN = (data) => {
        if(data && data.result === "ERROR" && data.reason === "NOT_REGISTED") {
            SetSerialCheck(true);
        }
        else {
            SetSerialCheck(false);
        }
    }


    useEffect(() => {
        let axiosDescriptor;
        const data = location.state?.data;

        if (is_loaded_modelinfo === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetModelInfo, onErrorGetModelInfo);
            axiosDescriptor.keys['model_name'] = data.model_name;
            axiosGetModeInfo(axiosDescriptor);
        }
        if (is_loaded_customerlist === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetCustomerInfo, onErrorGetCustomerInfo);
            axiosGetCustomerInfo(axiosDescriptor);
        }
        if (is_loaded_eyetrackerlist === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetEyetrackerList, onErrorGetEyetrackerList);
            axiosGetEyeTrackerTypeList(axiosDescriptor);
        }
        if (is_loaded_eyetracker_license_type_list === false)
        {
            axiosDescriptor = GetAxiosGetDescriptior(onSuccessGetEyetrackerLicenseTypeList, onErrorGetEyetrackerLicenseTypeList);
            axiosGetEyeTrackerLicenseTypeList(axiosDescriptor);
        }
    }, [is_loaded_modelinfo, is_loaded_customerlist, is_loaded_eyetrackerlist, is_loaded_eyetracker_license_type_list, location]);


    // const onSuccessMutateProduct = (data, variables, context) => {
    //     //queryClient.invalidateQueries(QUERYKEY_PRODUCT['changeProductInfo']);
    //     alert("수정되었습니다");
    //     navigate('/product/list');
    // };

    // const onErrorMutateProduct = (error) => {
    //     console.log('Error Update Product');
    // };

    // const mutate_options_product = {
    //     'body': null,
    //     'callback_success': onSuccessMutateProduct,
    //     'callback_error': onErrorMutateProduct
    // };

    // const result_mutate_product = changeProductInfo(mutate_options_product);

    // const onSuccessMutateValidTime = (dta, variables, context) => {
    //     console.log('Success Update Valid Time');
    // };

    // const onErrorMutateValidTime = (error) => {
    //     console.log('Error Update Valid Time');
    // };

    // const mutate_options_validtime = {
    //     'body': null,
    //     'callback_success': onSuccessMutateValidTime,
    //     'callback_error': onErrorMutateValidTime
    // };

    // const result_mutate_validtime = changeProductValidTime(mutate_options_validtime);

    const [startDate, setStartDate] = useState();
    const [obj_product, setProduct] = useState({
        model_name: '',
        model_serialnumber: '',
        product_id: '',
        product_slantedangle: '',
        product_offset: '',
        product_spsize: '',
        eyetracker_idx: '',
        eyetracker_license_type_idx: '',
        customer_id: '',
        product_manager: '',
        product_note: '',
        changed_date: '',
        changed_user: ''
    });

    const [obj_checkvalid, setCheckValid] = useState({
        valid_time: '',
        activate_date: '',
        expire_data: ''
    });

    const [obj_changevalid, setChangeValid] = useState({
        product_id: '',
        valid_time: '',
        changed_user: ''
    })

    const [org_modelSN, setOrgModelSN] = useState("");

    useEffect(() => {
        const data = location.state?.data;
        const activatedatas = location.state?.activatedata;
        if (!checkstate)
        {
            if (data)
            {
                setOrgModelSN(data.model_serialnumber);
                setProduct({
                    ...data
                })
                if (activatedatas)
                {
                    for (let i = activatedatas.length - 1; i >= 0; i--)
                    {
                        var activatedata = activatedatas[i];
                        if (!activatedata.activate_date || !activatedata.expire_date)
                            continue;

                        let current_date = new Date();
                        let start_date = new Date(activatedata.activate_date);
                        let end_date = new Date(activatedata.expire_date);

                        if (current_date > start_date && current_date < end_date)
                        {
                            break;
                        }
                    }
                    setCheckValid({
                        ...activatedata
                    })
                    console.log(activatedata);
                }
                setStartDate(new Date(data.manufacture_date));
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_product, obj_checkvalid, location]);

    let { model_name, model_serialnumber, product_slantedangle, product_offset, product_spsize, eyetracker_idx, eyetracker_license_type_idx, customer_id, product_manager, product_note } = obj_product;

    // regist validator
    const is_valid_user = (has_role_write || has_role_update) ? true : false;
    const [is_valid_data, SetValidData] = useState(false);
    const [is_valid_parameter, SetValidParameter] = useState(false);

    useEffect(() => {
        let model_theta, input_sa;
        let model_spsize, input_spsize;

        let valid_product_info = (obj_product.product_id !== '' && account_info.getAccount() !== null);
        let valid_product_parameter = false;
        let valid_product_parameter_sa = false;
        let valid_product_parameter_spsize = false;

        if (is_loaded_modelinfo === true)
        {
            if (obj_product.model_name !== null && obj_product.model_name !== undefined && obj_product.model_name !== '')
            {
                if (obj_product.product_slantedangle !== null && obj_product.product_slantedangle !== undefined && obj_product.product_slantedangle !== '')
                {
                    if (isNaN(obj_product.product_slantedangle))
                    {
                        valid_product_parameter_sa = false;
                    }
                    else
                    {
                        model_theta = +model_param.theta;
                        input_sa = +obj_product.product_slantedangle;

                        valid_product_parameter_sa = (model_theta + 1 > input_sa) && (model_theta - 1 < input_sa);
                    }
                }
                else
                {
                    valid_product_parameter_sa = true;
                }

                if (obj_product.product_spsize !== null && obj_product.product_spsize !== undefined && obj_product.product_spsize !== '')
                {
                    if (isNaN(obj_product.product_spsize))
                    {
                        valid_product_parameter_spsize = false;
                    }
                    else
                    {
                        model_spsize = +model_param.spsize;
                        input_spsize = +obj_product.product_spsize;
                        if (Math.abs(input_spsize - model_spsize) > 0.001)
                        {
                            valid_product_parameter_spsize = false;
                        }
                        else
                        {
                            valid_product_parameter_spsize = true;
                        }
                    }
                }
                else
                {
                    valid_product_parameter_spsize = true;
                }

                valid_product_parameter = (valid_product_parameter_sa === true) && (valid_product_parameter_spsize === true);
            }
        }

        if (valid_product_info)
        {
            SetValidData(true);
        }
        else
        {
            SetValidData(false);
        }

        if (valid_product_parameter)
        {
            SetValidParameter(true);
        }
        else
        {
            SetValidParameter(false);
        }

    }, [account_info, is_loaded_modelinfo,obj_product.product_id,  obj_product.model_name, obj_product.manufacture_date, obj_product.valid_time, obj_product.product_slantedangle, obj_product.product_spsize, model_param])

    useEffect(() => {
        if (!list_eyetracker_license_type_origin.length)
            return;

        let type = +eyetracker_idx;
        if (isNaN(type))
            return;

        let show_list = [];
        switch (type)
        {
            case 0:
            case 1:
            case 2:
                list_eyetracker_license_type_origin.forEach((elem, idx) => {
                    if (elem.license_type_name === 'Default')
                    {
                        show_list.push(elem);
                    }
                })
                setProduct({
                    ...obj_product,
                    "eyetracker_license_type_idx": 0
                })
                break;
            case 3:
                list_eyetracker_license_type_origin.forEach((elem, idx) => {
                    if (elem.license_type_name === 'Default')
                    {
                        show_list.push(elem);
                    }
                    if (elem.license_type_name.indexOf('Blink') > -1)
                    {
                        show_list.push(elem);
                    }
                })
                break;
            default:
                break;
        }

        SetEyeTrackerLicenseTypeList(show_list);
    }, [eyetracker_idx, list_eyetracker_license_type_origin, obj_product])
    // logical UI
    const ShowValidatorUI = () => {
        if (is_valid_user === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>제품등록 권한이 없습니다. 제품을 등록할수 있는 계정으로 등록해주십시오.</Alert>;
        }
        else if (is_valid_data === false || is_valid_parameter === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>
                <div>등록에 필요한 값이 올바르지 않습니다.</div>
                {is_valid_parameter === false ? <div>잘못된 파라미터 범위</div> : <></>}
            </Alert>
        }
        else if (serial_check === false)
        {
            return <Alert severity="error" sx={{ width: 'fit-content' }}>
                <div>모델 시리얼 번호가 이미 존재합니다. </div>
            </Alert>
        }
        else
        {
            return <Alert severity="success" sx={{ width: 'fit-content' }}>제품수정이 가능합니다.</Alert>
        }
    }

    const updateData = () => {
        if (is_valid_user === false || is_valid_data === false || serial_check === false)
        {
            return;
        }

        obj_product.changed_user = account_info.getAccount();
        obj_product.changed_date = new Date();

        let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductInfoChange, onErrorProductInfoChange);
        axiosDescriptor.data = obj_product;

        axiosPutProductInfoChange(axiosDescriptor);

        if (obj_changevalid.valid_time) 
        {
            obj_changevalid.product_id = obj_product.product_id;
            obj_changevalid.changed_user = obj_product.changed_user;

            axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductValidTimeChange, onErrorProductValidTimeChange);
            axiosDescriptor.data = obj_changevalid;

            axiosPostProductValidTimeChange(axiosDescriptor);
        }

        // mutate_options_product.body = obj_product;
        // console.log(obj_product);
        // result_mutate_product.mutate(null, mutate_options_product);

        // if (obj_changevalid.valid_time) 
        // {
        //     mutate_options_validtime.body = obj_changevalid;
        //     mutate_options_validtime.body.product_id = obj_product.product_id;
        //     mutate_options_validtime.body.changed_user = obj_product.changed_user;
        //     result_mutate_validtime.mutate();
        // }
    };

    // UI Event handler
    const onChangeHandler = (e) => {
        const { value, name } = e.target;
        setProduct({
            ...obj_product,
            [name]: value
        })
        
        if (e.target.name === 'model_serialnumber')
        {
            // console.log(org_modelSN, e.target.value);
            if ( e.target.value == '' || org_modelSN == e.target.value) {
                SetSerialCheck(true);
            }
            else {
                let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductCheckSN, onSuccessProductCheckSN);
                axiosDescriptor.data = {model_serialnumber: e.target.value};
                axiosPostProductSerialNumber(axiosDescriptor);
            }
        }
    };

    const onChangeValidHandler = (e) => {
        const { value, name } = e.target;
        setChangeValid({
            ...obj_changevalid,
            [name]: value
        })
    };

    const getMembers = (email) => {
        const name = account_info.members.filter(item => item.mail === email)[0].displayName;
        return name ? name + "(" + email + ")" : "";
    }


    const [obj_option_email,setObjOptionEmail] = useState({
        message: {
            subject: "Meet for lunch?1111",
            body: {
                contentType: "Text",
                content: "The new cafeteria is open."
            },
            toRecipients: [
                {
                    "emailAddress": {
                        "address": "wlphs@naver.com"
                    }
                }
            ]
        },
        saveToSentItems: "false"
      });
    
        const onSuccessMutateStatus = (data) => {
            console.log("send email success");
            
        };

        const onErrorMutateStatus = (error) => {
            console.log("Error Send email");
        };
      const mutate_options_email = {
        body: obj_option_email,
        callback_success: onSuccessMutateStatus,
        callback_error: onErrorMutateStatus,
      };
    
      const result_mutate_product_email = sendEmail(
        mutate_options_email
      );
       // Status Change



    const sendMail = () => {
        obj_option_email.message.subject = model_name+ " 제품의 영업 담당자로 지정되었습니다.";
        
        obj_option_email.message.body.contentType= 'HTML';
        obj_option_email.message.body.content = "제품의 영업 담당자로 지정되었습니다.<br /> 해당 제품의 유효기간은 --- 일까지 입니다. <br />"+"감사합니다.";
        obj_option_email.message.toRecipients[0].emailAddress.address = "wlphs@naver.com";
        result_mutate_product_email.mutate();
        return;
        
    }
    const changeDateType = (dateStr)=> {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    

    return (
        <>
            <Container className="product-update">
                <Typography sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }} variant="h6">
                    제품 수정
                </Typography>

                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">Model</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="model_name" readOnly={true} value={model_name} sx={{ padding: '10px 26px 10px 12px', borderColor: 'primary.main' }} />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">Model S/N</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="model_serialnumber" className={serial_check ? "inputStyle" : "inputStyle check_error"} value={model_serialnumber} onChange={onChangeHandler} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">Slanted Angle</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_slantedangle" className="inputStyle" value={product_slantedangle} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">Offset</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_offset" className="inputStyle" value={product_offset} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">Spsize</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="product_spsize" className="inputStyle" value={product_spsize} onChange={onChangeHandler} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">제작일자</Grid>
                    <Grid item xs={7} sm={9} xl={10} className="inputBase-item">
                        <DatePicker className="inputStyle" readOnly={true} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => { obj_product.manufacture_date = date; setStartDate(date); }} customInput={<InputBase />} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">유효기간</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <InputBase name="valid_time_type" value={changeDateType(obj_checkvalid.expire_date)} sx={{ padding: '10px 26px 10px 12px', borderColor: 'primary.main' , fontSize: '14px'}} />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">유효기간 연장</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <Select name="valid_time" className="inputStyle" value={obj_changevalid.valid_time} onChange={onChangeValidHandler} displayEmpty input={<InputBase />}>
                            <MenuItem value="">
                                <em>기간을 선택해주세요</em>
                            </MenuItem>
                            {getValidTimeList().map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">Eyetracker</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <Select name="eyetracker_idx" className="inputStyle" value={list_eyetracker.length > 0 ? eyetracker_idx : ''} onChange={onChangeHandler} displayEmpty input={<InputBase />}>
                            {list_eyetracker?.map((row, idx) => (
                                <MenuItem key={idx} value={row.eyetracker_idx}>{row.eyetracker_name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">Eyetracker Policy</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <Select name="eyetracker_license_type_idx" className="inputStyle" value={list_eyetracker_license_type.length ? eyetracker_license_type_idx : ""} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            {list_eyetracker_license_type?.map((row, idx) => (
                                <MenuItem key={idx} value={row.license_type_idx}>{row.license_type_name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={5} sm={3} xl={2} className="label">고객사</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        <Select name='customer_id' className="inputStyle" value={list_customer.length > 0 ? customer_id : ''} onChange={onChangeHandler} displayEmpty input={<InputBase />}>
                            {list_customer?.map((row, idx) => (
                                <MenuItem key={idx} value={row.customer_id}>{row.customer}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">영업담당자</Grid>
                    <Grid item xs={7} sm={9} xl={4} className="inputBase-item">
                        {/* <InputBase name='product_manager' className="inputStyle" value={product_manager} onChange={onChangeHandler} /> */}
                        <Select name='product_manager' className="inputStyle" value={account_info.members.length > 0 ? product_manager : ''} onChange={onChangeHandler} displayEmpty input={<InputBase />} >
                            <MenuItem value="">
                                <em>담당자를 선택해주세요</em>
                            </MenuItem>
                            {account_info.members?.map((row, idx) => (
                                <MenuItem key={idx} value={getMembers(row.mail)}>{row.displayName}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} className="label">Note</Grid>
                    <Grid item xs={7} sm={9} xl={10} className="inputBase-item">
                        <InputBase
                            name="product_note" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', maxWidth: 815, minWidth: 240, background: '#fff' }}
                            value={product_note}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 2 }}>
                <Stack direction="row" sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {ShowValidatorUI()}
                    </div>

                    <div style={{ width: '100%', paddingTop: 15, display: 'flex', justifyContent: 'center' }}>
                        <Button component={Link} variant="contained" sx={{ minWidth: 100 }} to="" onClick={updateData}>수정</Button>
                    </div>
                </Stack>
            </Container>
            {/* <Button variant="contained" sx={{ minWidth: 100 }} to="" onClick={sendMail}>메일</Button> */}
        </>
    );
};
export default ProductUpdate;

