// base
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { useQueryClient } from 'react-query';
// mui
import { Card, CardContent, Typography, Button, Stack, Grid, Box, Container, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
// hook
import { DeprecateModelInfo, QUERYKEY_MODEL } from 'hooks/useModel';
import { MopicContext } from 'hooks/useContextManager';

import { DateFormat } from 'utils/Dateformat';

function createData(title, value) {
    return { title, value };
}

let spec = [];

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    '&:first-of-type': {
        fontWeight: '700'
    }
}));

const StyledSpecTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: '1px solid #ccc',
    }
}));

const ParamUI = (props) => {
    return (
        <>
            <Grid
                item xs={5} sm={4} xl={2} key={props.value.title}
                sx={{ p: 2, borderBottom: { borderBottom: '1px solid #ddd' }, fontWeight: 'bold' }}
            >
                {props.value.title}
            </Grid>
            <Grid
                item xs={7} sm={8} xl={4}
                sx={{ p: 2, borderBottom: { borderBottom: '1px solid #ddd' } }}
            >
                {props.value.value}
            </Grid>
        </>
    );
}

function specUI(props) {
    var ary = [];
    for (var i = 0; i < spec.length; i++)
    {
        if (typeof spec[i] == "string")
        {
            ary.push(
                <TableRow
                    key={i.toString()}
                    hover={true}
                >
                    <StyledSpecTableCell component="th" scope="col" align="left" colSpan="2"
                        sx={{
                            fontWeight: 'bold', color: '#000', background: '#eee', padding: '10px'
                        }}>{spec[i]}</StyledSpecTableCell>
                </TableRow>
            );
        }
        else if (i === spec.length - 1)
        {
            ary.push(
                <TableRow
                    key={i.toString()}
                    hover={true}
                >
                    <StyledSpecTableCell align="left" width="30%"
                        sx={{
                            fontWeight: 'bold', background: '#f7f7f7', padding: '5px',
                            color: '#444'
                        }}
                    >{spec[i].title}</StyledSpecTableCell>
                    <StyledSpecTableCell align="left"
                        sx={{
                            background: '#f7f7f7', padding: '5px',
                            color: '#444'
                        }}
                    >{spec[i].value}</StyledSpecTableCell>
                </TableRow>
            );
        }
        else
        {
            ary.push(
                <TableRow key={i.toString()}>
                    <StyledSpecTableCell align="left" width="300px"
                        sx={{
                            background: '#f7f7f7', padding: '5px',
                            color: '#444'
                        }}
                    >{spec[i].title}</StyledSpecTableCell>
                    <StyledSpecTableCell align="left"
                        sx={{
                            background: '#f7f7f7', padding: '5px',
                            color: '#444'
                        }}
                    >{spec[i].value}</StyledSpecTableCell>
                </TableRow>
            );
        }
    }
    return ary;
}

const ModelView = (props) => {
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
    const has_role_update = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_UPDATE");

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const onSuccessMutateModel = (data, variables, context) => {
        // console.log('Success Model Status Change');
        queryClient.invalidateQueries(QUERYKEY_MODEL['getModelInfo']);
        alert('단종처리되었습니다');
        navigate('/model/list');
    };

    const onErrorMutateModel = () => {
        console.log('Error Model Status Change');
    };

    const mutate_options_model = {
        'body': null,
        'callback_success': onSuccessMutateModel,
        'callback_error': onErrorMutateModel
    };

    const result_mutate_model = DeprecateModelInfo(mutate_options_model);

    const [bdeprecate, setDeprecate] = useState(false);
    const [obj_model, setModel] = useState();
    const [checkstate, setCheckState] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const data = location.state?.data;
        if (!checkstate)
        {
            if (data)
            {
                if (data.base?.status_idx === 3)
                {
                    setDeprecate(true);
                }
                setModel({
                    ...data
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_model])

    let basicInfo = [
        createData('모델명', obj_model?.model_name?.model_name),
        createData('해상도', obj_model?.model_name?.model_resolution),
        createData('화면사이즈', obj_model?.model_name?.model_screensize),
        createData('기본사양', obj_model?.model_name?.model_usecamera),
        createData('Grade', obj_model?.model_name?.model_grade),
        createData('상태', obj_model?.base?.status_name),
        createData('등록일', DateFormat(new Date(obj_model?.base?.model_released_date))),
        
    ];
    if(obj_model?.base?.model_type == 'Mobile') {
        basicInfo = [
            createData('모델명', obj_model?.model_name?.model_name),
            createData('제품', obj_model?.model_name?.model_m_model),
            
            createData('기본사양', obj_model?.model_name?.model_usecamera),
            createData('Grade', obj_model?.model_name?.model_grade),
            createData('상태', obj_model?.base?.status_name),
            createData('등록일', DateFormat(new Date(obj_model?.base?.model_released_date))),
        ]
    }

    spec = [
        'Display',
        createData('Screen Diagonal', obj_model?.spec?.screen_diagonal),
        createData('Input Resolution', obj_model?.spec?.input_resolution),
        createData('Aspect Ratio', obj_model?.spec?.aspect_ratio),
        createData('Refresh rate', obj_model?.spec?.refresh_rate),
        createData('Brightness(Typ)', obj_model?.spec?.brightness),
        createData('Color Support', obj_model?.spec?.color_support),
        createData('Contrast(Typ)', obj_model?.spec?.contrast),
        'Optics',
        createData('LightField Lens', obj_model?.spec?.lightfield_lens),
        createData('Crosstalk', obj_model?.spec?.crosstalk),
        createData('Optimal Viewing distance', obj_model?.spec?.optimal_viewing_distance),
        createData('Optimal viewing angle', obj_model?.spec?.optimal_viewing_angle),
        createData('Number of views', obj_model?.spec?.number_of_view),
        'Sensors',
        createData('Eye-tracking', obj_model?.spec?.eye_tracking),
        createData('Hand tracking', obj_model?.spec?.hand_tracking),
        'Connection',
        createData('video input', obj_model?.spec?.video_input),
        createData('Data interfacing', obj_model?.spec?.data_interface),
        'Power',
        createData('Type(internal/external)', obj_model?.spec?.power_adaptor_type),
        createData('Power Supply Type', obj_model?.spec?.power_supply_type),
        createData('Power Consumption(Max)', obj_model?.spec?.power_comsumption),
        'Dimensions',
        createData('Dimensions With Stand', obj_model?.spec?.dimension_with_stand),
        createData('Dimensions Without Stand', obj_model?.spec?.dimension_without_stand),
        createData('Shipping Dimensions', obj_model?.spec?.dimension_shipping),
        'Weight',
        createData('Weight With Stand', obj_model?.spec?.weight_with_stand),
        createData('Weight Without Stand', obj_model?.spec?.weight_without_stand),
        createData('Shipping Weight', obj_model?.spec?.weight_shipping),
        'Minimum PC requirements',
        createData('CPU', obj_model?.spec?.pcspec_cpu),
        createData('GPU', obj_model?.spec?.pcspec_gpu),
        createData('I/O', obj_model?.spec?.pcspec_io),
        'What`s in the box',
        createData('HDMI Cable', obj_model?.spec?.has_hdmi),
        createData('Display Port Cable', obj_model?.spec?.has_dp),
        createData('USB Cable', obj_model?.spec?.has_usb),
        createData('Power Cable', obj_model?.spec?.has_powercable),
        createData('Setup Guide', obj_model?.spec?.has_setupguide),
        createData('Remote Mouse', obj_model?.spec?.has_remotemouse),
        createData('3D Box', obj_model?.spec?.has_3dbox),
        createData('Warranty & Support', obj_model?.spec?.warrenty_date)
    ];

    const parameter = [
        createData('MonitorSet', obj_model?.base?.component_monitor_name),
        createData('LCM Model', obj_model?.base?.component_lcm_name),
        createData('OpenCell Model', obj_model?.base?.component_opencell_name),
        createData('Camera Model', obj_model?.base?.component_camera_name),
        createData('3D box Model', obj_model?.base?.component_3dbox_name),
        createData('Lenticular Lens', obj_model?.base?.component_lens_name),
        createData('Motion Sensor', obj_model?.base?.component_motionsensor_name),
        createData('Theta', obj_model?.base?.theta),
        createData('Eye', obj_model?.base?.eye),
        createData('Zstd', obj_model?.base?.zstd),
        createData('k', obj_model?.base?.k),
        createData('LP', obj_model?.base?.lp),
        createData('GN', obj_model?.base?.gn),
        createData('SPSize', obj_model?.base?.spsize),
        createData('Dx', obj_model?.base?.dx),
        createData('Dy', obj_model?.base?.dy),
        createData('Dz', obj_model?.base?.dz),
        createData('Mx', obj_model?.base?.mx),
        createData('Adh', obj_model?.base?.adh),
        createData('Adv', obj_model?.base?.adv),
        createData('TN', obj_model?.base?.tn),
        createData('Repeat Angle', obj_model?.base?.repeat_angle),
        createData('Color', obj_model?.base?.color),
        createData('Orientation', obj_model?.base?.orientation),
        createData('Resolution', obj_model?.base?.resolution),
        createData('Type', obj_model?.base?.model_type),
        createData('Note', obj_model?.base?.model_note),
    ];

    /////////////////////////// Confirm 처리용
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function")
        {
            return;
        }
        if (onCancel && typeof onCancel !== "function")
        {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message))
            {
                onConfirm();
            }
            else
            {
                onCancel();
            }
        }

        return confirmAction;
    };

    const [deprecatemodel, setDeprecateModel] = useState({
        model_name: '',
        changed_user: ''
    });

    const deprecateConfirm = () => {
        if (has_role_write)
        {
            setDeprecate(true);
            deprecatemodel.changed_user = account_info.getAccount();
            deprecatemodel.model_name = obj_model.base.model_name;
            mutate_options_model.body = deprecatemodel;
            result_mutate_model.mutate();
        }
        else
        {
            alert('단종 권한이 없습니다');
        }
    };

    const cancelConfirm = () => alert('취소되었습니다');
    const confirmDepreacte = useConfirm(
        '단종처리하시겠습니까?',
        deprecateConfirm,
        cancelConfirm
    );

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold' }}
                    variant="h6"
                >
                    모델 정보
                </Typography>

                <Card {...props}>
                    <CardContent>
                        <Container sx={{ m: 0, p: 0, display: 'flex', flexWrap: 'wrap' }}>
                            <TableContainer component={Box} sx={{}}>
                                <Table sx={{}} aria-label="Spec">
                                    <TableBody>
                                        {basicInfo.map((row, idx) => (
                                            <TableRow
                                                key={idx}
                                                hover={true}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="col" align="left" width="200px">{row.title}</StyledTableCell>
                                                <StyledTableCell align="left">{row.value}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </CardContent>
                </Card>
            </Container>
            <Container sx={{ m: 0, mt: 10 }}>
                <Typography
                    sx={{ m: 0, mb: 1, fontWeight: 'bold' }}
                    variant="h6"
                >
                    Parameters
                </Typography>

                <Container sx={{ m: 0, mt: 2, p: 0 }}>
                    <Grid container sx={{ fontSize: 14 }}>
                        {
                            parameter.map((row, idx) => (
                                <ParamUI key={idx} value={row}></ParamUI>
                            ))
                        }
                    </Grid>
                </Container>
            </Container>
            <Container sx={{ m: 0, mt: 10 }}>
                <Typography
                    sx={{ m: 0, mb: 1, fontWeight: 'bold' }}
                    variant="h6"
                >
                    Specification
                </Typography>

                <Container sx={{ m: 0, mt: 2, p: 0 }}>
                    <TableContainer component={Box} sx={{}}>
                        <Table sx={{}} aria-label="Spec">
                            <TableBody>
                                {specUI(obj_model)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Container>
            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="/model/list">리스트</Button>
                    {(has_role_write || has_role_update) ?
                        <Button component={Link} variant="contained" to="/model/update" state={{ base: obj_model?.base, spec: obj_model?.spec, name: obj_model?.model_name }}>수정</Button>
                        : null}
                    {/* <Button variant="contained" onClick={() => { console.log('delete'); }}>삭제</Button> */}
                    {(has_role_write) ? (
                        (bdeprecate) ? null : <Button variant="contained" color="warning" onClick={confirmDepreacte}>단종</Button>
                    ) : null}
                </Stack>
            </Container>
        </>
    );
};
export default ModelView;
