import { UseQueryKey, FetchGET, FetchPOST, FetchDELETE, FetchPUT } from './useFetch'
import { axiosGet } from './useAxios';

import { URL_API_LIST, URI_QUERY_LIST } from './useMopicAPIConfig.js'

export const QUERYKEY_MODEL = {
    getModelInfo: UseQueryKey("GET", "modelinfo"),
    getModelNameList: UseQueryKey("GET", "modelname"),
    getUsableModelNameList: UseQueryKey("GET", "usablemodelname"),
    getUnusedModelNameList: UseQueryKey("GET", "unusedmodelname"),
    addModel: UseQueryKey("POST", "model"),
    updateModelInfo: UseQueryKey("POST", "model"),
    deprecateModelInfo: UseQueryKey("DELETE", "deprecatemodel")
};

export const getModelInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['getModelInfo'];

    let req_url = URL_API_LIST['API_MODEL_INFORMATION'];

    let str_query = "";
    if (objOptions?.keys)
    {
        const keys = objOptions?.keys;

        str_query += "?";

        if (keys.model_name)
        {
            str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELNAME'] + "=" + encodeURIComponent(keys.model_name) + "&";
        }

        str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELHISTORY'] + "=latest";
    }

    if (str_query)
    {
        req_url += str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}
export const axiosGetModeInfo = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_MODEL_INFORMATION'];
    let str_query = "";
    let keys = descriptor?.keys;
    if (keys)
    {
        str_query += "?";

        if (keys.model_name)
            str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELNAME'] + "=" + encodeURIComponent(keys.model_name) + "&";

        str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELHISTORY'] + "=latest";
    }

    if (str_query)
        req_url += str_query;

    await axiosGet(req_url, descriptor);
};

export const getModelNameList = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['getModelNameList'];

    let req_url = URL_API_LIST['API_MODEL_LIST'];

    let str_query = "?";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_COLUMNNAME'] + "=modelname&";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELSTATUS'] + "=none";

    if (str_query)
    {
        req_url += str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}

export const getUsableModelNameList = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['getUsableModelNameList'];

    let req_url = URL_API_LIST['API_MODEL_LIST'];

    let str_query = "?";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_COLUMNNAME'] + "=modelname&";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELSTATUS'] + "=usable";

    if (str_query)
    {
        req_url += str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}

export const getUnusedModelNameList = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['getUnusedModelNameList'];

    let req_url = URL_API_LIST['API_MODEL_LIST'];

    let str_query = "?";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_COLUMNNAME'] + "=modelname&";
    str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELSTATUS'] + "=unused";

    if (str_query)
    {
        req_url += str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}

export const addModel = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['addModel'];

    let req_url = URL_API_LIST['API_MODEL_INFORMATION'];

    return FetchPOST(req_url, querykey, objOptions);
}

export const updateModelInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['updateModelInfo'];

    let req_url = URL_API_LIST['API_MODEL_INFORMATION'];

    return FetchPUT(req_url, querykey, objOptions);
}

export const DeprecateModelInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_MODEL['deprecateModelInfo'];
    let req_url = URL_API_LIST['API_MODEL_INFORMATION'];

    return FetchDELETE(req_url, querykey, objOptions);
}

// local list
// TODO : DB화 진행
export const getModelTypeList = () => {
    return [
        { type_name: "Signage" },
        { type_name: "Personal S." },
    ]
};
export const getMonitorList = () => {
    return [
        { monitor_name: "미사용" },
        { monitor_name: "AIO265" },
        { monitor_name: "U32J590UQK" },
    ]
};
export const getLCMList = () => {
    return [
        { lcm_name: "미사용" },
    ]
}
export const getOpencellList = () => {
    return [
        { opencell_name: "미사용" },
    ]
}
export const getCameraList = () => {
    return [
        { camera_name: "미사용" },
        { camera_name: "D435" },
        { camera_name: "AF926H" },
    ]
}
export const get3DBoxList = () => {
    return [
        { box3d_name: "미사용" },
        { box3d_name: "EM3399" },
        { box3d_name: "VIM3 Basic" },
    ]
}
export const getLensList = () => {
    return [
        { lens_name: "260" },
        { lens_name: "270" },
        { lens_name: "280" },
    ]
}
