import { Button, ThemeProvider } from '@mui/material';
import { theme_buttom } from 'view/common/Theme';

const MPButton = (props) => {
    const data = props.rowdata;

    return (
        <ThemeProvider theme={theme_buttom}>
            <Button
                variant={props.variant}
                onClick={(e) => {
                    if (props.callbackfn)
                    {
                        if (data)
                        {
                            props.callbackfn.call(this, data);
                        }
                        else
                        {
                            props.callbackfn.call(this);
                        }
                    }
                }}
            >
                {props.text}
            </Button>
        </ThemeProvider>
    );
}

export const MPStatusButton = (props) => {
    const data = props.rowdata;

    var theme_color;

    switch (props.status_idx)
    {
        case 1: theme_color = "stock"; break;
        case 2: theme_color = "prerelease"; break;
        case 3: theme_color = "release"; break;
        case 4: theme_color = "recall"; break;
        case 5: theme_color = "defective"; break;
        case 6: theme_color = "dispose"; break;
        case 7: theme_color = "rental"; break;
        case 8: theme_color = "inneruse"; break;
        default: theme_color = "stock"; break;
    }

    return (
        <ThemeProvider theme={theme_buttom}>
            <Button
                variant={props.variant}
                color={theme_color}
                onClick={(e) => {
                    if (props.callbackfn)
                    {
                        if (data)
                        {
                            props.callbackfn.call(this, data);
                        }
                        else
                        {
                            props.callbackfn.call(this);
                        }
                    }
                }}
            >
                {props.text}
            </Button>
        </ThemeProvider>
    );
}

export default MPButton;