import React from "react";
import { InputLabel } from "@mui/material";

const MPLabel = (props) => {
    const options = props.options;

    const product_serialnumber = props.sn || "";
    const product_model = props.model || "";

    if (options.label_type == "stock") {
        return (
            <>
                <InputLabel sx={{ mt: 2 }}>Model Name: {options.model_name}</InputLabel>
                <InputLabel sx={{ mt: 2 }}>Rating: {options.rating}</InputLabel>
                <InputLabel sx={{ mt: 2 }}>Manufactured : {options.manufactured}</InputLabel>
                <InputLabel sx={{ mt: 2 }}>S / N : {options.serialnumber}</InputLabel>
            </>
        );
    } else if (options.label_type == "release") {
        return (
            <>
                <InputLabel sx={{ mt: 2 }}>Model Name: {options.model_name}</InputLabel>
                <InputLabel sx={{ mt: 2 }}>Customer: {options.customer_company}</InputLabel>
                <InputLabel sx={{ mt: 2 }}>S / N : {options.serialnumber}</InputLabel>
            </>
        );
    }

}

export default MPLabel;