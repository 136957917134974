export const DateFormat = (date) => {
    if (date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date)) {
        // let datetime = date.getTime();
        // let offsettime = new Date().getTimezoneOffset() * 60000;
        //date = new Date(datetime + offsettime);
        //const calc = new Date(timestamp - offset);
        let month = date.getMonth() + 1;
        let day = date.getDate();
        //let hour = date.getHours();
        //let minute = date.getMinutes();
        //let second = date.getSeconds();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        //hour = hour >= 10 ? hour : '0' + hour;
        //minute = minute >= 10 ? minute : '0' + minute;
        //second = second >= 10 ? second : '0' + second;

        return date.getFullYear().toString() + '/' + month + '/' + day;
    } else {
        return '';
    }
};

export const LabelDateFormat = (date) => {
    let arrmonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let month = arrmonth[date.getMonth()];
    let year = date.getFullYear();

    return month + ' ' + year;
};
