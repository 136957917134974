// base
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useQueryClient } from 'react-query';
// mui
import { Grid, Container, Button, Stack, FormControl, InputBase, InputLabel, MenuItem, Select, Typography } from '@mui/material';
// hook
import { updateModelInfo, QUERYKEY_MODEL } from 'hooks/useModel';
import useModelComponent from 'hooks/useModelComponent';
import { MopicContext } from 'hooks/useContextManager';
import { GetAxiosGetDescriptior } from 'hooks/useAxios';

import { DateFormat } from 'utils/Dateformat';

const ModelUpdate = (props) => {
    // account info
    const account_info = useContext(MopicContext).account_info;
    const has_role_write = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_WRITE");
    const has_role_update = account_info.hasRole("MODEL_ALL") || account_info.hasRole("MODEL_UPDATE");

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const onSuccessMutateModel = (data, variables, context) => {
        // console.log('Success Update Model');
        queryClient.invalidateQueries(QUERYKEY_MODEL['getModelInfo']);
        alert('수정되었습니다');
        navigate('/model/list');
    };

    const onErrorMutateModel = () => {
        console.log('callback Error [onErrorMutateModel]');
    };

    const mutate_options_model = {
        'body': null,
        'callback_success': onSuccessMutateModel,
        'callback_error': onErrorMutateModel
    };

    const result_mutate_model = updateModelInfo(mutate_options_model);

    const ModelInfo = {
        base: {
            model_name: '',
            model_type: '',
            model_released_date: '',
            model_deprecated_date: null,
            model_note: '',
            component_monitor_name: '',
            component_lcm_name: '',
            component_opencell_name: '',
            component_camera_name: '',
            component_3dbox_name: '',
            component_lens_name: '',
            component_motionsensor_name: '',
            theta: '',
            eye: '',
            zstd: '',
            k: '',
            lp: '',
            gn: '',
            spsize: '',
            dx: '',
            dy: '',
            dz: '',
            mx: '',
            adh: '',
            adv: '',
            tn: '',
            repeat_angle: '',
            color: '',
            orientation: '',
            resolution: '',
            changed_user: '',
            changed_date: '',
            changed_message: ''
        },
        spec: {
            screen_diagonal: '',
            input_resolution: '',
            aspect_ratio: '',
            refresh_rate: '',
            brightness: '',
            color_support: '',
            contrast: '',
            lightfield_lens: '',
            crosstalk: '',
            optimal_viewing_distance: '',
            optimal_viewing_angle: '',
            number_of_view: '',
            eye_tracking: '',
            hand_tracking: '',
            video_input: '',
            data_interface: '',
            power_adaptor_type: '',
            power_supply_type: '',
            power_comsumption: '',
            dimension_with_stand: '',
            dimension_without_stand: '',
            dimension_shipping: '',
            weight_with_stand: '',
            weight_without_stand: '',
            weight_shipping: '',
            pcspec_cpu: '',
            pcspec_gpu: '',
            pcspec_io: '',
            has_hdmi: '',
            has_dp: '',
            has_usb: '',
            has_powercable: '',
            has_setupguide: '',
            has_remotemouse: '',
            has_3dbox: '',
            warrenty_date: ''
        }
    }

    const [obj_model, setModel] = useState(ModelInfo);

    ////////////////////////////////// Model Base 처리
    const ModelBaseInfo = {
        model_name: '',
        model_type: '',
        model_released_date: DateFormat(new Date()),
        model_deprecated_date: null,
        model_note: '',
        component_monitor_name: '',
        component_lcm_name: '',
        component_opencell_name: '',
        component_camera_name: '',
        component_3dbox_name: '',
        component_lens_name: '',
        component_motionsensor_name: '',
        theta: '',
        eye: '',
        zstd: '',
        k: '',
        lp: '',
        gn: '',
        spsize: '',
        dx: '',
        dy: '',
        dz: '',
        mx: '',
        adh: '',
        adv: '',
        tn: '',
        repeat_angle: '',
        color: '',
        orientation: '',
        resolution: '',
        changed_user: '',
        changed_date: DateFormat(new Date()),
        changed_message: ''
    };

    const [obj_basemodel, setBaseModel] = useState(ModelBaseInfo);
    const { model_name, model_type, model_released_date, model_deprecated_date, model_note, component_monitor_name, component_lcm_name, component_opencell_name, component_camera_name, component_3dbox_name, component_lens_name, component_motionsensor_name, theta, eye, zstd, k, lp, gn, spsize, dx, dy, dz, mx, adh, adv, tn, repeat_angle, color, orientation, resolution, changed_user, changed_date, changed_message } = obj_basemodel;

    // validator
    const onParameterItemChange = (e) => {
        let monitor, lcm, opencell, camera, lens, box;
        const { value, name } = e.target;
        switch (name)
        {
            case "component_monitor_name":
                monitor = monitor_list.find(item => item.value === value);
                lcm = lcm_list.find(item => item.value === obj_basemodel.component_lcm_name);
                opencell = opencell_list.find(item => item.value === obj_basemodel.component_opencell_name);

                if (monitor)
                {
                    let spsize = '';
                    if (monitor.value !== '미사용')
                    {
                        spsize = monitor.spsize;
                    }
                    else
                    {
                        if (lcm && lcm.value !== '미사용')
                        {
                            spsize = lcm.spsize;
                        }
                        else if (opencell && opencell.value !== '미사용')
                        {
                            spsize = opencell.spsize;
                        }
                        else
                        {
                            spsize = '';
                        }
                    }

                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value,
                        "spsize": spsize
                    })
                }
                break;
            case "component_lcm_name":
                monitor = monitor_list.find(item => item.value === obj_basemodel.component_monitor_name);
                lcm = lcm_list.find(item => item.value === value);
                opencell = opencell_list.find(item => item.value === obj_basemodel.component_opencell_name);

                if (lcm)
                {
                    let spsize = '';
                    if (lcm.value !== '미사용')
                    {
                        spsize = lcm.spsize;
                    }
                    else
                    {
                        if (monitor && monitor.value !== '미사용')
                        {
                            spsize = monitor.spsize;
                        }
                        else if (opencell && opencell.value !== '미사용')
                        {
                            spsize = opencell.spsize;
                        }
                        else
                        {
                            spsize = '';
                        }
                    }

                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value,
                        "spsize": spsize
                    })
                }
                break;
            case "component_opencell_name":
                monitor = monitor_list.find(item => item.value === obj_basemodel.component_monitor_name);
                lcm = lcm_list.find(item => item.value === obj_basemodel.component_lcm_name);
                opencell = opencell_list.find(item => item.value === value);

                if (opencell)
                {
                    let spsize = '';
                    if (opencell.value !== '미사용')
                    {
                        spsize = opencell.spsize;
                    }
                    else
                    {
                        if (monitor && monitor.value !== '미사용')
                        {
                            spsize = monitor.spsize;
                        }
                        else if (lcm && lcm.value !== '미사용')
                        {
                            spsize = lcm.spsize;
                        }
                        else
                        {
                            spsize = '';
                        }
                    }

                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value,
                        "spsize": spsize
                    })
                }
                break;
            case "component_camera_name":
                camera = camera_list.find(item => item.value === value);
                if (camera)
                {
                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value,
                        "k": camera.k
                    })
                }
                break;
            case "component_lens_name":
                lens = lens_list.find(item => item.value === value);
                if (lens)
                {
                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value,
                        "lp": lens.lp
                    })
                }
                break;
            case "component_3dbox_name":
                box = box3d_list.find(item => item.value === value);
                if (box)
                {
                    setBaseModel({
                        ...obj_basemodel,
                        [name]: value
                    })
                }
                break;
            default:
                setBaseModel({
                    ...obj_basemodel,
                    [name]: value
                })
                break;
        }
    };

    ////////////////////////////// Model Spec 처리
    const ModelSpecInfo = {
        screen_diagonal: '',
        input_resolution: '',
        aspect_ratio: '',
        refresh_rate: '',
        brightness: '',
        color_support: '',
        contrast: '',
        lightfield_lens: '',
        crosstalk: '',
        optimal_viewing_distance: '',
        optimal_viewing_angle: '',
        number_of_view: '',
        eye_tracking: '',
        hand_tracking: '',
        video_input: '',
        data_interface: '',
        power_adaptor_type: '',
        power_supply_type: '',
        power_comsumption: '',
        dimension_with_stand: '',
        dimension_without_stand: '',
        dimension_shipping: '',
        weight_with_stand: '',
        weight_without_stand: '',
        weight_shipping: '',
        pcspec_cpu: '',
        pcspec_gpu: '',
        pcspec_io: '',
        has_hdmi: '',
        has_dp: '',
        has_usb: '',
        has_powercable: '',
        has_setupguide: '',
        has_remotemouse: '',
        has_3dbox: '',
        warrenty_date: ''
    }

    const [obj_specmodel, setSpecModel] = useState(ModelSpecInfo);
    const { screen_diagonal, input_resolution, aspect_ratio, refresh_rate, brightness, color_support, contrast, lightfield_lens, crosstalk, optimal_viewing_distance, optimal_viewing_angle, number_of_view, eye_tracking, hand_tracking, video_input, data_interface, power_adaptor_type, power_supply_type, power_comsumption, dimension_with_stand, dimension_without_stand, dimension_shipping, weight_with_stand, weight_without_stand, weight_shipping, pcspec_cpu, pcspec_gpu, pcspec_io, has_hdmi, has_dp, has_usb, has_powercable, has_setupguide, has_remotemouse, has_3dbox, warrenty_date } = obj_specmodel;

    const onChangeSpecHandler = (e) => {
        const { value, name } = e.target;
        setSpecModel({
            ...obj_specmodel,
            [name]: value
        })
    };

    ///////////////////////////////////// modelname 처리용
    const ModelNameInfo = {
        name_model_name: '',
        name_resolution: '',
        name_screensize: '',
        name_ver1: '',
        name_camera: '',
        name_grade: '',
        name_ver2: ''
    }

    const [obj_modelname, setModelName] = useState(ModelNameInfo);
    const { name_model_name, model_resolution, model_screensize, model_ver1, model_usecamera, model_grade, model_ver2 } = obj_modelname;

    const onChangeNameHandler = (e) => {
        const { value, name } = e.target;
        setModelName({
            ...obj_modelname,
            [name]: value
        })
    };

    const [checkstate, setCheckState] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const base = location.state?.base;
        const spec = location.state?.spec;
        const name = location.state?.name;
        if (!checkstate)
        {
            if (base)
            {
                setBaseModel({
                    ...base
                })
            }
            if (spec)
            {
                setSpecModel({
                    ...spec
                })
            }
            if (name)
            {
                setModelName({
                    ...name
                })
                setCheckState(!checkstate);
            }
        }
    }, [checkstate, obj_basemodel, obj_specmodel, obj_modelname])

    /////////////////////////// Confirm 처리용
    const useConfirm = (message = null, onConfirm, onCancel) => {
        if (!onConfirm || typeof onConfirm !== "function")
        {
            return;
        }
        if (onCancel && typeof onCancel !== "function")
        {
            return;
        }

        const confirmAction = () => {
            if (window.confirm(message))
            {
                onConfirm();
            }
            else
            {
                onCancel();
            }
        }

        return confirmAction;
    };

    const registConfirm = () => {
        if (has_role_write || has_role_update) 
        {
            obj_basemodel.model_name = obj_modelname.model_name;
            obj_basemodel.model_released_date = new Date();
            obj_basemodel.changed_date = new Date();
            obj_basemodel.changed_user = account_info.getAccount();

            let valid_model_data = (obj_basemodel.model_name.length === 7 && model_type !== '');
            //let valid_parameter_data = true;
            let valid_user_data = obj_basemodel.changed_user !== null;

            if (valid_model_data && valid_user_data)
            {
                obj_model['base'] = obj_basemodel;
                obj_model['spec'] = obj_specmodel;
                mutate_options_model.body = obj_model;
                result_mutate_model.mutate();
            }
            else 
            {
                alert('필수값을 입력해주세요');
            }
        }
        else
        {
            alert('모델 수정 권한이 없습니다');
        }

    };

    const cancelConfirm = () => alert('취소되었습니다');
    const confirmUpdate = useConfirm(
        '수정하시겠습니까?',
        registConfirm,
        cancelConfirm
    );

    // model component list
    const [is_loaded_camera_data, loadCompleteCameraData] = useState(false);
    const [is_loaded_lens_data, loadCompleteLensData] = useState(false);
    const [is_loaded_displayset_data, loadCompleteDisplaySetData] = useState(false);
    const [is_loaded_3dbox_data, loadComplete3DBoxData] = useState(false);

    const [monitor_list, setMonitorList] = useState([]);
    const [lcm_list, setLCMList] = useState([]);
    const [opencell_list, setOpenCellList] = useState([]);
    const [camera_list, setCameraList] = useState([]);
    const [lens_list, setLensList] = useState([]);
    const [box3d_list, setBox3DList] = useState([]);

    const onSuccessGetCameraInfo = (data) => {
        let temp_list = [];
        for (let i in data)
        {
            let row = data[i];
            temp_list.push({
                idx: i,
                value: row.camera_name,
                text: row.camera_name,
                k: row.camera_param_k
            })
        }
        setCameraList(temp_list);

        loadCompleteCameraData(true);
    };
    const onSuccessGetLensInfo = (data) => {
        let temp_list = [];
        for (let i in data)
        {
            let row = data[i];
            temp_list.push({
                idx: i,
                value: row.lens_name,
                text: row.lens_name,
                lp: row.lens_param_lp
            })
        }
        setLensList(temp_list);

        loadCompleteLensData(true);
    };
    const onSuccessGet3DBoxInfo = (data) => {
        let temp_list = [];
        for (let i in data)
        {
            let row = data[i];
            temp_list.push({
                idx: i,
                value: row.box_name,
                text: row.box_name
            })
        }
        setBox3DList(temp_list);

        loadComplete3DBoxData(true);
    };
    const onSuccessGetDisplaySetInfo = (data) => {
        let temp_monitor_list = [];
        let temp_lcm_list = [];
        let temp_opencell_list = [];
        for (let i in data)
        {
            let row = data[i];
            let item = {
                idx: i,
                value: row.represent_name,
                text: row.represent_name,
                spsize: row.display_param_spsize
            }
            switch (row.display_type)
            {
                case "MONITOR":
                    temp_monitor_list.push(item);
                    break;
                case "LCM":
                    temp_lcm_list.push(item);
                    break;
                case "OPENCELL":
                    temp_opencell_list.push(item);
                    break;
                default:
                    break;
            }
        }

        setMonitorList(temp_monitor_list);
        setLCMList(temp_lcm_list);
        setOpenCellList(temp_opencell_list);
        loadCompleteDisplaySetData(true);
    };
    const onErrorGetData = (data) => {
        console.log("onErrorGetData");
    };

    const getCameraData = useCallback(() => {
        if (is_loaded_camera_data === true)
            return;

        let axiosDescriptorCameraData = GetAxiosGetDescriptior(onSuccessGetCameraInfo, onErrorGetData);
        useModelComponent.axiosGetUsableCameraInfo(axiosDescriptorCameraData);
    }, [is_loaded_camera_data]);
    const getLensData = useCallback(() => {
        if (is_loaded_lens_data === true)
            return;

        let axiosDescriptorLensData = GetAxiosGetDescriptior(onSuccessGetLensInfo, onErrorGetData);
        useModelComponent.axiosGetUsableLensInfo(axiosDescriptorLensData);
    }, [is_loaded_lens_data]);
    const get3DBoxData = useCallback(() => {
        if (is_loaded_3dbox_data === true)
            return;

        let axiosDescriptor3DBoxData = GetAxiosGetDescriptior(onSuccessGet3DBoxInfo, onErrorGetData);
        useModelComponent.axiosGetUsable3DBoxInfo(axiosDescriptor3DBoxData);
    }, [is_loaded_3dbox_data]);
    const getDisplaySetData = useCallback(() => {
        if (is_loaded_displayset_data === true)
            return;

        let axiosDescriptorDisplaySetData = GetAxiosGetDescriptior(onSuccessGetDisplaySetInfo, onErrorGetData);
        useModelComponent.axiosGetUsableDisplaySetInfo(axiosDescriptorDisplaySetData);
    }, [is_loaded_displayset_data]);

    useEffect(() => {
        getCameraData();
        getLensData();
        get3DBoxData();
        getDisplaySetData();
    }, [getCameraData, getLensData, get3DBoxData, getDisplaySetData]);

    // styles
    const input_data_style = { m: 0, minWidth: 240 };
    const input_label_style = { color: 'rgba(0, 0, 0, 0.87)' };

    return (
        <>
            <Container sx={{ m: 0 }}>
                <Typography
                    sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }}
                    variant="h6"
                >
                    모델 수정
                </Typography>
                <Grid container sx={{ fontSize: 28 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>모델명</Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2 }}>
                        {model_name}
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 7 }}>
                <Typography sx={{ m: 0, mb: 2, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }} variant="h6">
                    Parameter
                </Typography>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>MonitorSet *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_monitor_name' value={component_monitor_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {monitor_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>LCM Model *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_lcm_name' value={component_lcm_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {lcm_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>OpenCell Model *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_opencell_name' value={component_opencell_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {opencell_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>Camera Model *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_camera_name' value={component_camera_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {camera_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>3D Box Model *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_3dbox_name' value={component_3dbox_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {box3d_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>Lenticular Lens *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_lens_name' value={component_lens_name} onChange={onParameterItemChange} displayEmpty input={<InputBase />} className="inputStyle">
                            {lens_list.map((row, idx) => (
                                <MenuItem key={idx} value={row.value}>{row.text}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-motion" sx={input_label_style}>Motion Sensor *</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <Select sx={input_data_style} name='component_motionsensor_name' value={component_motionsensor_name} onChange={onParameterItemChange} input={<InputBase />} className="inputStyle">
                            <MenuItem key={0} value={"미사용"}>{"미사용"}</MenuItem>
                            <MenuItem key={1} value={"Leap Motion Controller"}>Leap Motion Controller</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-theta" sx={input_label_style}>Theta</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='theta' value={theta} onChange={onParameterItemChange} id="textbox-theta" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-eye" sx={input_label_style}>Eye</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='eye' value={eye} onChange={onParameterItemChange} id="textbox-eye" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-zstd" sx={input_label_style}>Zstd</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='zstd' value={zstd} onChange={onParameterItemChange} id="textbox-zstd" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-k" sx={input_label_style}>K</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='k' value={k} onChange={onParameterItemChange} id="textbox-k" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-lp" sx={input_label_style}>LP</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='lp' value={lp} onChange={onParameterItemChange} id="textbox-lp" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-gn" sx={input_label_style}>GN</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='gn' value={gn} onChange={onParameterItemChange} id="textbox-gn" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-spsize" sx={input_label_style}>SPSize</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='spsize' value={spsize} onChange={onParameterItemChange} id="textbox-spsize" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-dx" sx={input_label_style}>Dx</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='dx' value={dx} onChange={onParameterItemChange} id="textbox-dx" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-dy" sx={input_label_style}>Dy</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='dy' value={dy} onChange={onParameterItemChange} id="textbox-dy" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-dz" sx={input_label_style}>Dz</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='dz' value={dz} onChange={onParameterItemChange} id="textbox-dz" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-mx" sx={input_label_style}>Mx</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='mx' value={mx} onChange={onParameterItemChange} id="textbox-mx" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-adh" sx={input_label_style}>Adh</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='adh' value={adh} onChange={onParameterItemChange} id="textbox-adh" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-adv" sx={input_label_style}>Adv</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='adv' value={adv} onChange={onParameterItemChange} id="textbox-adv" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-tn" sx={input_label_style}>TN</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='tn' value={tn} onChange={onParameterItemChange} id="textbox-tn" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-ra" sx={input_label_style}>Repeat Angle</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='repeat_angle' value={repeat_angle} onChange={onParameterItemChange} id="textbox-ra" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-color" sx={input_label_style}>Color</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='color' value={color} onChange={onParameterItemChange} id="textbox-color" className="inputStyle" />
                    </Grid>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-orientation" sx={input_label_style}>Orientation</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='orientation' value={orientation} onChange={onParameterItemChange} id="textbox-orientation" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2 }}>
                        <InputLabel htmlFor="textbox-resolution" sx={input_label_style}>Resolution</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={4} sx={{ p: 2, py: '4px' }}>
                        <InputBase sx={input_data_style} name='resolution' value={resolution} onChange={onParameterItemChange} id="textbox-resolution" className="inputStyle" />
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={3} xl={2} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-note" sx={input_label_style}>Note</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={9} xl={10} sx={{ p: 2, py: '4px' }}>

                        <InputBase
                            name='model_note'
                            id="outlined-multiline-static" className="inputStyle"
                            multiline
                            rows={4} sx={{ width: '100%', background: '#fff' }}
                            value={model_note}
                            onChange={onParameterItemChange}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ m: 0, mt: 7 }}>
                <Typography
                    sx={{ m: 0, mb: 4, fontWeight: 'bold', borderBottom: '2px solid #333', display: 'inline-block', minWidth: '200px' }}
                    variant="h6"
                >
                    Specification
                </Typography>

                <Typography sx={{ m: 0, mb: 2, fontWeight: 'bold', display: 'block' }}>Display</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-sd" sx={{ color: 'rgba(0, 0, 0, 0.87)', width: '100%' }} >Screen Diagonal</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='screen_diagonal' value={screen_diagonal} onChange={onChangeSpecHandler} id="textbox-sd" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ir" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Input Resolution</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='input_resolution' value={input_resolution || ''} onChange={onChangeSpecHandler} id="textbox-ir" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ar" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Aspect Ratio</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='aspect_ratio' value={aspect_ratio} onChange={onChangeSpecHandler} id="textbox-ar" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-rr" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Refresh rate</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='refresh_rate' value={refresh_rate} onChange={onChangeSpecHandler} id="textbox-rr" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-brightness" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Brightness(Typ)</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='brightness' value={brightness} onChange={onChangeSpecHandler} id="textbox-brightness" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-cs" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Color Support</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='color_support' value={color_support} onChange={onChangeSpecHandler} id="textbox-cs" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-contrast" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Contrast(Typ)</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='contrast' value={contrast} onChange={onChangeSpecHandler} id="textbox-contrast" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>



                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Optics</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ll" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>LightField Lens</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='lightfield_lens' value={lightfield_lens} onChange={onChangeSpecHandler} id="textbox-ll" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-crosstalk" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Crosstalk</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='crosstalk' value={crosstalk} onChange={onChangeSpecHandler} id="textbox-crosstalk" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ovd" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Optimal Viewing distance</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='optimal_viewing_distance' value={optimal_viewing_distance} onChange={onChangeSpecHandler} id="textbox-ovd" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ova" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Optimal viewing angle</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='optimal_viewing_angle' value={optimal_viewing_angle} onChange={onChangeSpecHandler} id="textbox-ova" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-nov" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Number of views</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='number_of_view' value={number_of_view} onChange={onChangeSpecHandler} id="textbox-nov" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>



                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Sensors</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-eyetracking" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Eye-tracking</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='eye_tracking' value={eye_tracking} onChange={onChangeSpecHandler} id="textbox-eyetracking" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ht" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Hand tracking</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='hand_tracking' value={hand_tracking} onChange={onChangeSpecHandler} id="textbox-ht" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Connection</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-videoinput" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Video input</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='video_input' value={video_input} onChange={onChangeSpecHandler} id="textbox-videoinput" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-di" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Data interfacing</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='data_interface' value={data_interface} onChange={onChangeSpecHandler} id="textbox-di" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Power</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-type" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Type(internal/external)</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='power_adaptor_type' value={power_adaptor_type} onChange={onChangeSpecHandler} id="textbox-type" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-pst" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Power Supply Type</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='power_supply_type' value={power_supply_type} onChange={onChangeSpecHandler} id="textbox-pst" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-pc" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Power Consumption(Max)</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='power_comsumption' value={power_comsumption} onChange={onChangeSpecHandler} id="textbox-pc" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>



                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Dimensions</Typography>

                <Grid container sx={{ fontSize: 12 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-dws" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Dimensions With Stand</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='dimension_with_stand' value={dimension_with_stand} onChange={onChangeSpecHandler} id="textbox-dws" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-dwos" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Dimensions Without Stand</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='dimension_without_stand' value={dimension_without_stand} onChange={onChangeSpecHandler} id="textbox-dwos" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-shipd" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Shipping Dimensions</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='dimension_shipping' value={dimension_shipping} onChange={onChangeSpecHandler} id="textbox-shipd" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Weight</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-wws" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Weight With Stand</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='weight_with_stand' value={weight_with_stand} onChange={onChangeSpecHandler} id="textbox-wws" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-wwos" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Weight Without Stand</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='weight_without_stand' value={weight_without_stand} onChange={onChangeSpecHandler} id="textbox-wwos" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-shipw" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Shipping Weight</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='weight_shipping' value={weight_shipping} onChange={onChangeSpecHandler} id="textbox-shipw" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>Minimum PC requirements</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-cpu" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>CPU</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='pcspec_cpu' value={pcspec_cpu} onChange={onChangeSpecHandler} id="textbox-cpu" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-gpu" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>GPU</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='pcspec_gpu' value={pcspec_gpu} onChange={onChangeSpecHandler} id="textbox-gpu" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-io" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>I/O</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='pcspec_io' value={pcspec_io} onChange={onChangeSpecHandler} id="textbox-io" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>



                <Typography sx={{ m: 0, mt: 4, mb: 2, fontWeight: 'bold', display: 'block' }}>What's in the box</Typography>

                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-hc" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>HDMI Cable</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_hdmi' value={has_hdmi} onChange={onChangeSpecHandler} id="textbox-hc" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-dpc" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Display Port Cable</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_dp' value={has_dp} onChange={onChangeSpecHandler} id="textbox-dpc" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-uc" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>USB Cable</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_usb' value={has_usb} onChange={onChangeSpecHandler} id="textbox-uc" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-powerc" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Power Cable</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_powercable' value={has_powercable} onChange={onChangeSpecHandler} id="textbox-powerc" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-sg" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Setup Guide</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_setupguide' value={has_setupguide} onChange={onChangeSpecHandler} id="textbox-sg" className="inputStyle" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-rm" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Remote Mouse</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_remotemouse' value={has_remotemouse} onChange={onChangeSpecHandler} id="textbox-rm" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{ fontSize: 14 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-3db" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>3D Box</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='has_3dbox' value={has_3dbox} onChange={onChangeSpecHandler} id="textbox-3db" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>


                <Grid container sx={{ fontSize: 14, m: 0, mt: 4, mb: 2 }}>
                    <Grid item xs={5} sm={6} lg={4} xl={3} sx={{ p: 2, fontWeight: 'bold' }}>
                        <InputLabel htmlFor="textbox-ws" sx={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold', ml: -2 }}>Warranty & Support</InputLabel>
                    </Grid>
                    <Grid item xs={7} sm={6} lg={8} xl={3} sx={{ p: 2, py: '4px' }}>
                        <FormControl sx={{ m: 0 }} fullWidth>
                            <InputBase name='warrenty_date' value={warrenty_date} onChange={onChangeSpecHandler} id="textbox-ws" className="inputStyle" />
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>


            <Container sx={{ m: 0, mt: 5 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                    <Button component={Link} variant="contained" to="" sx={{ minWidth: 100 }} onClick={confirmUpdate}>수정</Button>
                </Stack>
            </Container>
        </>
    );
};
export default ModelUpdate;

