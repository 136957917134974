/* eslint-disable */
import React, { useContext } from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { Divider, Drawer, List, Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import ArticleIcon from '@mui/icons-material/Article';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import KeyIcon from '@mui/icons-material/Key';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

import { MopicContext } from 'hooks/useContextManager';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const categories = [
  {
    id: 'Product',
    children: [
      { id: '모델 관리', icon: <PhonelinkSetupIcon />, active: true, link: '/model/list', idx: 0 },
      { id: '제품 관리', icon: <AutoAwesomeMotionIcon />, active: false, link: '/product/list', idx: 1 },
      { id: '아이트래커 관리', icon: <KeyIcon />, active: false, link: '/eyetrackermanager', idx: 2 }
    ]
  },
  {
    id: 'Client',
    children: [
      { id: '고객 관리', icon: <GroupIcon />, active: false, link: '/customer/list', idx: 3 },
    ]
  },
  {
    id: 'Specification',
    role : "MODEL_ALL",
    children: [
      { id: 'Camera', icon: <ArrowRightIcon />, active: false, link: '/spec/camera', idx: 11, role : "MODEL_ALL" },
      { id: 'DisplaySet', icon: <ArrowRightIcon />, active: false, link: '/spec/display', idx: 12, role : "MODEL_ALL" },
      { id: 'Lens', icon: <ArrowRightIcon />, active: false, link: '/spec/lens', idx: 13, role : "MODEL_ALL" },
      { id: '3D Box', icon: <ArrowRightIcon />, active: false, link: '/spec/3dbox', idx: 14, role : "MODEL_ALL" }
    ]
  },
  {
    id: 'Log Data',
    children: [
      { id: 'MopicLib', icon: <ArticleIcon />, link: '/logviewer/mopiclib', idx: 4 },
      { id: 'Product API', icon: <ArticleIcon />, link: '/logviewer/product', idx: 5, role : "PRODUCTAPI_READ" }
    ]
  }
];

const item = { py: '5px', px: 3, color: 'rgba(255, 255, 255, 0.7)' };


export default function Navigator(props) {
  const account_info = useContext(MopicContext).account_info;
  const ListItemCustom = (props) => {
    let link = props.link;
    let listItemProps = {
      component: forwardRef((props, ref) =>
        <Link ref={ref} {...props} to={link} />
      )
    };
    if (!props.role || account_info.hasRole(props.role)) {
      return (
        <ListItem disablePadding >
          <ListItemButton {...listItemProps} onClick={props.clickHandler.bind(this, props.idx)} selected={props.selectedIndex === props.idx} sx={props.item}>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText>{props.rowid}</ListItemText>
          </ListItemButton>
        </ListItem>
      );
    }
    else {
      return null;
    }
  };
  const { ...other } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const listpage_info = useContext(MopicContext).listpage_info;

  const onItemClick = (idx) => {
    setSelectedIndex(idx);
    //setOpen(false);
    switch (idx)
    {
      case 0: // 모델관리
        listpage_info.setModelPageIndex(0);
        break;
      case 1: // 제품관리
        listpage_info.setProductPageIndex(0);
        listpage_info.setProductSearchKeyModelName(null);
        listpage_info.setProductSearchKeyCustomer(null);
        listpage_info.setProductSearchKeyStatus(null);
        listpage_info.setProductSearchKeySerialNumber(null);
        listpage_info.setProductSearchKeyStartDate(null);
        listpage_info.setProductSearchKeyEndDate(null);
        break;
      case 2: // 고객관리
        listpage_info.setCustomerPageIndex(0);
        break;
      case 4: // Log MopicLib
        listpage_info.setLogMopiclibPageIndex(0);
        listpage_info.setLogMopiclibPageRowCount(100);
        listpage_info.setLogMopiclibSearchStartDate(null);
        listpage_info.setLogMopiclibSearchEndDate(null);
        listpage_info.setLogMopiclibSearchMessage(null);
        break;
      case 5: // Log Product API
        listpage_info.setLogProductPageIndex(0);
        listpage_info.setLogProductPageRowCount(100);
        listpage_info.setLogProductSearchStartDate(null);
        listpage_info.setLogProductSearchEndDate(null);
        listpage_info.setLogProductSearchMessage(null);
        break;
      default:
        break;
    }
  };
  //const [open, setOpen] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    //console.log(location.pathname, location.pathname.indexOf('/model') === 0);
    switch (true) {
      case location.pathname.indexOf('/model') === 0 :
        setSelectedIndex(0);
        break;
      case location.pathname.indexOf('/product') === 0:
        setSelectedIndex(1);
        break;
      case location.pathname.indexOf('/eyetrackermanager') === 0:
        setSelectedIndex(2);
        break;
      case location.pathname.indexOf('/customer') === 0:
        setSelectedIndex(3);
        break;
      case location.pathname.indexOf('/logviewer/mopiclib') === 0:
        setSelectedIndex(4);
        break;
      case location.pathname.indexOf('/logviewer/product') === 0:
        setSelectedIndex(5);
        break;
      default:
        setSelectedIndex(-1);
        break;
    }
  }, [location]);

  return (
    //open={open} onClose={() => setOpen(false)}
    <Drawer variant="permanent" {...other} >
      <List disablePadding>
        <ListItem sx={{ px: 3, fontSize: 24, color: '#fff', py: 4 }}>
          MOPIC ERP
        </ListItem>
        <Divider />
        {categories.map(rows => (
          
          <Box key={rows.id} sx={{ bgcolor: '' }}  className="navigation">
            
            {(rows.role && !account_info.hasRole(rows.role)) ? null :
            <>
            <ListItem sx={{ py: 1, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{rows.id}</ListItemText>
            </ListItem>
            
            {rows.children.map((row, rowidx) => (
              <ListItemCustom sx={{ m: 0}} rowid={row.id} icon={row.icon} link={row.link} idx={row.idx} item={item} key={rowidx} role={row.role} selectedIndex={selectedIndex} clickHandler={onItemClick}></ListItemCustom>
            ))}
            <Divider sx={{ mt: 2 }} />
            </>
            }
          </Box>
          
        ))}
      </List>
    </Drawer >
  );
}