import axios from 'axios';
import { MOPICAPI_KEY } from './useMopicAPIConfig';


const _addRequestHeader = (descriptor) => {
    descriptor.headers = {
        'content-type': 'application/json',
        'api-key': MOPICAPI_KEY['COMMON']
    };
    return descriptor;
}

export const GetAxiosGetDescriptior = (callbackSuccess, callbackError) => {
    return {
        responseType: 'json',
        responseEncoding: 'utf8',
        params: {},
        keys: {},
        successCallback: callbackSuccess ? callbackSuccess : () => { },
        errorCallback: callbackError ? callbackError : () => { },
    }
}
export const GetAxiosCommonDescriptior = (callbackSuccess, callbackError) => {
    return {
        url: '',
        responseType: 'json',
        responseEncoding: 'utf8',
        params: {},
        keys: {},
        data: null,
        successCallback: callbackSuccess ? callbackSuccess : () => { },
        errorCallback: callbackError ? callbackError : () => { },
    }
}
export const axiosGet = async (url, descriptor) => {
    if (descriptor)
    {
        descriptor.method = 'get';
        descriptor = _addRequestHeader(descriptor);

        let ret = await axios.get(url, descriptor);
        if (ret.data.result === "ERROR")
        {
            descriptor.errorCallback(ret.data);
        }
        else
        {
            descriptor.successCallback(ret.data);
        }
    }
}
export const axiosFileDownload = async (url, descriptor) => {
    if (descriptor)
    {
        descriptor.method = 'get';
        descriptor.responseType = "blob";
        descriptor = _addRequestHeader(descriptor);

        let ret = await axios.get(url, descriptor);
        if (ret.data.result === "ERROR")
        {
            descriptor.errorCallback(ret.data);
        }
        else
        {
            descriptor.successCallback(ret.data);
        }
    }
}
export const axiosPost = async (descriptor) => {
    if (descriptor)
    {
        descriptor.method = 'post';
        descriptor = _addRequestHeader(descriptor);

        let ret = await axios(descriptor);
        if (ret.data.result === "ERROR")
        {
            descriptor.errorCallback(ret.data);
        }
        else
        {
            descriptor.successCallback(ret.data);
        }
    }
}
export const axiosPut = async (descriptor) => {
    if (descriptor)
    {
        descriptor.method = 'put';
        descriptor = _addRequestHeader(descriptor);

        let ret = await axios(descriptor);

        if (ret.data.result === "ERROR")
        {
            descriptor.errorCallback(ret.data);
        }
        else
        {
            descriptor.successCallback(ret.data);
        }
    }
}
export const axiosDelete = async (descriptor) => {
    if (descriptor)
    {
        descriptor.method = 'delete';
        descriptor = _addRequestHeader(descriptor);

        let ret = await axios(descriptor);

        if (ret.data.result === "ERROR")
        {
            descriptor.errorCallback(ret.data);
        }
        else
        {
            descriptor.successCallback(ret.data);
        }
    }
}