// base
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
// mui
import { Box, InputBase, Container, Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Card, CardContent, Typography, Grid, Button, IconButton } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// hooks
import { getCustomerInfo, QUERYKEY_CUSTOMER } from 'hooks/useCustomer';
import { MopicContext } from 'hooks/useContextManager';
// MP Components
import { MPTablePaginationAction, StyledTableCell } from 'components/MPTable'

export default function CustomerList() {
  // context
  const account_info = useContext(MopicContext).account_info;
  const listpage_info = useContext(MopicContext).listpage_info;

  const queryClient = useQueryClient();

  // role
  const has_role_write = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_WRITE");
  const has_role_update = account_info.hasRole("CUSTOMER_ALL") || account_info.hasRole("CUSTOMER_UPDATE");

  // data
  const [customerrows, setCustomerRows] = useState([]);

  // page
  const [page, setPage] = useState(0);
  const [rowcount, setRowCount] = useState(10);

  // find
  const [find_customer, setFindCustomer] = useState({
    company_name: '',
    customer_name: '',
    manager_name: ''
  });
  const { company_name, customer_name, manager_name } = find_customer;

  // Effects
  useEffect(() => {
    // global effect
  }, [])

  useEffect(() => {
    setPage(listpage_info.getCustomerPageIndex());
    setRowCount(listpage_info.getCustomerPageRowCount());
  }, [listpage_info, page, rowcount]);

  // Query Data
  const onSuccessQueryCustomer = (data) => {
    const filteredData = data.filter(item => item.customer_company !== "삭제");

    setCustomerRows(filteredData);
  };

  const onErrorQueryCustomer = (error) => {
    console.warn("Error Query Customer")
  };

  const query_options_customer = {
    'keys': {
      company_name: '',
      customer_name: '',
      manager_name: ''
    },
    'callback_success': onSuccessQueryCustomer,
    'callback_error': onErrorQueryCustomer
  };
  const [obj_options_customer, setOptionsCustomer] = useState(query_options_customer);

  getCustomerInfo(obj_options_customer);

  // Event Handler
  const onFindValueChange = e => {
    const { value, name } = e.target;
    setFindCustomer({
      ...find_customer,
      [name]: value
    })
  };

  const onFindButtonClick = e => {
    queryClient.removeQueries(QUERYKEY_CUSTOMER['getCustomerInfo']);
    query_options_customer.keys.company_name = find_customer.company_name;
    query_options_customer.keys.customer_name = find_customer.customer_name;
    query_options_customer.keys.manager_name = find_customer.manager_name;

    setOptionsCustomer(query_options_customer);
  };

  const onPageIndexChange = (e, new_page_idx) => {
    setPage(new_page_idx);
    listpage_info.setCustomerPageIndex(new_page_idx);
  };

  const onPageRowCountChange = e => {
    const rowcount = parseInt(e.target.value, 10);

    setPage(0);
    setRowCount(rowcount);

    listpage_info.setCustomerPageIndex(0);
    listpage_info.setCustomerPageRowCount(rowcount);
  };

  const onCustomerDetailClick = e => {
    listpage_info.setCustomerPageIndex(page);
    listpage_info.setCustomerPageRowCount(rowcount);
  };

  return (
    <>
      {/* Title Container */}
      <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItem: 'right', flexWrap: 'wrap', m: 0 }} >
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography sx={{ m: 0 }} variant="h5">고객관리</Typography>
          </Grid>
          {(has_role_write) ?
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Button variant="contained" component={Link} to="/customer/write">고객 추가</Button>
            </Grid>
            : null}
        </Grid>
      </Container>
      {/* Search Container */}
      <Container sx={{ mx: 0, mt: 5, mb: 5, display: 'flex', flexWrap: 'wrap' }}>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <TableContainer sx={{}} component={Container}>
              <Table sx={{}} aria-label="Spec">
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell width="75px">
                      회사명
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputBase name={'company_name'} className="inputStyle" value={company_name} onChange={onFindValueChange} />
                    </StyledTableCell>
                    <StyledTableCell width="75px">
                      고객명
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputBase name={'customer_name'} className="inputStyle" value={customer_name} onChange={onFindValueChange} />
                    </StyledTableCell>
                    <StyledTableCell width="90px">
                      영업담당
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputBase name={'manager_name'} className="inputStyle" value={manager_name} onChange={onFindValueChange} />
                    </StyledTableCell>
                    <StyledTableCell width="110px">
                      <Button sx={{ px: 3 }} variant="contained" onClick={onFindButtonClick}>검색</Button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
      {/* DataTable Container */}
      <Container sx={{ mx: 0, mt: 3, display: 'flex', flexWrap: 'wrap' }}>
        <TableContainer sx={{}} component={Box}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell component="th" scope="row" align="center">회사명</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '110px' }}>고객명</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '65px' }}>부서</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '130px' }}>연락처</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">E-mail</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" sx={{ minWidth: '110px' }}>영업담당자</StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerrows.slice(page * rowcount, page * rowcount + rowcount).map((row, idx) => {
                if (row.customer_company === "삭제") return null;
                return (
                  <TableRow key={idx} hover={true}>
                    <StyledTableCell align="center"><Link to="/customer/view" sx={{ cursor: "pointer" }} state={{ data: row }} onClick={onCustomerDetailClick}>{row.customer_company}</Link></StyledTableCell>
                    <StyledTableCell align="center">{row.customer_name}</StyledTableCell>
                    <StyledTableCell align="center">{row.customer_department}</StyledTableCell>
                    <StyledTableCell align="center">{row.customer_phone}</StyledTableCell>
                    <StyledTableCell align="center">{row.customer_mail}</StyledTableCell>
                    <StyledTableCell align="center">{row.mopic_manager}</StyledTableCell>
                    <StyledTableCell align="center" width="120" sx={{ px: 1 }}>
                      {(has_role_write || has_role_update) ?
                        <IconButton aria-label="edit" sx={{ mr: 1 }}>
                          <Link to="/customer/update" sx={{ cursor: "pointer" }} state={{ data: row }}>
                            <ModeEditIcon />
                          </Link>
                        </IconButton>
                        : null}
                    </StyledTableCell>
                  </TableRow>);
              })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={9}
                  count={customerrows.length}
                  rowsPerPage={rowcount}
                  page={customerrows.length <= 0 ? 0 : page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                  onPageChange={onPageIndexChange}
                  onRowsPerPageChange={onPageRowCountChange}
                  ActionsComponent={MPTablePaginationAction}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};