import React from 'react';

const ACCESS_ROLES = {
    MODEL_ALL: 'Mopic.Model.All',
    MODEL_WRITE: 'Mopic.Model.Write',
    MODEL_READ: 'Mopic.Model.Read',
    MODEL_UPDATE: 'Mopic.Model.Update',
    PRODUCT_ALL: 'Mopic.Product.All',
    PRODUCT_WRITE: 'Mopic.Product.Write',
    PRODUCT_READ: 'Mopic.Product.Read',
    PRODUCT_UPDATE: 'Mopic.Product.Update',
    CUSTOMER_ALL: 'Mopic.Customer.All',
    CUSTOMER_WRITE: 'Mopic.Customer.Write',
    CUSTOMER_READ: 'Mopic.Customer.Read',
    CUSTOMER_UPDATE: 'Mopic.Customer.Update',
    EYETRACKER_ALL: 'Mopic.Eyetracker.All',
    PRODUCTAPI_READ: 'Mopic.ProductAPI.Read'
};

export const MopicContext = React.createContext();

export const AccountInfomation = {
    user: '',
    account: '',
    roles: '',
    members:[],
    token:'',
    setUser: (value) => {
        AccountInfomation.user = value;
    },
    setAccount: (value) => {
        AccountInfomation.account = value;
    },
    setMembers: (value) => {
        AccountInfomation.members = value;
    },
    setRoles: (value) => {
        AccountInfomation.roles = value;
    },
    setToken: (value) => {
        AccountInfomation.token = value;
    },
    getUser: () => { return AccountInfomation.user; },
    getAccount: () => { return AccountInfomation.account; },
    getRoles: () => { return AccountInfomation.roles; },
    getMembers: () => { return AccountInfomation.members; },
    getToken: () => { return AccountInfomation.token; },
    hasRole: (role_name) => {
        let roles = AccountInfomation.getRoles();
        if (roles?.length)
        {
            return (roles.indexOf(ACCESS_ROLES[role_name]) > -1);
        }
        else
        {
            return false;
        }
    }

}
//log_page=0&log_msg=&log_startdate=2023-03-22&log_enddate=2024-04-20
export const ListPageInfomation = {
    model_page_idx: 0,
    model_page_rowcount: 10,
    product_page_idx: 0,
    product_page_rowcount: 10,
    product_search_key_modelname: null,
    product_search_key_modelserialnumber: null,
    product_search_key_customer: null,
    product_search_key_status: null,
    product_search_key_serialnumber: null,
    product_search_key_startdate: null,
    product_search_key_enddate: null,
    customer_page_idx: 0,
    customer_page_rowcount: 10,
    log_mopiclib_page_idx: 0,
    log_mopiclib_page_rowcount: 10,
    log_mopiclib_search_serialnumber : null,
    log_mopiclib_search_startdate: null,
    log_mopiclib_search_enddate: null,
    log_mopiclib_search_message: null,
    log_product_page_idx: 0,
    log_product_page_rowcount: 10,
    log_product_search_serialnumber : null,
    log_product_search_startdate: null,
    log_product_search_enddate: null,
    log_product_search_message: null,
    setModelPageIndex: (value) => { ListPageInfomation.model_page_idx = value; },
    setModelPageRowCount: (value) => { ListPageInfomation.model_page_rowcount = value; },
    setProductPageIndex: (value) => { ListPageInfomation.product_page_idx = value; },
    setProductPageRowCount: (value) => { ListPageInfomation.product_page_rowcount = value; },
    setProductSearchKeyModelName: (value) => { ListPageInfomation.product_search_key_modelname = value; },
    setProductSearchKeyModelSerialNumber: (value) => { ListPageInfomation.product_search_key_modelserialnumber = value; },
    setProductSearchKeyCustomer: (value) => { ListPageInfomation.product_search_key_customer = value; },
    setProductSearchKeyStatus: (value) => { ListPageInfomation.product_search_key_status = value; },
    setProductSearchKeySerialNumber: (value) => { ListPageInfomation.product_search_key_serialnumber = value; },
    setProductSearchKeyStartDate: (value) => { ListPageInfomation.product_search_key_startdate = value; },
    setProductSearchKeyEndDate: (value) => { ListPageInfomation.product_search_key_enddate = value; },
    setCustomerPageIndex: (value) => { ListPageInfomation.customer_page_idx = value; },
    setCustomerPageRowCount: (value) => { ListPageInfomation.customer_page_rowcount = value; },

    setLogMopiclibPageIndex: (value) => { ListPageInfomation.log_mopiclib_page_idx = value; },
    setLogMopiclibPageRowCount: (value) => { ListPageInfomation.log_mopiclib_page_rowcount = value; },
    setLogMopiclibSearchKeySerialNumber: (value) => { ListPageInfomation.log_mopiclib_search_serialnumber = value; },
    setLogMopiclibSearchStartDate: (value) => { ListPageInfomation.log_mopiclib_search_startdate = value; },
    setLogMopiclibSearchEndDate: (value) => { ListPageInfomation.log_mopiclib_search_enddate = value; },
    setLogMopiclibSearchMessage: (value) => { ListPageInfomation.log_mopiclib_search_message = value; },

    setLogProductPageIndex: (value) => { ListPageInfomation.log_product_page_idx = value; },
    setLogProductPageRowCount: (value) => { ListPageInfomation.log_product_page_rowcount = value; },
    setLogProductSearchKeySerialNumber: (value) => { ListPageInfomation.log_product_search_serialnumber = value; },
    setLogProductSearchStartDate: (value) => { ListPageInfomation.log_product_search_startdate = value; },
    setLogProductSearchEndDate: (value) => { ListPageInfomation.log_product_search_enddate = value; },
    setLogProductSearchMessage: (value) => { ListPageInfomation.log_product_search_message = value; },

    getModelPageIndex: () => { return ListPageInfomation.model_page_idx; },
    getModelPageRowCount: () => { return ListPageInfomation.model_page_rowcount; },
    getProductPageIndex: () => { return ListPageInfomation.product_page_idx; },
    getProductPageRowCount: () => { return ListPageInfomation.product_page_rowcount; },
    getProductSearchKeyModelName: () => { return ListPageInfomation.product_search_key_modelname; },
    getProductSearchKeyModelSerialNumber: () => { return ListPageInfomation.product_search_key_modelserialnumber; },
    getProductSearchKeyCustomer: () => { return ListPageInfomation.product_search_key_customer; },
    getProductSearchKeyStatus: () => { return ListPageInfomation.product_search_key_status; },
    getProductSearchKeySerialNumber: () => { return ListPageInfomation.product_search_key_serialnumber; },
    getProductSearchKeyStartDate: () => { return ListPageInfomation.product_search_key_startdate; },
    getProductSearchKeyEndDate: () => { return ListPageInfomation.product_search_key_enddate; },
    getCustomerPageIndex: () => { return ListPageInfomation.customer_page_idx; },
    getCustomerPageRowCount: () => { return ListPageInfomation.customer_page_rowcount; },
    
    getLogMopiclibPageIndex: () => { return ListPageInfomation.log_mopiclib_page_idx; },
    getLogMopiclibPageRowCount: () => { return ListPageInfomation.log_mopiclib_page_rowcount; },
    getLogMopiclibSearchKeySerialNumber: () => { return ListPageInfomation.log_mopiclib_search_serialnumber; },
    getLogMopiclibSearchStartDate: () => { return ListPageInfomation.log_mopiclib_search_startdate; },
    getLogMopiclibSearchEndDate: () => { return ListPageInfomation.log_mopiclib_search_enddate; },
    getLogMopiclibSearchMessage: () => { return ListPageInfomation.log_mopiclib_search_message; },

    getLogProductPageIndex: () => { return ListPageInfomation.log_product_page_idx; },
    getLogProductPageRowCount: () => { return ListPageInfomation.log_product_page_rowcount; },
    getLogProductSearchKeySerialNumber: () => { return ListPageInfomation.log_product_search_serialnumber; },
    getLogProductSearchStartDate: () => { return ListPageInfomation.log_product_search_startdate; },
    getLogProductSearchEndDate: () => { return ListPageInfomation.log_product_search_enddate; },
    getLogProductSearchMessage: () => { return ListPageInfomation.log_product_search_message; }
}

export const CustomerInfomation = {
    cached_list: [],
    setCustomerList: (value) => { CustomerInfomation.cached_list = value; },
}