import { axiosGet, axiosPost } from './useAxios';
import { URL_API_LIST } from './useMopicAPIConfig.js'

const useEyetracker = {};

useEyetracker.axiosGetUsedEyeTrackerKeyInformation = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/init_list";

    await axiosGet(req_url, descriptor);
};
useEyetracker.axiosGetUnusedEyeTrackerKeyInformation = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/unused_list";

    await axiosGet(req_url, descriptor);
};
useEyetracker.axiosGetIssuedEyeTrackerKeyInformation = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/issued_info";

    await axiosGet(req_url, descriptor);
};
useEyetracker.axiosPostAddEyeTrackerKeyCSV = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/add_csv";

    await axiosPost(descriptor);
};
useEyetracker.axiosPostUnIssueEyeTrackerKey = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/unissue";

    await axiosPost(descriptor);
};
useEyetracker.axiosPostUnIssueEyeTrackerKeyWindowOnly = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER_WINDOWONLY'] + "/2/unissue";

    await axiosPost(descriptor);
};
useEyetracker.axiosPostExchangeEyeTrackerKey = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/exchange"; //

    await axiosPost(descriptor);
};
useEyetracker.axiosPostExchangeEyeTrackerKeyWindowOnly = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER_WINDOWONLY'] + "/2/exchange"; //

    await axiosPost(descriptor);
};
useEyetracker.axiosPostUnregistEyeTrackerKey = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "/2/unregist";

    await axiosPost(descriptor);
};
useEyetracker.axiosPostUnregistEyeTrackerKeyWindowOnly = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_EYETRACKER_WINDOWONLY'] + "/2/unregist";

    await axiosPost(descriptor);
};

export default useEyetracker;