import { DataGrid } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

export const BasicEditingGrid = (props) => {
    return (
        <div style={{ width: props.width, height: props.height }}>
            <Button color="primary" startIcon={<AddIcon />} onClick={props.callback_addrow}>ADD</Button>
            <Button color="primary" startIcon={<SaveIcon />} onClick={props.callback_save}>SAVE</Button>
            <DataGrid rows={props.rows} columns={props.columns} experimentalFeatures={{ newEditingApi: true }} />
        </div>
    );
}